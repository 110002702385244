<div class="flex flex-col mb-2">
    <div class="flex gap-2">
        <basile-avatar
            class="w-10 h-10 lg:h-15 lg:w-15 xl:w-20 xl:h-20 flex flex-shrink-0"
            [configuration]="comment.basileConfiguration"
        ></basile-avatar>
        <div class="flex flex-col grow">
            <div class="flex w-full justify-between">
                <span class="text-xl truncate font-bold text-warn"
                    >{{ comment.firstName }}
                    <span *ngIf="comment.familyName">{{
                        comment.familyName
                    }}</span></span
                >
                <div class="flex gap-2">
                    <mat-icon
                        *ngIf="canEdit"
                        (click)="edit()"
                        class="text-current cursor-pointer"
                        [svgIcon]="'heroicons_outline:pencil-square'"
                        [matTooltip]="'shared.common.edit' | transloco"
                    ></mat-icon>
                    <mat-icon
                        *ngIf="canDelete"
                        (click)="remove()"
                        class="text-current cursor-pointer"
                        [svgIcon]="'heroicons_outline:trash'"
                        [matTooltip]="'shared.common.remove' | transloco"
                    ></mat-icon>
                </div>
            </div>
            <span class="text-xs truncate"
                >{{ comment.dateCreated | date : "short" }}
                <span class="italic" *ngIf="comment.dateUpdated"
                    >- {{ "shared.common.updated-at" | transloco }}
                    {{ comment.dateUpdated | date : "short" }}</span
                ></span
            >
            <span class="mt-2 hidden xl:inline">{{ comment.comment }}</span>
        </div>
    </div>
    <span class="mt-2 inline xl:hidden">{{ comment.comment }}</span>
</div>
