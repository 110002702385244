<bslk-classic-card
    [fullHeight]="true"
    [title]="'setup.administrative-contact' | transloco"
>
    <div headerActions class="flex gap-5 items-center">
        <bslk-info
            [size]="'large'"
            [tooltip]="'setup.administrative-contact-tooltip' | transloco"
        ></bslk-info>
    </div>

    <div class="flex flex-col h-full">
        <div class="flex flex-col justify-center grow">
            <single-select-async
                *ngIf="!(isLoadingCompany$ | async); else loading"
                [selectPlaceholder]="'setup.select-employee' | transloco"
                [searchPlaceholder]="'setup.search-employee' | transloco"
                [actions]="CompanyUsersActions"
                [selectors]="CompanyUsersSelectors"
                [control]="control"
                [valueIsId]="true"
            >
            </single-select-async>
        </div>

        <div class="flex justify-end mt-5 gap-5">
            <bslk-classic-button
                [isDisabled]="!control.value"
                [background]="'warn'"
                (onClick)="remove()"
                >{{ "setup.remove" | transloco }}</bslk-classic-button
            >
            <bslk-classic-button
                [isDisabled]="!isControlDirty"
                [isLoading]="isLoadingUpdate$ | async"
                (onClick)="save()"
                >{{ "shared.common.save" | transloco }}</bslk-classic-button
            >
        </div>
    </div>
</bslk-classic-card>

<ng-template #loading>
    <div class="w-full h-12">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
