import {
    AuthenticationResponseViewModel,
    CompanyCustomizationViewModel,
    UserViewModel,
} from 'app/api';
import { CompanyAdminDashboardConfiguration } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.state';

export const USER_SESSION_INFO_LS_KEY = 'usi';
export const COMPANY_ADMIN_DASHBOARD_CONFIG_LS_KEY = 'cadc';

export function getUserSessionFromLocalStorage():
    | AuthenticationResponseViewModel
    | undefined {
    const serializedInfo = localStorage.getItem(USER_SESSION_INFO_LS_KEY);
    const deserializedInfo: AuthenticationResponseViewModel =
        JSON.parse(serializedInfo);
    return deserializedInfo;
}

export function getUserFromLocalStorage(): UserViewModel | undefined {
    return getUserSessionFromLocalStorage()?.user;
}

export function updateLocalStorageSession(
    session: AuthenticationResponseViewModel
) {
    localStorage.setItem(USER_SESSION_INFO_LS_KEY, JSON.stringify(session));
}

export function updateLocalStorageUser(user: UserViewModel) {
    const session = getUserSessionFromLocalStorage();
    session.user = user;
    updateLocalStorageSession(session);
}

export function updateLocalStorageCustomization(
    customization: CompanyCustomizationViewModel
): AuthenticationResponseViewModel {
    const session = getUserSessionFromLocalStorage();
    session.user.company.customization = customization;
    updateLocalStorageSession(session);

    return session;
}

export function getCompanyAdminDashboardConfigFromLocalStorage(): CompanyAdminDashboardConfiguration {
    const serializedInfo = localStorage.getItem(
        COMPANY_ADMIN_DASHBOARD_CONFIG_LS_KEY
    );
    const deserializedInfo: CompanyAdminDashboardConfiguration =
        JSON.parse(serializedInfo);
    return deserializedInfo;
}

export function updateLocalStorageCompanyAdminDashboardConfig(
    configuration: CompanyAdminDashboardConfiguration
) {
    localStorage.setItem(
        COMPANY_ADMIN_DASHBOARD_CONFIG_LS_KEY,
        JSON.stringify(configuration)
    );
}
