import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyCustomizationService, CompanyService } from 'app/api';
import { updateLocalStorageCustomization } from 'app/shared/utils/local-storage.utils';
import { isNil } from 'lodash-es';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AlertActions } from '../alert/alert.actions';
import { UserActions } from '../user/user/user.actions';
import { SetupActions } from './setup.action';

@Injectable()
export class SetupEffects {
    loadCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.loadCompanyRequest),
            exhaustMap(() => {
                return this.companyService.companyUserGet().pipe(
                    map((response) =>
                        SetupActions.loadCompanyRequestSuccess({ response })
                    ),
                    catchError((httpResponse) =>
                        of(
                            SetupActions.loadCompanyRequestFail({
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    updateCompanyAdministrativeContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.updateAdministrativeContactRequest),
            exhaustMap(({ userId }) => {
                return (
                    isNil(userId)
                        ? this.companyService.companyRemoveAdministrativeContactPatch()
                        : this.companyService.companyAdministrativeContactIdPatch(
                              { id: userId }
                          )
                ).pipe(
                    map(() =>
                        SetupActions.updateAdministrativeContactRequestSuccess()
                    ),
                    catchError((httpResponse) =>
                        of(
                            SetupActions.updateAdministrativeContactRequestFail(
                                {
                                    error: httpResponse?.error ?? httpResponse,
                                }
                            )
                        )
                    )
                );
            })
        )
    );

    loadCustomization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.loadCompanyCustomizationRequest),
            exhaustMap(() => {
                return this.companyCustomizationService
                    .companyCustomizationGet()
                    .pipe(
                        map((customization) =>
                            SetupActions.loadCompanyCustomizationRequestSuccess(
                                {
                                    response: customization,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                SetupActions.loadCompanyCustomizationRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );
    updateCustomization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.updateCustomizationRequest),
            exhaustMap(({ customization }) => {
                return this.companyCustomizationService
                    .companyCustomizationPut({
                        companyCustomizationViewModel: customization,
                    })
                    .pipe(
                        map((customization) =>
                            SetupActions.updateCustomizationRequestSuccess({
                                customization,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                SetupActions.updateCustomizationRequestFail({
                                    error: httpResponse?.error ?? httpResponse,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateAdministrativeContactSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SetupActions.updateAdministrativeContactRequestSuccess,
                SetupActions.updateCustomizationRequestSuccess
            ),
            map(() => {
                return AlertActions.displaySuccess({
                    key: 'setup-update',
                });
            })
        )
    );

    updateCustomizationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.updateCustomizationRequestSuccess),
            map(({ customization }) => {
                const session = updateLocalStorageCustomization(customization);
                return UserActions.updateSessionInfo({
                    session,
                });
            })
        )
    );

    constructor(
        private actions$: Actions,
        private companyService: CompanyService,
        private companyCustomizationService: CompanyCustomizationService
    ) {}
}
