<div *ngrxLet="isLoading$ as isLoading" class="flex flex-col">
    <div
        class="flex items-end truncate justify-between pb-1 mb-6 border-b-2 border-slate-300"
    >
        <span class="text-3xl md:text-4xl font-bold truncate">{{
            "regulardashboard.my-pending-approvals" | transloco
        }}</span>
    </div>

    <ng-container *ngIf="!isLoading; else skeletonCard">
        <div
            *ngIf="noPendingApprovals$ | async"
            class="flex flex-col w-full text-center"
        >
            <span class="flex items-center font-bold m-auto text-2xl">
                {{ "regulardashboard.no-pending-approvals" | transloco }}
                <mat-icon
                    class="ml-1 text-black text-2xl"
                    [svgIcon]="'mat_outline:approval'"
                ></mat-icon>
            </span>
            <span class="mt-1">{{
                "regulardashboard.no-pending-approvals-description" | transloco
            }}</span>
        </div>

        <div class="grid gap-4 grid-cols-1 md:grid-cols-2 xxl:grid-cols-3">
            <div *ngFor="let pendingApproval of pendingApprovals$ | async">
                <div
                    class="bg-gradient-to-r from-primary-300 to-warn-300 p-4 shadow-lg rounded-xl"
                >
                    <div
                        class="border-b border-black flex justify-between pb-2 truncate"
                    >
                        <span class="font-bold text-xl truncate">{{
                            pendingApproval.missionName
                        }}</span>
                        <a
                            [matTooltip]="
                                'regulardashboard.see-mission' | transloco
                            "
                            [routerLink]="
                                '/missions/' + pendingApproval.missionId
                            "
                        >
                            <mat-icon
                                class="text-current"
                                [svgIcon]="'heroicons_solid:magnifying-glass'"
                            ></mat-icon>
                        </a>
                    </div>

                    <div
                        class="border-b border-black py-3 text-lg font-bold flex gap-2"
                    >
                        <mat-icon
                            class="text-current"
                            [svgIcon]="'heroicons_solid:calendar'"
                        ></mat-icon>
                        {{
                            formatDateRange(
                                pendingApproval.startDate,
                                pendingApproval.endDate
                            )
                        }}
                    </div>

                    <div class="pt-2">
                        <div
                            class="py-3"
                            *ngFor="
                                let user of pendingApproval.users;
                                let isLast = last
                            "
                        >
                            <div
                                [class.mb-1]="!isLast"
                                class="flex justify-between"
                            >
                                <div class="flex items-center gap-2">
                                    <mat-icon
                                        class="text-current"
                                        [svgIcon]="'heroicons_solid:user'"
                                    ></mat-icon>
                                    <div class="flex flex-col">
                                        <span class="font-semibold text-lg">{{
                                            user.firstName +
                                                " " +
                                                user.familyName
                                        }}</span>
                                        <span>{{ user.jobTitle }}</span>
                                    </div>
                                </div>
                                <bslk-answer-request
                                    (onAnswer)="
                                        requestAnswered(
                                            pendingApproval.missionSlotId,
                                            user.id,
                                            $event
                                        )
                                    "
                                ></bslk-answer-request>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #skeletonCard>
    <div class="grid grid-cols-2 gap-x-4">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="h-64 rounded-2xl shadow"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="h-64 rounded-2xl shadow"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
