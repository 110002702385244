import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionUserMatchViewModel } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { ChatSelectors } from 'app/store/chat/chat.selectors';
import { MissionUserMatchActions } from 'app/store/mission/mission-user-match/mission-user-match.action';
import { MissionUserMatchSelectors } from 'app/store/mission/mission-user-match/mission-user-match.selectors';
import { clone, isEmpty } from 'lodash-es';

export type ContactMatchDialogInput = {
    match: MissionUserMatchViewModel;
};

@Component({
    selector: 'contact-match-dialog',
    standalone: true,
    templateUrl: './contact-match-dialog.component.html',
    imports: [
        BaseDialogComponent,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        NgFor,
        NgIf,
        AsyncPipe,
        TranslocoModule,
        MatFormFieldModule,
        LetDirective,
        MatInputModule,
        FuseAlertComponent,
        ReactiveFormsModule,
        BslkClassicButtonComponent,
        FormsModule,
    ],
})
export class ContactMatchDialogComponent {
    message: string;

    isLoadingContact$ = this.store.select(
        MissionUserMatchSelectors.selectIsLoadingContact
    );

    hasContactedMatch$ = this.store.select(
        MissionUserMatchSelectors.selectHasContactedMatch
    );

    isLoadingChats$ = this.store.select(ChatSelectors.selectIsLoading);

    constructor(
        private dialogRef: MatDialogRef<ContactMatchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ContactMatchDialogInput,
        private store: Store
    ) {}

    get isMessageEmpty() {
        return isEmpty(this.message);
    }

    send() {
        let clonedMatch = clone(this.data.match);
        clonedMatch.message = this.message;
        this.store.dispatch(
            MissionUserMatchActions.contactPotentialMatcheRequest({
                potentialMatch: clonedMatch,
            })
        );
    }

    seeChat() {
        this.dialogRef.close();

        this.store.dispatch(MissionUserMatchActions.seeChat());
    }

    close() {
        this.dialogRef.close();
    }
}
