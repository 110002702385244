<div class="flex flex-col flex-auto min-w-0">
    <!-- Header -->
    <div class="flex flex-col shadow bg-white">
        <!-- Cover image -->
        <div class="relative">
            <bslk-round-icon
                [ngClass]="{
                    'fixed z-999': closeFixedPosition,
                    absolute: !closeFixedPosition
                }"
                class="top-5 left-5 cursor-pointer"
                [icon]="'heroicons_solid:arrow-uturn-left'"
                [color]="'primary'"
                [matTooltip]="'shared.navigation.go-back' | transloco"
                (click)="onClose()"
            >
            </bslk-round-icon>
            <ng-container *ngIf="!isLoading; else skeletonCover">
                <img
                    *ngIf="coverUrl && !imagesUrl"
                    class="h-40 lg:h-60 xxl:h-80 object-cover"
                    [src]="coverUrl"
                    alt="Cover image"
                />

                <div
                    *ngIf="!coverUrl && !imagesUrl"
                    class="h-40 lg:h-60 xxl:h-80 object-cover flex items-center border-b-2"
                >
                    <span class="text-4xl text-gray-400 mx-auto">{{
                        "missionview.no-cover" | transloco
                    }}</span>
                </div>

                <div
                    *ngIf="imagesUrl"
                    class="h-40 lg:h-60 xxl:h-80 object-cover"
                >
                    <ng-container *ngIf="imagesUrl.length === 1">
                        <img
                            *ngFor="let url of imagesUrl"
                            [src]="url"
                            class="w-full h-full object-cover"
                        />
                    </ng-container>

                    <ng-container *ngIf="imagesUrl.length > 1">
                        <div class="gap-4 flex w-full h-full p-4">
                            <div *ngFor="let url of imagesUrl" class="flex-1">
                                <img
                                    [src]="url"
                                    class="object-cover rounded-2xl w-full h-full"
                                />
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <div
                class="absolute top-5 lg:bottom-5 lg:top-auto right-5 grow flex flex-col lg:flex-row justify-end items-center"
            >
                <ng-content select="[coverActions]"></ng-content>
            </div>
        </div>

        <div
            class="flex flex-col lg:flex-row items-center max-w-7xl w-full mx-auto px-8 py-3 my-0.5 bg-card"
        >
            <!-- Logo -->
            <div
                *ngIf="!hideLogo"
                class="-mt-26 lg:-mt-22 relative flex-shrink-0"
            >
                <classic-avatar
                    [avatarUrl]="logoUrl"
                    [fallbackLetter]="logoFirstLetter"
                >
                </classic-avatar>
                <div class="absolute top-0 right-0">
                    <ng-content select="[avatarActions]"></ng-content>
                </div>
            </div>

            <!-- Details -->
            <div class="flex items-center lg:ml-8 flex-1">
                <div
                    class="flex flex-col gap-1 items-center text-center lg:text-left lg:items-start pr-3"
                >
                    <div
                        [matTooltip]="title"
                        class="text-2xl flex gap-2 items-center font-bold mt-1 lg:mt-0 md:p-0 w-full"
                    >
                        <mat-icon
                            class="text-current"
                            [svgIcon]="titleIcon"
                            *ngIf="titleIcon"
                        ></mat-icon>
                        {{ title }}
                    </div>
                    <div *ngIf="subtitle" class="text-gray-500">
                        {{ subtitle }}
                    </div>
                </div>

                <!-- Separator -->
                <!-- <div class="hidden lg:flex h-8 mx-8 border-l-2"></div> -->
            </div>

            <!-- Actions -->
            <div class="flex items-center h-full flex-shrink-0 mt-2 md:mt-0">
                <ng-content select="[headerActions]"></ng-content>
            </div>
        </div>
    </div>

    <!-- Main -->
    <div
        class="flex flex-col-reverse lg:flex-row flex-auto justify-center w-full max-w-7xl mx-auto p-3 lg:p-6 sm:p-8"
    >
        <!-- Left Column -->
        <div
            class="flex flex-col items-start lg:mr-8 min-w-[35%] mb-5 lg:mb-0 mt-4 lg:mt-0"
        >
            <ng-content *ngIf="!isLoading" select="[leftColumn]"></ng-content>
            <ng-container *ngIf="isLoading">
                <ng-container *ngTemplateOutlet="skeleton60"></ng-container>
                <ng-container *ngTemplateOutlet="skeleton60"></ng-container>
            </ng-container>
        </div>

        <!-- Right Column -->
        <div class="flex flex-col items-start min-w-[60%] grow">
            <ng-content *ngIf="!isLoading"></ng-content>
            <ng-container *ngIf="isLoading">
                <ng-container *ngTemplateOutlet="skeleton80"></ng-container>
                <ng-container *ngTemplateOutlet="skeleton80"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #skeletonCover>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="flex h-40 lg:h-60 xxl:h-80 object-cover"
    ></ngx-skeleton-loader>
</ng-template>

<ng-template #skeleton60>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full mb-6 h-60"
    ></ngx-skeleton-loader>
</ng-template>

<ng-template #skeleton80>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full mb-6 h-80"
    ></ngx-skeleton-loader>
</ng-template>
