import { SocialUser } from '@abacritt/angularx-Social-login';
import { AuthenticationResult } from '@azure/msal-browser';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    AuthenticationResponseViewModel,
    EUserRole,
    ReminderConfigurationViewModel,
    UserViewModel,
} from 'app/api';

export const UserActions = createActionGroup({
    source: 'User',
    events: {
        'Signed In With Google': props<{ user: SocialUser }>(),
        'Signed In With Microsoft': props<{ result: AuthenticationResult }>(),
        'Signed In With LinkedIn': props<{ code: string; state: string }>(),
        'Signed In With Email': props<{ email: string; password: string }>(),
        'Sign In Success': props<{
            session: AuthenticationResponseViewModel;
        }>(),
        'Sign In Failure': props<{ error: string }>(),
        'Already Signed In': props<{
            session: AuthenticationResponseViewModel;
        }>(),

        'Sign Out': emptyProps(),

        'Sign Up User With Google': props<{ user: SocialUser }>(),
        'Sign Up User With Microsoft': props<{ user: AuthenticationResult }>(),
        'Sign Up User With LinkedIn': props<{
            code: string;
            stateGuid: string;
        }>(),
        'Sign Up User With LinkedIn With Professional Email Linked': props<{
            professionalEmail?: string;
            stateGuid: string;
        }>(),
        'Sign Up User With Email': props<{ user: UserViewModel }>(),
        'Sign Up Success': props<{
            session: AuthenticationResponseViewModel;
        }>(),
        'Sign Up Failure': props<{ error: string }>(),
        'Sign Up': props<{ user: UserViewModel }>(),

        'Init User': emptyProps(),
        'Reset Errors': emptyProps(),

        'Load User In Form': emptyProps(),

        'Start Building User': props<{ user: UserViewModel }>(),

        'Update Request': emptyProps(),
        'Update Request Success': props<{
            user: UserViewModel;
            hasGainedRewards: boolean;
        }>(),
        'Update Request Fail': props<{ error: string }>(),
        'Cancel Update': emptyProps(),

        'Update Avatar Request': props<{ file: File }>(),
        'Update Avatar Request Success': props<{ url: string }>(),
        'Update Avatar Request Fail': props<{ error: string }>(),

        'Switch Role Request': props<{ redirect?: string }>(),
        'Switch Role Request Success': props<{
            newRole: EUserRole;
            redirect?: string;
        }>(),
        'Switch Role Request Fail': props<{ error: string }>(),

        'Update Password Request': props<{
            oldPassword: string;
            newPassword: string;
        }>(),
        'Update Password Request Success': emptyProps(),
        'Update Password Request Fail': props<{ error: string }>(),

        'Load Signature Request': emptyProps(),
        'Load Signature Request Success': props<{ url: string }>(),
        'Load Signature Request Fail': props<{ error: string }>(),

        'Load Reminder Configuration Request': emptyProps(),
        'Load Reminder Configuration Request Success': props<{
            configuration: ReminderConfigurationViewModel;
        }>(),
        'Load Reminder Configuration Request Fail': props<{ error: string }>(),
        'Update Reminter Configuration Value': props<{
            key: string;
            value: boolean;
        }>(),
        'Update Reminder Configuration Request': emptyProps(),
        'Update Reminder Configuration Request Success': emptyProps(),
        'Update Reminder Configuration Request Fail': props<{
            error: string;
        }>(),

        'Delete Signature': emptyProps(),

        'Check If Rewards Are Updated': emptyProps(),
        'Set Has Gained Rewards': emptyProps(),
        'Update User Rewards': props<{
            experiencePoints: number;
            purposeCoins: number;
        }>(),

        'Unsubscribe Request': props<{ email: string }>(),
        'Unsubscribe Request Success': emptyProps(),
        'Unsubscribe Request Fail': props<{ error: string }>(),

        'Update Session Info': props<{
            session: AuthenticationResponseViewModel;
        }>(),
    },
});
