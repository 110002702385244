<div
    (click)="view()"
    class="bg-white overflow-hidden rounded-xl h-96 flex flex-col w-full transition duration-300 hover:scale-[1.03] cursor-pointer"
>
    <div class="h-2/6 w-full relative">
        <img
            *ngIf="microAction.coverUrl"
            class="w-full h-full object-cover"
            [src]="microAction.coverUrl"
            alt="Cover image"
        />
        <div *ngIf="!microAction.coverUrl" class="w-full h-full flex border-b">
            <mat-icon
                class="icon-size-16 m-auto"
                [svgIcon]="'heroicons_solid:photo'"
            >
            </mat-icon>
        </div>

        <div class="absolute top-3 left-3" *ngIf="microAction.isRegistered">
            <bslk-tag
                [icon]="'heroicons_outline:check-badge'"
                [background]="'tertiary'"
                [size]="'small'"
            >
                {{ "microactions.card.registered" | transloco }}
            </bslk-tag>
        </div>
    </div>
    <div class="flex flex-col h-4/6 w-full px-4 py-1">
        <div class="h-1/4 flex flex-col border-b-2 pt-2">
            <div class="flex gap-1 items-center">
                <mat-icon
                    [svgIcon]="
                        microAction.type === EMicroActionType.Awareness
                            ? 'heroicons_solid:academic-cap'
                            : 'feather:target'
                    "
                    class="text-current icon-size-4"
                >
                </mat-icon>
                <span
                    [matTooltip]="microAction.name"
                    class="font-bold text-lg truncate"
                    >{{ microAction.name }}
                </span>
            </div>
            <div class="truncate">
                <span
                    >{{
                        "microactions.card.created-by" | transloco
                    }}&nbsp;</span
                >
                <span class="whitespace-nowrap text-primary font-bold">{{
                    microAction.createdBy.name
                }}</span>
            </div>
        </div>
        <div class="h-1/4 flex flex-col justify-center">
            <div class="flex flex-col">
                <span class="text-warn font-bold">
                    {{
                        formatDateRange(
                            microAction.startDate,
                            microAction.endDate
                        )
                    }}
                </span>
                <span>{{ "microactions.card.dates" | transloco }}</span>
            </div>
        </div>
        <div class="h-2/4 pt-2 flex flex-col border-t-2">
            <div class="flex justify-between">
                <div class="text-right flex flex-col">
                    <span class="font-bold text-warn text-left mb-1">{{
                        "microactions.card.location" | transloco
                    }}</span>
                    <div class="flex items-center justify-between">
                        <div class="flex items-center justify-between">
                            <div
                                *ngIf="microAction.location"
                                class="flex truncate"
                            >
                                <mat-icon
                                    class="icon-size-5 mr-1"
                                    [svgIcon]="'heroicons_solid:map-pin'"
                                >
                                </mat-icon>
                                <span class="truncate"
                                    >{{ microAction.location.zipCode }}
                                    {{ microAction.location.city }}</span
                                >
                            </div>
                            <span
                                *ngIf="microAction.isRemote"
                                class="flex truncate"
                            >
                                <mat-icon
                                    class="icon-size-5 mr-1"
                                    [svgIcon]="
                                        'heroicons_solid:computer-desktop'
                                    "
                                >
                                </mat-icon>
                                <span class="truncate">{{
                                    "microactions.card.remote" | transloco
                                }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="companyHasAgencies$ | async"
                    class="text-right flex flex-col"
                >
                    <span class="font-bold text-warn text-left mb-1">{{
                        "microactions.card.agency" | transloco
                    }}</span>
                    <div class="flex items-center justify-between">
                        <div class="flex items-center justify-between">
                            <div
                                *ngIf="microAction.companyAgencyLocation"
                                class="flex truncate"
                            >
                                <mat-icon
                                    class="icon-size-5 mr-1"
                                    [svgIcon]="
                                        'heroicons_solid:building-office'
                                    "
                                >
                                </mat-icon>
                                <span class="truncate">
                                    {{
                                        microAction.companyAgencyLocation.city
                                    }}</span
                                >
                            </div>
                            <div
                                *ngIf="!microAction.companyAgencyLocation"
                                class="flex truncate"
                            >
                                <mat-icon
                                    class="icon-size-5 mr-1"
                                    [svgIcon]="
                                        'heroicons_solid:globe-europe-africa'
                                    "
                                >
                                </mat-icon>
                                <span class="truncate">
                                    {{
                                        "microactions.card.all-agencies"
                                            | transloco
                                    }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="my-auto overflow-y-scroll flex flex-wrap gap-x-2 gap-y-1"
            >
                <bslk-tag
                    [background]="'primary'"
                    [size]="'small'"
                    *ngFor="let skill of microAction.activityFields"
                    >{{ "enums.EActivityField." + skill | transloco }}</bslk-tag
                >
            </div>
        </div>
    </div>
</div>
