import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'bslk-classic-card',
    standalone: true,
    templateUrl: './classic-card.component.html',
    imports: [
        NgIf,
        MatProgressSpinnerModule,
        NgClass,
        MatTooltipModule,
        MatIconModule,
    ],
})
export class BslkClassicCardComponent {
    @Input() title: string;
    @Input() titleIcon: string;
    @Input() isLoading = false;
    @Input() disabled: boolean;
    @Input() disabledText: string;
    @Input() overflow = 'hidden';
    @Input() fullHeight = false;
}
