<company-csr-approach-card
    (editing)="onEdit($event)"
    (hiding)="onHiding($event)"
    [isLoading]="isLoading"
    [isLoadingUpdate]="isLoadingUpdate"
    [isCompanyAdmin]="isCompanyAdmin"
    [isValidateDisabled]="isValidateDisabled"
    [isHidden]="csrApproach?.hideMatrix"
    [isEditing]="isEditing"
    [title]="
        csrApproach?.matrix?.name ?? 'csrapproach.matrix.title' | transloco
    "
    [group]="formGroup"
>
    <div
        #matrixContainer
        class="w-full flex flex-col justify-center gap-4 xl:gap-6 xxl:gap-8 items-center"
    >
        <div class="w-full flex gap-4 justify-around">
            <div
                *ngIf="csrApproach?.matrix.legends.length > 0"
                class="flex flex-col gap-2 shadow-lg rounded-lg bg-white p-4"
            >
                <span class="text-2xl font-bold">{{
                    "csrapproach.matrix.legend" | transloco
                }}</span>

                <div
                    *ngFor="let legend of csrApproach?.matrix.legends"
                    (click)="highlightLegend(legend.id)"
                    class="flex cursor-pointer gap-2"
                >
                    <div
                        class="w-5 h-5 rounded-full"
                        [style.backgroundColor]="legend.color"
                    ></div>
                    <span
                        [class.font-bold]="legend.id === highlightedLegendId"
                        >{{ legend.name }}</span
                    >
                </div>
            </div>
            <div class="hidden md:flex flex-col justify-center">
                <fuse-alert appearance="border" class="max-w-100" type="info">
                    <span *ngIf="!isEditing">{{
                        "csrapproach.matrix.info-view" | transloco
                    }}</span>
                    <span *ngIf="isEditing">{{
                        "csrapproach.matrix.info-edit" | transloco
                    }}</span>
                </fuse-alert>
            </div>
        </div>

        <div class="relative overflow-x-scroll">
            <canvas
                #matrixCanvas
                [width]="graphWidth"
                height="410"
                class="border border-gray-300"
                [ngClass]="{
                    'bg-white': !isCompanyAdmin,
                    'bg-accent': isCompanyAdmin
                }"
                (click)="onCanvasClick($event)"
                (mousemove)="onCanvasMouseMove($event)"
                (mousedown)="onCanvasMouseDown($event)"
                (mousemove)="onCanvasMouseMove($event)"
                (mouseup)="onCanvasMouseUp($event)"
            ></canvas>
        </div>
        <edit-matrix class="w-full" *ngIf="isEditing"></edit-matrix>
    </div>
</company-csr-approach-card>

<company-csr-approach-edit-matrix-point
    (close)="editDrawerClosed()"
    [isCreating]="isCreating"
    [editedPointIndex]="editedPointIndex"
></company-csr-approach-edit-matrix-point>

<company-csr-approach-view-matrix-point
    (close)="viewDrawerClosed()"
    [point]="viewedPoint"
></company-csr-approach-view-matrix-point>
