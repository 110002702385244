import {
    AwarenessUserCampaignProgressionViewModelPaginatedListDto,
    DecimalPeriodChart,
    DecimalValueChart,
    EChartTimePeriod,
    EFeature,
    EGenderDecimalEnumChart,
    ESustainableDevelopmentGoalInt32EnumChart,
    ESustainableDevelopmentGoalSdgDonationCampaignKpiEnumChart,
    MissionRegisteredViewModel,
    MissionRegisteredViewModelPaginatedListDto,
} from 'app/api';
import { ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart } from 'app/api/model/eSustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart';
import { PartialRecord } from 'app/shared/types/common.types';

export interface CompanyAdminDashboardState {
    activeTab: CompanyAdminTab;
    kpiFilters: Record<CompanyAdminKpi, CompanyAdminKpiFilters>;
    kpiResults: PartialRecord<CompanyAdminKpi, CompanyAdminKpiResponse>;
    configuration: CompanyAdminDashboardConfiguration;
    isLoading: Record<CompanyAdminKpi, boolean>;
    expandedKpi?: CompanyAdminKpi;

    isLoadingExport: boolean;
}

export type CompanyAdminTab =
    | 'overview'
    | 'employee'
    | 'missions'
    | 'accounting';

export type CompanyAdminKpi =
    | 'all-company-missions'
    | 'awareness-progression'
    | 'awareness-rate'
    | 'cumulative-spending'
    | 'donation-campaigns-impact'
    | 'dual-quiz-answers-by-sdg'
    | 'employees-on-assignment'
    | 'gender-participation-repartition'
    | 'missions-by-goals'
    | 'participation-rate'
    | 'satisfaction-rate'
    | 'tracking-my-impact';

export const companyAdminKpiLinkedFeature: Record<CompanyAdminKpi, EFeature> = {
    'all-company-missions': EFeature.SkillsSponsorship,
    'awareness-progression': EFeature.Awareness,
    'awareness-rate': EFeature.Awareness,
    'cumulative-spending': EFeature.DonationGifts,
    'donation-campaigns-impact': EFeature.DonationGifts,
    'dual-quiz-answers-by-sdg': EFeature.Awareness,
    'employees-on-assignment': EFeature.SkillsSponsorship,
    'gender-participation-repartition': EFeature.SkillsSponsorship,
    'missions-by-goals': EFeature.SkillsSponsorship,
    'participation-rate': EFeature.SkillsSponsorship,
    'satisfaction-rate': EFeature.SkillsSponsorship,
    'tracking-my-impact': EFeature.SkillsSponsorship,
};

export type CompanyAdminKpiResponse =
    | AwarenessUserCampaignProgressionViewModelPaginatedListDto
    | DecimalPeriodChart
    | DecimalValueChart
    | EGenderDecimalEnumChart
    | ESustainableDevelopmentGoalInt32EnumChart
    | ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart
    | MissionRegisteredViewModel[]
    | MissionRegisteredViewModelPaginatedListDto
    | ESustainableDevelopmentGoalSdgDonationCampaignKpiEnumChart;

export interface CompanyAdminKpiFilters {
    timePeriod?: EChartTimePeriod;
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
    nameContains?: string;
}

export type CompanyAdminDashboardTabConfiguration = PartialRecord<
    CompanyAdminKpi,
    boolean
>;

export type CompanyAdminDashboardConfiguration = Record<
    CompanyAdminTab,
    CompanyAdminDashboardTabConfiguration
>;

export interface CompanyAdminKpiExtendedEvent {
    kpi: CompanyAdminKpi;
    isExpanded: boolean;
}
