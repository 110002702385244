import { Route } from '@angular/router';
import { AssoCreatedGuard } from 'app/core/auth/guards/asso-created.guard';
import { AssoSignupGuard } from 'app/core/auth/guards/asso-sign-up.guard';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AssociationSignUpComponent } from '../auth/sign-up/association/association-sign-up.component';
import { MissionViewComponent } from '../regular/mission/view/mission-view.component';
import { ProjectViewComponent } from '../regular/project/view/project-view.component';
import { AssoDashboardComponent } from './dashboard/asso-dashboard.component';
import { FindPotentialMatchesComponent } from './find-potential-matches/find-potential-matches.component';
import { MissionListComponent } from './mission-list/mission-list.component';
import { MissionManageAssociationComponent } from './mission/manage/mission-manage-association.component';
import { AssociationProjectListComponent } from './project-list/project-list.component';

export const associationRoutes: Route[] = [
    { path: 'a', pathMatch: 'full', redirectTo: 'a/dashboard' },

    {
        path: 'a',
        canActivate: [AssoSignupGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-up',
                component: AssociationSignUpComponent,
            },
        ],
    },

    {
        path: 'a',
        canActivate: [AuthGuard, AssoCreatedGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                component: AssoDashboardComponent,
            },
            {
                path: 'organization',
                loadChildren: () =>
                    import(
                        'app/modules/association/profile/asso-profile.routes'
                    ),
            },
            {
                path: 'missions',
                children: [
                    {
                        path: '',
                        component: MissionListComponent,
                    },
                    {
                        path: ':id',
                        component: MissionViewComponent,
                    },
                    {
                        path: ':id/manage',
                        component: MissionManageAssociationComponent,
                    },
                    {
                        path: ':id/manage/:missionSlotId',
                        component: MissionManageAssociationComponent,
                    },
                ],
            },
            {
                path: 'find-volunteers',
                component: FindPotentialMatchesComponent,
            },
            {
                path: 'projects',
                children: [
                    {
                        path: '',
                        component: AssociationProjectListComponent,
                    },
                    {
                        path: ':id',
                        component: ProjectViewComponent,
                    },
                ],
            },
            {
                path: 'users',
                loadChildren: () =>
                    import(
                        'app/modules/association/association-users/association-users.routes'
                    ),
            },
        ],
    },
];
