<div class="p-4 md:p-6 xxl:p-8 w-full">
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "setup.title" | transloco
            }}</span>
            <span class="text-lg">{{ "setup.subtitle" | transloco }}</span>
        </div>
    </div>

    <div class="grid md:grid-cols-2 gap-4">
        <company-customization
            class="col-span-2 md:col-span-1"
        ></company-customization>
        <administrative-contact
            class="col-span-2 md:col-span-1"
        ></administrative-contact>
        <company-employees class="col-span-2"></company-employees>
    </div>
</div>
