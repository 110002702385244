import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AssociationDashboardActions } from 'app/store/association/association-dashboard/association-dashboard.actions';
import { AssociationDashboardSelectors } from 'app/store/association/association-dashboard/association-dashboard.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'missions-kpis',
    templateUrl: './missions-kpis.component.html',
    standalone: true,
    imports: [
        LetDirective,
        AsyncPipe,
        NgIf,
        TranslocoModule,
        MatIconModule,
        NgxSkeletonLoaderModule,
    ],
})
export class MissionsKpisComponent implements OnInit {
    kpis$ = this.store.select(
        AssociationDashboardSelectors.selectKpiResult('missions-kpis')
    );

    isLoading$ = this.store.select(
        AssociationDashboardSelectors.selectIsLoading('missions-kpis')
    );

    constructor(private store: Store) {}

    ngOnInit() {
        this.store.dispatch(
            AssociationDashboardActions.checkIfKpiIsLoaded({
                kpi: 'missions-kpis',
            })
        );
    }
}
