<base-dialog
    (onClose)="close()"
    [title]="'companyadmindashboard.configure-dashboard' | transloco"
    [showActions]="true"
    [hideBodyPadding]="true"
>
    <div
        *ngrxLet="selectDisabledKpis$ as selectDisabledKpis"
        class="w-full h-full flex flex-col justify-center"
        body
    >
        <mat-tab-group
            [selectedIndex]="activeTabIndex$ | async"
            *ngrxLet="configuration$ as configuration"
        >
            <mat-tab
                *ngFor="
                    let tab of enabledTabs$
                        | async
                        | keyvalue : keepOriginalOrder
                "
                [disabled]="!tab.value"
                [label]="'companyadmindashboard.tabs.' + tab.key | transloco"
            >
                <div class="grid grid-cols-2">
                    <mat-checkbox
                        (change)="onConfigChange(option.key, tab.key, $event)"
                        *ngFor="let option of configuration[tab.key] | keyvalue"
                        color="primary"
                        [checked]="option.value"
                        [disabled]="selectDisabledKpis?.includes(option.key)"
                    >
                        <span class="font-semibold">{{
                            "companyadmindashboard.kpis." + option.key
                                | transloco
                        }}</span>
                    </mat-checkbox>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="flex justify-end" actions>
        <button
            (click)="close()"
            [color]="'accent'"
            class="z-10 text-white m-auto"
            mat-flat-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            <span class="ml-1">{{ "shared.common.close" | transloco }}</span>
        </button>
    </div>
</base-dialog>
