import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkRoundIconComponent } from '@bslk/components/round-icon/round-icon.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'cover-template',
    standalone: true,
    templateUrl: './cover-template.component.html',
    imports: [
        AsyncPipe,
        BslkRoundIconComponent,
        ClassicAvatarComponent,
        LetDirective,
        MatIconModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgClass,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        RouterLink,
        TranslocoModule,
    ],
})
export class CoverTemplateComponent {
    @Input() title: string;
    @Input() titleIcon: string;
    @Input() subtitle: string;
    @Input() coverUrl: string;
    @Input() imagesUrl: string[];
    @Input() logoUrl: string;
    @Input() logoFirstLetter: string;
    @Input() isLoading = true;
    @Input() emitOnClose = false;
    @Input() closeFixedPosition = false;
    @Input() hideLogo = false;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    onClose() {
        if (this.emitOnClose) {
            this.close.emit();
        } else {
            window.history.back();
        }
    }
}
