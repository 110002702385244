import { BslkAdvancedSelectSyncInput } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { createSelector } from '@ngrx/store';
import { AppState } from '../..';

const selectState = (state: AppState) => state.companyCsrApproach;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsLoadingUpdate = createSelector(
    selectState,
    (state) => state.isLoadingUpdate
);

const selectIsLoadingImageUpload = createSelector(
    selectState,
    (state) => state.isLoadingImageUpload
);

const selectIsLoadingLegendCreateOrUpdate = createSelector(
    selectState,
    (state) => state.isLoadingLegendCreateOrUpdate
);

const selectCsrApproach = createSelector(
    selectState,
    (state) => state.csrApproach
);

const selectMatrixLegendsAsSelectInput = createSelector(selectState, (state) =>
    state.csrApproach?.matrix?.legends.map(
        (legend) =>
            ({
                name: legend.name,
                value: legend.id,
            } as BslkAdvancedSelectSyncInput)
    )
);

export const CompanyCsrApproachSelectors = {
    selectIsLoading,
    selectCsrApproach,
    selectIsLoadingImageUpload,
    selectIsLoadingLegendCreateOrUpdate,
    selectMatrixLegendsAsSelectInput,
    selectIsLoadingUpdate,
};
