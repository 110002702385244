<div *ngrxLet="items$ as items" class="single-select">
    <div *ngrxLet="isLoading$ as isLoading" class="flex w-full">
        <mat-progress-spinner
            [ngClass]="{
                'w-0 mr-0': !isLoading,
                'w-1/12 mr-3': isLoading
            }"
            class="self-end mb-3 transition-all duration-300"
            [diameter]="30"
            [mode]="'indeterminate'"
        ></mat-progress-spinner>

        <mat-form-field
            floatLabel="always"
            [subscriptSizing]="'dynamic'"
            [ngClass]="{
                'w-full': !isLoading,
                'w-11/12': isLoading
            }"
            class="transition-all duration-300"
        >
            <mat-label>{{ label }} </mat-label>
            <span
                class="whitespace-nowrap w-full"
                *ngIf="showInitialValue && !isLoading"
                >{{ initialValue }}</span
            >

            <mat-select
                [placeholder]="isLoading ? '' : selectPlaceholder"
                (openedChange)="onMatSelectOpenedChange($event)"
                (selectionChange)="onChange()"
                [formControl]="control"
                [multiple]="multiple"
            >
                <div class="relative">
                    <div class="search sticky top-0 z-99 border-b-2">
                        <mat-form-field
                            [subscriptSizing]="'dynamic'"
                            class="search-input py-2 w-full px-1 bg-warn-100"
                        >
                            <input
                                #searchInput
                                [placeholder]="searchPlaceholder"
                                matInput
                                (keyup)="onSearch($event.target.value)"
                            />
                        </mat-form-field>
                    </div>

                    <mat-option
                        *ngFor="let item of items"
                        [value]="valueIsId ? item.id : item"
                        >{{ item.name }}</mat-option
                    >
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <fuse-alert
        class="mt-2"
        *ngIf="
            items?.length === 0 &&
            isSearchEmpty &&
            noDataText &&
            !(isLoading$ | async)
        "
        [type]="'warn'"
    >
        {{ noDataText }}
    </fuse-alert>
</div>
