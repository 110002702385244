import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EFeature } from 'app/api';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import { CompanyAdminTab } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.state';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { CompanyAdminDashboardExportDialogComponent } from './export-dialog/export-dialog.component';
import { CompanyAdminDashboardReportDialogComponent } from './report-dialog/report-dialog.component';
import { CompanyAdminDashboardSettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { CompanyAdminDashboardHeaderTabComponent } from './tab/tab.component';

@Component({
    selector: 'company-admin-dashboard-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        MatIconModule,
        CompanyAdminDashboardHeaderTabComponent,
        LetDirective,
        TranslocoModule,
        BslkTagComponent,
    ],
})
export class CompanyAdminDashboardHeaderComponent {
    activeTab$ = this.store.select(
        CompanyAdminDashboardSelectors.selectActiveTab
    );

    firstName$ = this.store.select(UserSelectors.selectFirstName);

    isSkillsSponsorshipAllowed$ = this.store.select(
        UserSelectors.selectIsFeatureAllowed(EFeature.SkillsSponsorship)
    );
    isDonationGiftsAllowed$ = this.store.select(
        UserSelectors.selectIsFeatureAllowed(EFeature.DonationGifts)
    );

    constructor(private store: Store, private dialog: MatDialog) {}

    switchTab(tab: CompanyAdminTab, isEnabled: boolean) {
        if (isEnabled) {
            this.store.dispatch(
                CompanyAdminDashboardActions.switchTab({ tab })
            );
        }
    }

    openSettingsDialog() {
        this.dialog.open(CompanyAdminDashboardSettingsDialogComponent);
    }

    openExportDialog() {
        this.dialog.open(CompanyAdminDashboardExportDialogComponent);
    }

    openReportDialog() {
        this.dialog.open(CompanyAdminDashboardReportDialogComponent);
    }
}
