<div *ngrxLet="approvals$ as approvals" class="">
    <div
        class="flex items-end truncate justify-between pb-1 mb-6 border-b-2 border-slate-300"
    >
        <span class="text-3xl md:text-4xl font-bold truncate">{{
            "associationdashboard.my-pending-approvals" | transloco
        }}</span>
    </div>
    <div class="grid gap-4 grid-cols-1 md:grid-cols-2 xxl:grid-cols-3">
        <ng-container *ngIf="!(isLoading$ | async); else loading">
            <div
                *ngIf="approvals.length === 0"
                class="col-span-12 flex flex-col w-full text-center"
            >
                <span class="flex items-center font-bold m-auto text-2xl">
                    {{
                        "associationdashboard.no-pending-approvals" | transloco
                    }}
                    <mat-icon
                        class="ml-1 text-black text-2xl"
                        [svgIcon]="'mat_outline:approval'"
                    ></mat-icon>
                </span>
                <span class="mt-1">{{
                    "associationdashboard.no-pending-approvals-description"
                        | transloco
                }}</span>
            </div>

            <div *ngFor="let pendingApproval of approvals">
                <div
                    (click)="openSlot(pendingApproval)"
                    class="cursor-pointer hover:scale-105 transition-all duration-300 from-primary-300 to-warn-300 bg-gradient-to-r p-4 shadow-lg rounded-xl"
                >
                    <div
                        class="border-b border-black flex justify-between pb-2 truncate"
                    >
                        <span class="font-bold text-xl truncate">{{
                            pendingApproval.missionName
                        }}</span>
                    </div>

                    <div
                        class="border-b border-black py-3 text-lg font-bold flex gap-2"
                    >
                        <mat-icon
                            class="text-current"
                            [svgIcon]="'heroicons_solid:calendar'"
                        ></mat-icon>
                        {{
                            formatDateRange(
                                pendingApproval.startDate,
                                pendingApproval.endDate
                            )
                        }}
                    </div>

                    <div class="pt-2">
                        <div
                            class="py-3"
                            *ngFor="
                                let user of pendingApproval.users;
                                let isLast = last
                            "
                        >
                            <div class="flex items-center gap-2">
                                <mat-icon
                                    class="text-current"
                                    [svgIcon]="'heroicons_solid:user'"
                                ></mat-icon>
                                <div
                                    [class.mb-1]="!isLast"
                                    class="flex justify-between"
                                >
                                    <div class="flex flex-col">
                                        <span class="font-semibold text-lg">{{
                                            user.firstName +
                                                " " +
                                                user.familyName
                                        }}</span>
                                        <span>{{ user.jobTitle }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #loading>
    <div class="shadow-lg min-h-36 rounded-xl">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
    <div class="shadow-lg min-h-36 rounded-xl">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
    <div class="shadow-lg min-h-36 rounded-xl">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
    <div class="shadow-lg min-h-36 rounded-xl">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
