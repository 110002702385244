import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkClassicProgressBar } from '@bslk/components/progress-bar/classic-progress-bar/classic-progress-bar.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { tap } from 'rxjs';

@Component({
    selector: 'donation-campaign-requests-repartition',
    templateUrl: './requests-repartition.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicProgressBar,
        BslkTagComponent,
        BslkTagButtonComponent,
        ClassicAvatarComponent,
        LetDirective,
        MatIconModule,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class DonationCampaignRequestsRepartitionComponent
    implements AfterViewInit
{
    @ViewChild('requestsRepartition') requestsRepartition: ElementRef;

    isWaitingForTieSettlement = false;

    requestsRepartition$ = this.store.select(
        DonationCampaignSelectors.selectRequestsRepartition
    );

    winningProjectId$ = this.store.select(
        DonationCampaignSelectors.selectWinningProjectId
    );

    tiedWinningProjectIds$ = this.store.select(
        DonationCampaignSelectors.selectTiedWinningProjectIds
    );

    isLoading$ = this.store.select(
        DonationCampaignSelectors.selectIsLoadingUpdate
    );

    isWaitingForTieSettlement$ = this.store
        .select(DonationCampaignSelectors.selectIsWaitingForTieSettlement)
        .pipe(
            tap((isWaitingForTieSettlement) => {
                this.isWaitingForTieSettlement = isWaitingForTieSettlement;
            })
        );

    constructor(private store: Store) {}

    ngAfterViewInit(): void {
        if (this.isWaitingForTieSettlement) {
            this.requestsRepartition.nativeElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }

    setWinningProject(projectId: number) {
        this.store.dispatch(
            DonationCampaignActions.setWinningProjectRequest({ projectId })
        );
    }
}
