import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { SetupActions } from 'app/store/setup/setup.action';
import { SetupSelectors } from 'app/store/setup/setup.selectors';
import { CompanyUsersActions } from 'app/store/user/company-users/company-users.actions';
import { CompanyUsersSelectors } from 'app/store/user/company-users/company-users.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'administrative-contact',
    templateUrl: './administrative-contact.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        LetDirective,
        BslkClassicButtonComponent,
        NgClass,
        SingleSelectAsyncComponent,
        FormsModule,
        ReactiveFormsModule,
        AsyncPipe,
        BslkTagButtonComponent,
        BslkClassicCardComponent,
        BslkInfoComponent,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        NgxSkeletonLoaderModule,
        NgIf,
        NgFor,
    ],
})
export class CompanyAdministrativeContactComponent implements OnDestroy {
    CompanyUsersActions = CompanyUsersActions;
    CompanyUsersSelectors = CompanyUsersSelectors;

    unsubscribeAll: Subject<any> = new Subject<any>();

    control = new FormControl();

    isLoadingUpdate$ = this.store.select(
        SetupSelectors.selectIsLoadingAdministrativeContactUpdate
    );

    isLoadingCompany$ = this.store.select(
        SetupSelectors.selectIsLoadingCompany
    );

    company$ = this.store
        .select(SetupSelectors.selectCompany)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((company) => {
            if (company) {
                this.control.setValue(company.administrativeContactId);
            }
        });

    constructor(private store: Store) {}

    get isControlDirty() {
        return this.control.dirty;
    }

    save() {
        this.store.dispatch(
            SetupActions.updateAdministrativeContactRequest({
                userId: this.control.value,
            })
        );
        this.control.markAsPristine();
    }

    remove() {
        this.control.setValue(null);
        this.control.markAsDirty();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
