import { createReducer, on } from '@ngrx/store';
import { clone } from 'lodash-es';
import { MicroActionActions } from './micro-action.action';
import { MicroActionState } from './micro-action.state';

export const microActionInitialState: MicroActionState = {
    isLoading: false,
    isCreatingLoading: false,
    isUploadFileLoading: false,
    isLoadingRegistration: false,
    isLoadingArchive: false,
    isLoadingMarkActivityAsCompleted: false,
};

export const microActionReducer = createReducer(
    microActionInitialState,

    on(MicroActionActions.openDialog, (state) => {
        return {
            ...state,
            coverFile: null,
        };
    }),

    on(MicroActionActions.setCoverRequest, (state, { file }) => {
        return {
            ...state,
            coverFile: file,
        };
    }),

    on(MicroActionActions.createMicroActionRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(MicroActionActions.createMicroActionRequestSuccess, (state, { id }) => {
        return {
            ...state,
            isCreatingLoading: false,
        };
    }),

    on(MicroActionActions.createMicroActionRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(MicroActionActions.updateCoverRequest, (state) => {
        return {
            ...state,
            isUploadFileLoading: true,
        };
    }),

    on(MicroActionActions.updateCoverRequestFail, (state) => {
        return {
            ...state,
            isUploadFileLoading: false,
        };
    }),

    on(MicroActionActions.updateCoverRequestSuccess, (state, { url }) => {
        const microAction = clone(state.microAction);
        microAction.coverUrl = url;
        return {
            ...state,
            coverFile: null,
            isUploadFileLoading: false,
            microAction,
        };
    }),

    on(MicroActionActions.loadMicroActionRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(
        MicroActionActions.loadMicroActionRequestSuccess,
        (state, { microAction }) => {
            return {
                ...state,
                microAction,
                isLoading: false,
            };
        }
    ),

    on(MicroActionActions.loadMicroActionRequestFail, (state, { error }) => {
        return {
            ...state,
            error,
            isLoading: false,
        };
    }),

    on(MicroActionActions.updateMicroActionRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(MicroActionActions.updateMicroActionRequestSuccess, (state) => {
        return {
            ...state,
            isCreatingLoading: false,
        };
    }),

    on(MicroActionActions.updateMicroActionRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(MicroActionActions.registerMicroActionRequest, (state) => {
        return {
            ...state,
            isLoadingRegistration: true,
        };
    }),

    on(MicroActionActions.registerMicroActionRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingRegistration: false,
        };
    }),

    on(MicroActionActions.registerMicroActionRequestFail, (state) => {
        return {
            ...state,
            isLoadingRegistration: false,
        };
    }),

    on(MicroActionActions.unregisterMicroActionRequest, (state) => {
        return {
            ...state,
            isLoadingRegistration: true,
        };
    }),

    on(MicroActionActions.unregisterMicroActionRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingRegistration: false,
        };
    }),

    on(MicroActionActions.unregisterMicroActionRequestFail, (state) => {
        return {
            ...state,
            isLoadingRegistration: false,
        };
    }),

    on(MicroActionActions.archiveMicroActionRequest, (state) => {
        return {
            ...state,
            isLoadingArchive: true,
        };
    }),

    on(MicroActionActions.archiveMicroActionRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingArchive: false,
        };
    }),

    on(MicroActionActions.archiveMicroActionRequestFail, (state) => {
        return {
            ...state,
            isLoadingArchive: false,
        };
    }),

    on(MicroActionActions.markActivityAsCompletedRequest, (state) => {
        return {
            ...state,
            isLoadingMarkActivityAsCompleted: true,
        };
    }),

    on(MicroActionActions.markActivityAsCompletedRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingMarkActivityAsCompleted: false,
        };
    }),

    on(MicroActionActions.markActivityAsCompletedRequestFail, (state) => {
        return {
            ...state,
            isLoadingMarkActivityAsCompleted: false,
        };
    }),

    on(MicroActionActions.unmarkActivityAsCompletedRequest, (state) => {
        return {
            ...state,
            isLoadingMarkActivityAsCompleted: true,
        };
    }),

    on(MicroActionActions.unmarkActivityAsCompletedRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingMarkActivityAsCompleted: false,
        };
    }),

    on(MicroActionActions.unmarkActivityAsCompletedRequestFail, (state) => {
        return {
            ...state,
            isLoadingMarkActivityAsCompleted: false,
        };
    })
);
