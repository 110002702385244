import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MicroActionCommentViewModel } from 'app/api';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class MicroActionCommentFormService
    implements IFormService<MicroActionCommentViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder, private datePipe: DatePipe) {
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [0],
            comment: ['', [Validators.required, Validators.maxLength(500)]],
            microActionId: [null],
        });
        return this.formGroup;
    }

    updateForm(entity: MicroActionCommentViewModel) {
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: MicroActionCommentViewModel } {
        const microActionCommentViewModel = this.formGroup.value;
        return { microActionCommentViewModel };
    }
}
