<div class="flex gap-3 items-center">
    <div
        [matTooltip]="'bslk.reward.purpose-coins-tooltip' | transloco"
        class="flex items-center"
        [class.text-sm]="isSmall"
        [class.text-2xl]="isLarge"
        *ngIf="purposeCoins"
    >
        <span>+{{ rewardPurposeCoins }}</span>
        <mat-icon
            [class.icon-size-4]="isSmall"
            [class.icon-size-8]="isLarge"
            class="text-warn ml-1"
            [svgIcon]="'bslk_core:purpose-coins'"
        ></mat-icon>
    </div>
    <div
        [matTooltip]="'bslk.reward.experience-points-tooltip' | transloco"
        class="flex items-center"
        [class.text-sm]="isSmall"
        [class.text-2xl]="isLarge"
        *ngIf="experiencePoints"
    >
        <span>+{{ rewardExperiencePoints }}</span>
        <mat-icon
            [class.icon-size-4]="isSmall"
            [class.icon-size-8]="isLarge"
            class="text-tertiary ml-1"
            [svgIcon]="'basile-flat'"
        ></mat-icon>
    </div>
</div>
