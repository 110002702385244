import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { EMicroActionType, MicroActionViewModel } from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { ERegularUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { UserSelectors } from 'app/store/user/user/user.selectors';

@Component({
    selector: 'micro-action-grid-card',
    templateUrl: './micro-action-grid-card.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslocoModule,
        MatTooltipModule,
        NgIf,
        BslkTagComponent,
        NgFor,
        MatIconModule,
    ],
})
export class MicroActionGridCardComponent {
    @Input() microAction: MicroActionViewModel;

    companyHasAgencies$ = this.store.select(
        UserSelectors.selectCompanyHasAgencies
    );

    constructor(
        private datePipe: DatePipe,
        private router: Router,
        private store: Store
    ) {}

    EMicroActionType = EMicroActionType;

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    view() {
        this.router.navigateByUrl(
            UrlHelpers.getRegularUrl(ERegularUrl.RegularMicroActionPage, {
                id: this.microAction.id,
            })
        );
    }
}
