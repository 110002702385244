import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterModule } from '@angular/router';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EFeature, ETrackedActivityType } from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { ExperienceBarComponent } from 'app/shared/components/experience-bar/experience-bar.component';
import { MissionGridCardComponent } from 'app/shared/components/mission/grid/mission-grid-card.component';
import { MissionRegisteredRowCardComponent } from 'app/shared/components/mission/row/registered/mission-registered-row-card.component';
import { OnboardingProgressComponent } from 'app/shared/components/onboarding-progress/onboarding-progress.component';
import { UrlHelpersPipe } from 'app/shared/pipes/url-helpers.pipe';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { MyBasileSelectors } from 'app/store/my-basile/my-basilie.selectors';
import { RegularDashboardActions } from 'app/store/regular-dashboard/regular-dashboard.actions';
import { RegularDashboardSelectors } from 'app/store/regular-dashboard/regular-dashboard.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { sample } from 'lodash-es';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RegularDashboardCalendarComponent } from './calendar/regular-dashboard-calendar.component';
import { GoodNewsComponent } from './good-news/good-news/good-news.component';
import { LevelInformationComponent } from './level-information/level-information.component';
import { RegulardDashboardManagerApprovalsComponent as RegularDashboardManagerApprovalsComponent } from './manager-approvals/regulard-dashboard-manager-approvals/regulard-dashboard-manager-approvals.component';
import { PendingGiftsComponent } from './pending-gifts/pending-gifts.component';
import { UserRatingsComponent } from './ratings/my-ratings/my-ratings.component';

@Component({
    selector: 'regular-dashboard',
    standalone: true,
    templateUrl: './regular-dashboard.component.html',
    imports: [
        AsyncPipe,
        BasileAvatarComponent,
        BslkClassicCardComponent,
        ClassicAvatarComponent,
        ExperienceBarComponent,
        ExperienceBarComponent,
        GoodNewsComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MissionGridCardComponent,
        MissionRegisteredRowCardComponent,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        OnboardingProgressComponent,
        LevelInformationComponent,
        RegularDashboardCalendarComponent,
        RegularDashboardManagerApprovalsComponent,
        RouterModule,
        TranslocoModule,
        UrlHelpersPipe,
        RouterLink,
        UserRatingsComponent,
        PendingGiftsComponent,
    ],
})
export class RegularDashboardComponent implements OnInit {
    basilePosture = sample([
        'enthusiast',
        'flexing',
        'happy',
        'hello',
        'jumping',
        'reading',
        'peeking-left',
        'peeking-right',
        'speaking',
        'writing',
    ]);

    firstNameFirstLetter$ = this.store.select(
        UserSelectors.selectNameFirstLetter
    );
    avatarUrl$ = this.store.select(UserSelectors.selectAvatarUrl);
    displayedName$ = this.store.select(UserSelectors.selectFirstName);
    isLoading$ = this.store.select(
        RegularDashboardSelectors.selectIsLoadingUserRecap
    );
    isManager$ = this.store.select(UserSelectors.selectIsManager);
    recap$ = this.store.select(RegularDashboardSelectors.selectUserRecap);
    onboardingTasks$ = this.store.select(UserSelectors.selectOnboardingTasks);
    showOnboardingTasks$ = this.store.select(UserSelectors.showOnboardingTasks);
    basileConfig$ = this.store.select(
        MyBasileSelectors.selectBasileConfiguration
    );
    levelInformation$ = this.store.select(
        UserSelectors.selectUserLevelInformation
    );
    hasAnyPendingDonationGift$ = this.store.select(
        RegularDashboardSelectors.selectHasAnyPendingDonationGift
    );
    isSkillsSponsorshipAllowed$ = this.store.select(
        UserSelectors.selectIsFeatureAllowed(EFeature.SkillsSponsorship)
    );
    isDonationGiftsAllowed$ = this.store.select(
        UserSelectors.selectIsFeatureAllowed(EFeature.DonationGifts)
    );

    constructor(
        private store: Store,
        private activityService: ActivityService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(RegularDashboardActions.loadRecapRequest());
        this.activityService.sendActivityRequest({
            trackedActivityType: ETrackedActivityType.ViewDashboardPage,
        });
    }
}
