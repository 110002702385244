import {
    AsyncPipe,
    CommonModule,
    DatePipe,
    JsonPipe,
    NgClass,
    NgIf,
} from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BslkAdvancedChartComponent } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { BslkChartComponent } from '@bslk/components/charts/bslk-chart/bslk-chart.component';
import { BslkHexColors } from '@bslk/types/bslk-types';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DecimalPeriodChart } from 'app/api';
import { getChartDateRepresentation } from 'app/shared/utils/date-helpers.utils';
import { AssociationDashboardActions } from 'app/store/association/association-dashboard/association-dashboard.actions';
import { AssociationDashboardSelectors } from 'app/store/association/association-dashboard/association-dashboard.selectors';
import { isNil } from 'lodash-es';
import { ApexOptions, NgApexchartsModule } from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { AssociationDashboardChartComponent } from '../chart.component';

@Component({
    selector: 'project-donations',
    templateUrl: './project-donations.component.html',
    standalone: true,
    imports: [
        NgApexchartsModule,
        JsonPipe,
        AsyncPipe,
        CommonModule,
        NgIf,
        NgClass,
        LetDirective,
        BslkChartComponent,
        BslkAdvancedChartComponent,
        TranslocoModule,
    ],
})
export class ProjectDonationsComponent
    extends AssociationDashboardChartComponent
    implements OnInit, OnDestroy
{
    expandOptions: Partial<ApexOptions> = {
        dataLabels: {
            enabled: true,
        },
        yaxis: {
            show: true,
        },
        xaxis: {
            labels: {
                show: true,
            },
        },
        grid: {
            show: true,
        },
    };
    projectDonations: number;

    constructor(
        protected store: Store,
        protected datePipe: DatePipe,
        protected cdRef: ChangeDetectorRef,
        protected translocoService: TranslocoService
    ) {
        super(store, datePipe, cdRef, 'project-donations');
    }

    get color(): string {
        const rate = this.projectDonations;
        if (isNil(rate)) {
            return null;
        }
        if (rate >= 50) {
            return BslkHexColors.quaternary;
        } else if (rate >= 25) {
            return BslkHexColors.warn;
        }
        return BslkHexColors.primary;
    }

    ngOnInit() {
        this.store.dispatch(
            AssociationDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );

        this.store
            .select(AssociationDashboardSelectors.selectKpiResult(this.kpiName))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((response: DecimalPeriodChart) => {
                if (response) {
                    const data = Object.values(response.data);
                    this.projectDonations = data.at(data.length - 1);
                    this.labels = Object.keys(response.data).map((d) =>
                        getChartDateRepresentation(
                            this.datePipe,
                            d,
                            response.timePeriod
                        )
                    );
                    this.series = [
                        {
                            name: this.translocoService.translate(
                                'associationdashboard.' + this.kpiName
                            ),
                            data,
                            color: this.color,
                        },
                    ];
                    this.chartOptions = this.buildOptions();
                }
            });
    }
    buildOptions() {
        return {
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                show: false,
            },
            xaxis: {
                labels: {
                    show: false,
                },
            },
            grid: {
                show: false,
            },
        };
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
