import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    AssociationKpi,
    AssociationKpiFilters,
    AssociationKpiResponse,
} from './association-dashboard.state';

export const AssociationDashboardActions = createActionGroup({
    source: 'AssociationDashboard',
    events: {
        'Init Dashboard': emptyProps(),
        'Check If Kpi Is Loaded': props<{ kpi: AssociationKpi }>(),
        'Load Kpi Request': props<{
            kpi: AssociationKpi;
        }>(),
        'Load Kpi Request Success': props<{
            kpi: AssociationKpi;
            response: AssociationKpiResponse;
        }>(),
        'Load Kpi Request Fail': props<{
            kpi: AssociationKpi;
            error: string;
        }>(),
        'Update Kpi Filters': props<{
            kpi: AssociationKpi;
            filters: AssociationKpiFilters;
        }>(),
        'Expand Kpi': props<{ kpi: AssociationKpi }>(),

        'Minimize Kpi': emptyProps(),
    },
});
