import {
    MicroActionViewModel,
    MicroActionViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const MicroActionListActions = {
    ...createRessourceListActionGroup<
        MicroActionViewModel,
        MicroActionViewModelPaginatedListDto
    >('MicroActionList'),
};
