import { DatePipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkMenuComponent } from '@bslk/components/menu/menu.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';

import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ESkill, MissionUnregisteredViewModel } from 'app/api';
import {
    ConfirmationDialogComponent,
    ConfirmationDialogInput,
} from 'app/shared/components/dialog/confirmation/confirmation-dialog.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { MissionActions } from 'app/store/mission/mission/mission.action';
import { MissionSelectors } from 'app/store/mission/mission/mission.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'mission-association-card',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './mission-association-card.component.html',
    imports: [
        BslkMenuComponent,
        BslkTagComponent,
        DatePipe,
        FuseCardComponent,
        EnumTagListComponent,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        RouterLink,
        TranslocoModule,
    ],
})
export class MissionAssociationCardComponent {
    @Input() mission: MissionUnregisteredViewModel;

    ESkill = ESkill;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private datePipe: DatePipe,
        private store: Store,
        private fileUploadDialogService: FileUploadDialogService,
        private dialog: MatDialog
    ) {}

    get lastUpdate() {
        return this.mission.dateUpdated ?? this.mission.dateCreated;
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    modifyMission() {
        this.store.dispatch(
            MissionListActions.SelectItem({ id: this.mission.id })
        );
    }

    archiveMission() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                textKey: 'associationmissions.delete-mission-confirmation',
            } as ConfirmationDialogInput,
        });

        dialogRef.componentInstance.onConfirm
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.store.dispatch(
                    MissionListActions.DeleteItem({ id: this.mission.id })
                );
            });
    }

    publishMission() {
        this.store.dispatch(
            MissionListActions.PublishRequest({ id: this.mission.id })
        );
    }

    copyMission() {
        this.store.dispatch(
            MissionActions.copyMissionRequest({ id: this.mission.id })
        );
    }

    openUploadCoverDialog(id: number) {
        this.fileUploadDialogService.open({
            id,
            action: MissionListActions.UpdateCover,
            isLoading$: this.store.select(
                MissionSelectors.selectIsUploadFileLoading
            ),
            maxFileSize: 2000 * 1024,
            infoTextKey: 'createmission.info-cover',
        });
    }

    onMenuAction(event: string) {
        if (event === 'modify') {
            return this.modifyMission();
        } else if (event === 'archive') {
            return this.archiveMission();
        } else if (event === 'publish') {
            return this.publishMission();
        } else if (event === 'copy') {
            return this.copyMission();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
