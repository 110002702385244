import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    DonationCampaignAnonymousService,
    DonationCampaignService,
    ShareService,
} from 'app/api';
import { AuthService } from 'app/core/auth/auth.service';
import { DonationCampaignShareSettingsDialogService } from 'app/modules/company-admin/donation-campaign/share-link/share-settings-dialog/share-settings-dialog.service';
import { CreateDonationCampaignDialogService } from 'app/modules/company-admin/donation-campaigns/create/create.service';
import { ChooseProjectDialogService } from 'app/modules/external/donation-gift/choose-project-dialog/choose-project-dialog.service';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { NavigationActions } from 'app/store/navigation/navigation.actions';
import { isNil } from 'lodash-es';
import { catchError, exhaustMap, filter, map, mergeMap, of, tap } from 'rxjs';
import { DonationCampaignActions } from './donation-campaign.action';
import { DonationCampaignSelectors } from './donation-campaign.selectors';

@Injectable()
export class DonationCampaignEffects {
    openDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DonationCampaignActions.openDialog),
                tap(({ input }) => {
                    this.createDonationCampaignDialogService.open(input);
                })
            ),
        { dispatch: false }
    );

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DonationCampaignActions.closeDialog),
                tap(() => {
                    this.createDonationCampaignDialogService.close();
                })
            ),
        { dispatch: false }
    );

    loadByToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.loadDonationCampaignRequestByToken),
            exhaustMap(({ token }) => {
                return this.donationCampaignAnonymousService
                    .donationCampaignAnonTokenTokenGet({ token })
                    .pipe(
                        map((donationCampaign) =>
                            DonationCampaignActions.loadDonationCampaignRequestSuccess(
                                { donationCampaign }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.loadDonationCampaignRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    loadById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.loadDonationCampaignRequestById),
            exhaustMap(({ id }) => {
                return this.donationCampaignService
                    .donationCampaignIdGet({ id })
                    .pipe(
                        map((donationCampaign) => {
                            this.donationCampaignFormService.updateForm(
                                donationCampaign
                            );
                            return DonationCampaignActions.loadDonationCampaignRequestSuccess(
                                { donationCampaign }
                            );
                        }),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.loadDonationCampaignRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    chooseProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.chooseProjectRequest),
            exhaustMap(({ donationRequest }) => {
                return this.donationCampaignAnonymousService
                    .donationCampaignAnonPost({
                        donationRequestViewModel: donationRequest,
                    })
                    .pipe(
                        map((createdDonationRequest) =>
                            DonationCampaignActions.chooseProjectRequestSuccess(
                                { donationRequest: createdDonationRequest }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.chooseProjectRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    closeChoseProjectDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DonationCampaignActions.chooseProjectRequestSuccess,
                    DonationCampaignActions.fakeChooseProjectRequest
                ),
                tap(({ donationRequest }) => {
                    this.chooseProjectDialogService.close();
                    let url = '/thank-you';
                    if (!isNil(donationRequest.id)) {
                        url += '/' + donationRequest.id;
                    }
                    this.router.navigateByUrl(url);
                })
            ),
        { dispatch: false }
    );

    updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.updateDonationCampaignRequest),
            exhaustMap(() => {
                const donationCampaignViewModel =
                    this.donationCampaignFormService.getEntity();
                return this.donationCampaignService
                    .donationCampaignPut(donationCampaignViewModel)
                    .pipe(
                        map(() =>
                            DonationCampaignActions.updateDonationCampaignRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.updateDonationCampaignRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    setWinningProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.setWinningProjectRequest),
            concatLatestFrom(() =>
                this.store.select(
                    DonationCampaignSelectors.selectDonationCampaign
                )
            ),
            exhaustMap(([{ projectId }, campaign]) => {
                return this.donationCampaignService
                    .donationCampaignIdSettleTieProjectIdPut({
                        id: campaign.id,
                        projectId,
                    })
                    .pipe(
                        map(() =>
                            DonationCampaignActions.setWinningProjectRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.setWinningProjectRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    reloadAfterSave$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DonationCampaignActions.updateDonationCampaignRequestSuccess,
                DonationCampaignActions.setWinningProjectRequestSuccess
            ),
            concatLatestFrom(() =>
                this.store.select(
                    DonationCampaignSelectors.selectDonationCampaign
                )
            ),
            mergeMap(([, campaign]) => {
                this.donationCampaignShareSettingsDialogService.close();
                return [
                    AlertActions.displaySuccess({
                        key: 'donation-campaign-update',
                    }),
                    DonationCampaignActions.loadDonationCampaignRequestById({
                        id: campaign.id,
                    }),
                ];
            })
        )
    );

    contactMe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.contactMeRequest),
            concatLatestFrom(() =>
                this.store.select(DonationCampaignSelectors.selectEmail)
            ),
            exhaustMap(([, email]) => {
                return this.donationCampaignAnonymousService
                    .donationCampaignAnonContactMePost({ body: `"${email}"` })
                    .pipe(
                        map(() =>
                            DonationCampaignActions.contactMeRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.contactMeRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    shareOnLinkedIn$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DonationCampaignActions.shareCertificateOnLinkedIn),
                concatLatestFrom(() =>
                    this.store.select(
                        DonationCampaignSelectors.selectDonationRequestId
                    )
                ),
                tap(([, donationRequestId]) => {
                    this.authService.trylinkedInLogin({
                        operationType: 'share-certificate',
                        extraQueryParams:
                            'donationRequestId=' + donationRequestId.toString(),
                    });
                })
            ),
        { dispatch: false }
    );

    loadDonationRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.loadDonationRequestRequest),
            concatLatestFrom(() =>
                this.store.select(
                    DonationCampaignSelectors.selectDonationRequestId
                )
            ),
            filter(([{ id }, donationRequestId]) => id !== donationRequestId),
            exhaustMap(([{ id }]) => {
                return this.donationCampaignAnonymousService
                    .donationCampaignAnonRequestIdGet({ id })
                    .pipe(
                        map((donationRequest) =>
                            DonationCampaignActions.loadDonationRequestRequestSuccess(
                                { donationRequest }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.loadDonationRequestRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    shareCertificateOnLinkedInApi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignActions.shareCertificateOnLinkedInRequest),
            concatLatestFrom(() =>
                this.store.select(
                    DonationCampaignSelectors.selectDonationRequestId
                )
            ),
            exhaustMap(([{ linkedInImageShare }, donationRequestId]) => {
                const linkedInImageShareCopy = { ...linkedInImageShare };
                linkedInImageShareCopy.queryExtraParams =
                    '?donationRequestId=' + donationRequestId.toString();
                return this.shareService
                    .shareLinkedinImagePost({
                        linkedInImageShare: linkedInImageShareCopy,
                    })
                    .pipe(
                        map(() =>
                            DonationCampaignActions.shareCertificateOnLinkedInRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignActions.shareCertificateOnLinkedInRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    redirect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DonationCampaignActions.loadDonationCampaignRequestFailure,
                DonationCampaignActions.loadDonationRequestRequestFailure
            ),
            map(() => NavigationActions.notFound())
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private chooseProjectDialogService: ChooseProjectDialogService,
        private createDonationCampaignDialogService: CreateDonationCampaignDialogService,
        private donationCampaignAnonymousService: DonationCampaignAnonymousService,
        private donationCampaignFormService: DonationCampaignFormService,
        private donationCampaignService: DonationCampaignService,
        private donationCampaignShareSettingsDialogService: DonationCampaignShareSettingsDialogService,
        private router: Router,
        private shareService: ShareService,
        private store: Store
    ) {}
}
