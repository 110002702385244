import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    ECurrency,
    EUnit,
    LocationViewModel,
    ProjectMetricViewModel,
    ProjectViewModel,
} from 'app/api';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class ProjectFormService implements IFormService<ProjectViewModel> {
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [null],
            name: ['', [Validators.required, Validators.maxLength(100)]],
            basilikComment: ['', Validators.maxLength(350)],
            baseContributionAmount: [
                '',
                [Validators.required, Validators.min(1)],
            ],
            impactUnitQuantity: [
                '',
                [Validators.required, Validators.min(0.1)],
            ],
            impactUnitDescription: ['', Validators.maxLength(100)],
            currency: [ECurrency.Euro, Validators.required],
            isActive: [true],
            challenge: [
                '',
                [
                    Validators.required,
                    Validators.minLength(100),
                    Validators.maxLength(1000),
                ],
            ],
            solution: [
                '',
                [
                    Validators.required,
                    Validators.minLength(100),
                    Validators.maxLength(1000),
                ],
            ],
            solutionDetails: [
                '',
                CustomValidators.htmlTextLengthValidator(0, 3000),
            ],
            purposeOfDonations: [
                '',
                [
                    Validators.required,
                    CustomValidators.htmlTextLengthValidator(100, 1500),
                ],
            ],
            quote: ['', Validators.maxLength(300)],
            quoteAuthor: ['', Validators.maxLength(50)],
            quoteAuthorImageUrl: ['', Validators.maxLength(200)],
            quoteAuthorTitle: ['', Validators.maxLength(50)],
            videoUrl: ['', CustomValidators.youtubeVideoIdValidator],
            metrics: this.fb.array(
                [this.createMetric(), this.createMetric(), this.createMetric()],
                Validators.required
            ),
            locations: this.fb.array(
                [],
                [
                    CustomValidators.minArrayLengthValidator(1),
                    CustomValidators.maxArrayLengthValidator(10),
                ]
            ),
            sustainableDevelopmentGoals: [[], Validators.required],
            activityFields: [
                [],
                [
                    Validators.required,
                    CustomValidators.maxArrayLengthValidator(3),
                ],
            ],
        });

        return this.formGroup;
    }

    resetMetricsFormArray() {
        this.formGroup.controls.metrics = this.fb.array([]);
    }

    resetLocationsFormArray() {
        this.formGroup.controls.locations = this.fb.array([]);
    }

    createMetric(metric?: ProjectMetricViewModel): FormGroup {
        return this.fb.group({
            id: [metric?.id ?? 0],
            number: [metric?.number ?? null],
            isMoreThan: [metric?.isMoreThan ?? false],
            isRange: [metric?.isRange ?? false],
            fromNumber: [metric?.fromNumber ?? null],
            toNumber: [metric?.toNumber ?? null],
            description: [
                metric?.description ?? '',
                [Validators.required, Validators.maxLength(150)],
            ],
            unit: [metric?.unit ?? EUnit.None],
        });
    }

    createLocation(location?: LocationViewModel): FormGroup {
        return this.fb.group({
            id: [location?.id ?? 0],
            longitude: [location?.longitude ?? null],
            latitude: [location?.latitude ?? null],
            name: [location?.name ?? ''],
        });
    }

    updateForm(entity: ProjectViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        this.resetMetricsFormArray();
        this.resetLocationsFormArray();

        if (entity.metrics) {
            const metricsControl = this.formGroup.controls.metrics as FormArray;
            entity.metrics.forEach((metric) => {
                metricsControl.push(this.createMetric(metric));
            });
        }

        if (entity.locations) {
            const locationsControl = this.formGroup.controls
                .locations as FormArray;
            entity.locations.forEach((location) => {
                locationsControl.push(this.createLocation(location));
            });
        }
    }

    getEntity(): { [key: string]: ProjectViewModel } {
        const projectViewModel = returnOnlyNonNullFields<ProjectViewModel>(
            this.formGroup.value
        ) as ProjectViewModel;
        return { projectViewModel };
    }
}
