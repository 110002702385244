import { NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
    CompanyCsrApproachViewModel,
    CompanyMatrixPointViewModel,
} from 'app/api/model/models';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject } from 'rxjs';
import { CompanyCsrApproachCardComponent } from '../card/card.component';
import { CompanyCsrApproachEditMatrixPointComponent } from './edit/edit-matrix-point/edit-matrix-point.component';
import { EditMatrixComponent } from './edit/edit-matrix/edit-matrix.component';
import { CompanyCsrApproachViewMatrixPointComponent } from './edit/view-matrix-point/view-matrix-point.component';

@Component({
    selector: 'company-csr-approach-matrix',
    templateUrl: './matrix.component.html',
    standalone: true,
    imports: [
        CompanyCsrApproachCardComponent,
        CompanyCsrApproachEditMatrixPointComponent,
        CompanyCsrApproachViewMatrixPointComponent,
        TranslocoModule,
        EditMatrixComponent,
        FuseAlertComponent,
        NgxSkeletonLoaderModule,
        NgIf,
        NgFor,
        NgClass,
    ],
})
export class CompanyCsrApproachMatrixComponent
    implements OnInit, OnChanges, OnDestroy
{
    @ViewChild('matrixContainer', { static: false })
    matrixContainer!: ElementRef;

    @ViewChild('matrixCanvas', { static: false })
    matrixCanvas!: ElementRef<HTMLCanvasElement>;

    @Input() isLoading: boolean;
    @Input() isLoadingUpdate: boolean;
    @Input() isCompanyAdmin = false;
    @Input() csrApproach: CompanyCsrApproachViewModel;

    unsubscribeAll: Subject<any> = new Subject<any>();

    private ctx!: CanvasRenderingContext2D;
    startX = 70;
    startY = 350;
    endX: number;
    endY = 50;
    graphWidth = 800;

    isCreating = false;
    isEditing = false;
    isDragging = false;
    editedPointIndex: number;
    viewedPoint?: CompanyMatrixPointViewModel = null;
    draggedPointIndex: number | null = null;
    highlightedLegendId: number | null = null;

    constructor(
        private store: Store,
        private translocoService: TranslocoService,
        private formService: CompanyCsrApproachFormService,
        private cdRef: ChangeDetectorRef
    ) {}

    get formGroup() {
        return this.formService.getFormGroup.controls.matrix as FormGroup;
    }

    get pointsFormArray() {
        const matrixFormGroup = this.formService.getFormGroup.controls
            .matrix as FormGroup;
        return matrixFormGroup.controls.points as FormArray;
    }

    get points() {
        return this.pointsFormArray.value;
    }

    get isValidateDisabled() {
        return this.formGroup.invalid;
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.csrApproach &&
            changes.csrApproach?.currentValue?.matrix?.points
        ) {
            setTimeout(() => {
                this.renderPoints();
            });
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.drawMatrix();
        });
    }

    renderPoints() {
        this.drawMatrix();
        this.points.forEach((point: CompanyMatrixPointViewModel) => {
            if (this.highlightedLegendId === null) {
                this.drawPoint(point);
            } else if (point.legendId === this.highlightedLegendId) {
                this.drawPoint(point);
            }
        });
    }

    drawMatrix() {
        const matrixContainerElement = this.matrixContainer.nativeElement;
        this.graphWidth = matrixContainerElement.clientWidth;
        this.endX = this.graphWidth - this.startX;
        this.ctx = this.matrixCanvas.nativeElement.getContext('2d')!;
        this.cdRef.detectChanges();

        const canvas = this.matrixCanvas?.nativeElement;
        if (!canvas || !this.ctx) {
            return;
        }

        const ctx = this.ctx;
        const noTitle = this.translocoService.translate(
            'shared.common.no-title'
        );

        ctx.resetTransform();
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const steps = 5;

        //  Axis
        ctx.beginPath();
        ctx.moveTo(this.startX, this.startY);
        ctx.lineTo(this.endX, this.startY); // Axe X
        ctx.moveTo(this.startX, this.startY);
        ctx.lineTo(this.startX, this.endY); // Axe Y
        ctx.strokeStyle = '#4A5568'; // Gris foncé
        ctx.stroke();

        // X Axis
        ctx.font = 'bold 14px Poppins';
        ctx.fillStyle = '#4A5568';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(
            this.csrApproach?.matrix?.xAxisName ?? noTitle,
            (this.startX + this.endX) / 2,
            this.startY + 40
        );

        // Y Axis
        ctx.save();
        ctx.translate(this.startX - 50, (this.startY + this.endY) / 2);
        ctx.rotate(-Math.PI / 2);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.csrApproach?.matrix?.yAxisName ?? noTitle, 0, 0);
        ctx.restore();

        const xStep = (this.endX - this.startX) / steps;
        const yStep = (this.startY - this.endY) / steps;

        for (let i = 1; i <= steps; i++) {
            ctx.globalAlpha = 0.5;
            const x = this.startX + i * xStep;
            ctx.fillText(i.toString(), x, this.startY + 20);
            ctx.globalAlpha = 0.3;

            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            ctx.moveTo(x, this.startY);
            ctx.lineTo(x, this.endY);
            ctx.stroke();

            const y = this.startY - i * yStep;
            ctx.globalAlpha = 0.5;

            ctx.fillText(i.toString(), this.startX - 20, y + 5);
            ctx.globalAlpha = 0.3;

            ctx.beginPath();
            ctx.moveTo(this.startX, y);
            ctx.lineTo(this.endX, y);
            ctx.stroke();
        }

        ctx.setLineDash([]);
        ctx.globalAlpha = 1;
    }

    drawPoint(point: CompanyMatrixPointViewModel) {
        const noTitle = this.translocoService.translate(
            'shared.common.no-title'
        );

        const ctx = this.ctx;

        if (!ctx) {
            return;
        }

        // Calcul des coordonnées absolues du point
        const absoluteX =
            this.startX + (point.xAxis / 100) * (this.endX - this.startX);
        const absoluteY =
            this.startY - (point.yAxis / 100) * (this.startY - this.endY);

        const baseRadius = 15;
        const scaleFactor = 3;
        const radius =
            baseRadius +
            Math.sqrt(1 + (point.microActionIds?.length ?? 0)) * scaleFactor;

        ctx.fillStyle = '#4A5568';
        ctx.font = 'bold 12px Poppins';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(point.name ?? noTitle, absoluteX, absoluteY - radius - 10);

        ctx.beginPath();
        ctx.arc(absoluteX, absoluteY, radius, 0, 2 * Math.PI);

        const legend = this.csrApproach?.matrix?.legends?.find(
            (l) => l.id === point.legendId
        );
        ctx.fillStyle = legend?.color ?? '#F59E0B';
        ctx.fill();
        ctx.stroke();

        if (point.microActionIds?.length > 0) {
            ctx.fillStyle = '#FFF';
            ctx.font = '12px Poppins';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(
                point.microActionIds.length.toString(),
                absoluteX,
                absoluteY
            );
        }
    }

    isMouseOnPoint(x: number, y: number): number {
        return this.points.findIndex((point: CompanyMatrixPointViewModel) => {
            const pointX =
                this.startX + (point.xAxis / 100) * (this.endX - this.startX);
            const pointY =
                this.startY - (point.yAxis / 100) * (this.startY - this.endY);
            const radius =
                15 + Math.sqrt(1 + (point.microActionIds?.length ?? 0)) * 3;

            const distance = Math.sqrt((x - pointX) ** 2 + (y - pointY) ** 2);
            return distance <= radius;
        });
    }

    onCanvasClick(event: MouseEvent) {
        if (this.isDragging) {
            return;
        }

        const rect = this.matrixCanvas.nativeElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Vérifier si le point est dans les limites de la matrice
        if (
            x < this.startX ||
            x > this.endX ||
            y < this.endY ||
            y > this.startY
        ) {
            return; // Ne rien faire si le point est en dehors de la matrice
        }

        // Calcul des coordonnées en pourcentage par rapport à la taille du canevas
        const relativeX = ((x - this.startX) / (this.endX - this.startX)) * 100;
        const relativeY = ((this.startY - y) / (this.startY - this.endY)) * 100;

        // Vérifier si un point existant est cliqué
        const clickedPointIndex = this.isMouseOnPoint(x, y);

        if (this.isEditing) {
            // Si nous sommes en mode édition et qu'un point est cliqué
            if (clickedPointIndex !== -1) {
                this.editedPointIndex = clickedPointIndex;
                this.isCreating = false;
            } else {
                // Sinon, créer un nouveau point
                const newPoint: CompanyMatrixPointViewModel = {
                    xAxis: relativeX,
                    yAxis: relativeY,
                };
                this.pointsFormArray.push(
                    this.formService.createMatrixPoint(newPoint)
                );
                this.renderPoints();
                this.isCreating = true;
            }
        } else {
            if (clickedPointIndex !== -1) {
                this.viewedPoint =
                    this.csrApproach.matrix.points[clickedPointIndex];
            }
        }
    }

    onEdit(isEditing: boolean) {
        this.isEditing = isEditing;
        if (!isEditing) {
            this.store.dispatch(
                CompanyCsrApproachActions.updateCsrApproachRequest()
            );
        }
    }

    onHiding(isHiding: boolean) {
        this.formService.getFormGroup.controls.hideMatrix.setValue(isHiding);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    editDrawerClosed() {
        this.isCreating = false;
        this.editedPointIndex = null;
        this.renderPoints();
    }

    viewDrawerClosed() {
        this.viewedPoint = null;
    }

    onCanvasMouseDown(event: MouseEvent) {
        if (!this.isEditing) {
            return; // Ne pas activer le déplacement si on n'est pas en mode édition
        }

        const rect = this.matrixCanvas.nativeElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Vérifie si un point est cliqué
        const pointIndex = this.isMouseOnPoint(x, y);

        if (pointIndex !== -1) {
            // Activer le déplacement pour le point cliqué
            this.draggedPointIndex = pointIndex;
            this.isDragging = true;
        }
    }

    onCanvasMouseMove(event: MouseEvent) {
        const rect = this.matrixCanvas.nativeElement.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (this.isDragging && this.draggedPointIndex !== null) {
            // Mettre à jour la position du point en cours de déplacement
            const relativeX =
                ((x - this.startX) / (this.endX - this.startX)) * 100;
            const relativeY =
                ((this.startY - y) / (this.startY - this.endY)) * 100;

            // Restreindre les valeurs entre 0 et 100
            const clampedX = Math.max(0, Math.min(100, relativeX));
            const clampedY = Math.max(0, Math.min(100, relativeY));

            this.pointsFormArray.controls[this.draggedPointIndex].patchValue({
                xAxis: clampedX,
                yAxis: clampedY,
            });
            this.renderPoints();
        } else {
            // Vérifie si la souris est sur un point pour changer le curseur
            const pointIndex = this.isMouseOnPoint(x, y);
            this.matrixCanvas.nativeElement.style.cursor =
                pointIndex !== -1 ? 'pointer' : 'default';
        }
    }

    onCanvasMouseUp(event: MouseEvent) {
        if (this.draggedPointIndex !== null) {
            this.draggedPointIndex = null;
            this.isDragging = false;
        }
    }

    highlightLegend(legendId: number) {
        if (this.highlightedLegendId === legendId) {
            this.highlightedLegendId = null;
        } else {
            this.highlightedLegendId = legendId;
        }
        this.renderPoints();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
