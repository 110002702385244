import { DatePipe } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { AdvancedChartFilters } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { Store } from '@ngrx/store';
import { AssociationDashboardActions } from 'app/store/association/association-dashboard/association-dashboard.actions';
import { AssociationDashboardSelectors } from 'app/store/association/association-dashboard/association-dashboard.selectors';
import { AssociationKpi } from 'app/store/association/association-dashboard/association-dashboard.state';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { Subject, tap } from 'rxjs';

export abstract class AssociationDashboardChartComponent {
    chartOptions: Partial<ApexOptions>;
    labels: string[];
    series: ApexAxisChartSeries | number[];
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store
        .select(AssociationDashboardSelectors.selectIsLoading(this.kpiName))
        .pipe(
            tap(() => {
                this.cdRef.detectChanges();
            })
        );

    isExpanded$ = this.store.select(
        AssociationDashboardSelectors.selectIsKpiExpanded(this.kpiName)
    );

    dateRange$ = this.store.select(
        AssociationDashboardSelectors.selectKpiResultDates(this.kpiName)
    );

    constructor(
        protected store: Store,
        protected datePipe: DatePipe,
        protected cdRef: ChangeDetectorRef,
        protected kpiName: AssociationKpi
    ) {}

    onExpand(isExpanded: boolean) {
        if (isExpanded) {
            this.store.dispatch(
                AssociationDashboardActions.expandKpi({ kpi: this.kpiName })
            );
        } else {
            this.store.dispatch(AssociationDashboardActions.minimizeKpi());
        }
    }

    onFilterChange(filters: AdvancedChartFilters) {
        this.store.dispatch(
            AssociationDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    timePeriod: filters.timePeriod,
                    periodOffset: filters.periodOffset,
                },
            })
        );
    }
}
