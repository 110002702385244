<bslk-advanced-chart
    (expands)="onExpand($event)"
    (filterChanges)="onFilterChange($event)"
    class="w-full"
    [title]="'companyadmindashboard.kpis.donation-campaigns-impact' | transloco"
    [isLoading]="isLoading$ | async"
    [series]="series"
    [labels]="labels"
    [options]="chartOptions"
    [gradientColorFrom]="'quaternary'"
    [gradientColorTo]="'tertiary'"
    [isTimePeriodSelectionEnabled]="true"
    [isExpandable]="true"
    [isExpanded]="isExpanded$ | async"
    [dateRange]="dateRange$ | async"
>
    <div headerActions>
        <bslk-business-sdg-picker
            (valueChange)="onSdgChange($event)"
            [value]="selectedSdg"
            [isSingularValue]="true"
            [asOverlay]="true"
        ></bslk-business-sdg-picker>
    </div>

    <div
        class="justify-between bg-white bg-opacity-40 flex flex-col p-3 xxl:p-4 h-min rounded-xl gap-2 xxl:gap-4 my-auto"
    >
        <div class="flex flex-col gap-1 xxl:gap-2">
            <span class="font-bold text-lg truncate">{{
                "companyadmindashboard.total-gifts" | transloco
            }}</span>
            <span class="font-extrabold text-3xl xxl:text-4xl">{{
                totalGifts
            }}</span>
        </div>
        <div class="border-t-2 border-tertiary-300"></div>
        <div class="flex flex-col gap-1 xxl:gap-2">
            <span class="font-bold text-lg truncate">{{
                "companyadmindashboard.total-contribution-amount" | transloco
            }}</span>
            <span class="font-extrabold text-3xl xxl:text-4xl">{{
                totalContributionAmount
            }}</span>
        </div>
    </div>
</bslk-advanced-chart>
