<div class="p-4 md:p-6 xxl:p-8 w-full flex flex-col gap-3">
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "findvolunteers.title" | transloco
            }}</span>
            <span class="text-lg">{{
                "findvolunteers.subtitle" | transloco
            }}</span>
        </div>
    </div>

    <div class="bg-white p-6 rounded-xl shadow-lg">
        <single-select-async
            class="grow"
            [label]="'findvolunteers.select-mission' | transloco"
            [selectPlaceholder]="'findvolunteers.select-mission' | transloco"
            [searchPlaceholder]="'findvolunteers.search-mission' | transloco"
            [control]="formControl"
            [actions]="MissionListActions"
            [selectors]="MissionListSelectors"
            [filters]="filters"
            [valueIsId]="true"
            [noDataText]="'findvolunteers.no-missions' | transloco"
        >
        </single-select-async>
    </div>

    <div *ngIf="formControl.value" class="flex justify-center mt-8">
        <potential-match
            *ngIf="!(isLoadingMatches$ | async); else loadingPotentialMatches"
        ></potential-match>
    </div>
</div>

<ng-template #loadingPotentialMatches>
    <div class="flex min-w-160 rounded-3xl h-96">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #loadingMissions>
    <div class="w-full h-40">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
