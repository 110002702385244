<ng-container *ngrxLet="hasMorePotentialMatch$ as hasMorePotentialMatch">
    <ng-container *ngrxLet="viewedMatch$ as viewedMatch">
        <div
            *ngIf="hasMorePotentialMatch; else noMoreMatch"
            class="bg-gradient-to-r from-primary-300 to-warn-300 max-w-180 shadow-lg p-6 rounded-3xl flex flex-col gap-6"
        >
            <div class="flex gap-8 justify-center items-center">
                <basile-avatar
                    class="w-15 h-15 lg:h-20 lg:w-20 xl:w-30 xl:h-30 flex flex-shrink-0"
                    [configuration]="viewedMatch.basileConfiguration"
                ></basile-avatar>
                <div class="flex flex-col gap-1">
                    <span class="font-bold text-2xl">
                        {{ viewedMatch.user.name }}
                    </span>
                    <div class="flex gap-1">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:briefcase'"
                        >
                        </mat-icon>
                        <span
                            ><span *ngIf="viewedMatch.user.jobTitle"
                                >{{ viewedMatch.user.jobTitle }}
                                {{ "shared.common.at" | transloco }}</span
                            >
                            {{ viewedMatch.user.company.name }}</span
                        >
                    </div>
                    <div
                        *ngIf="
                            viewedMatch.user.location ||
                            viewedMatch.user.company.location
                        "
                        class="flex flex-col"
                    >
                        <div class="flex gap-1">
                            <mat-icon
                                class="icon-size-5 text-current"
                                [svgIcon]="'heroicons_solid:map-pin'"
                            >
                            </mat-icon>
                            <span class="truncate"
                                >{{
                                    viewedMatch.user.location.zipCode ??
                                        viewedMatch.user.company.location
                                            .zipCode
                                }}
                                {{
                                    viewedMatch.user.location.city ??
                                        viewedMatch.user.company.location.city
                                }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="flex justify-center items-center gap-2 bg-warn rounded-xl p-2 text-white"
                *ngIf="viewedMatch.user.hasParticipatedInAnyMission"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:check-badge'"
                ></mat-icon>
                <span class="font-bold">{{
                    "findvolunteers.participated-in-a-mission" | transloco
                }}</span>
            </div>

            <div
                class="border-t border-white p-3 flex flex-col gap-2"
                *ngIf="viewedMatch.user.skills?.length > 0"
            >
                <span class="font-bold">{{
                    "findvolunteers.skills" | transloco
                }}</span>
                <enum-tag-list
                    *ngIf="viewedMatch.user.skills"
                    [tags]="viewedMatch.user.skills"
                    [enum]="ESkill"
                    [maxTagsVisible]="10"
                    [wrap]="true"
                ></enum-tag-list>
            </div>

            <div class="border-t border-white p-6 flex justify-around">
                <button
                    (click)="contactMatch(viewedMatch)"
                    class="bg-quaternary shadow rounded-full p-2 items-center flex mr-3 transition-all duration-300 hover:bg-quaternary-700"
                >
                    <mat-icon
                        class="icon-size-12 text-white"
                        [svgIcon]="'heroicons_solid:check'"
                    ></mat-icon>
                </button>
                <button
                    (click)="nextMatch(viewedMatch)"
                    class="bg-primary shadow rounded-full p-2 items-center flex transition-all duration-300 hover:bg-primary-700"
                >
                    <mat-icon
                        class="icon-size-12 text-white"
                        [svgIcon]="'heroicons_solid:x-mark'"
                    ></mat-icon>
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #noMoreMatch>
    <div class="h-full flex flex-col justify-center items-center gap-8">
        <mat-icon
            [svgIcon]="'heroicons_outline:users'"
            class="icon-size-14 text-war"
        ></mat-icon>
        <div class="flex flex-col gap-2 justify-center items-center">
            <div class="text-center font-bold text-xl">
                {{ "findvolunteers.no-more-match-title" | transloco }}
            </div>

            <div class="text-hint text-lg">
                {{ "findvolunteers.no-more-match-description" | transloco }}
            </div>
        </div>
    </div>
</ng-template>
