import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MicroActionViewModel } from 'app/api';
import { CreateMicroActionDialogInput } from 'app/modules/regular/micro-action/create-micro-action/create-micro-action.component';

export const MicroActionActions = createActionGroup({
    source: 'MicroAction',
    events: {
        'Open Dialog': props<{ input: CreateMicroActionDialogInput }>(),
        'Close Dialog': emptyProps(),

        'Set Cover Request': props<{ file: File }>(),
        'Set Cover Success': emptyProps(),

        'Update Cover Request': props<{ file: File }>(),
        'Update Cover Request Success': props<{ url: string }>(),
        'Update Cover Request Fail': props<{ error: string }>(),

        'Edit Micro Action': emptyProps(),
        'Update Micro Action Request': emptyProps(),
        'Update Micro Action Request Success': emptyProps(),
        'Update Micro Action Request Fail': props<{ error: string }>(),

        'Try Register Micro Action Request': props<{ id: number }>(),
        'Register Micro Action Request': props<{ id: number }>(),
        'Register Micro Action Request Success': emptyProps(),
        'Register Micro Action Request Fail': props<{ error: string }>(),

        'Unregister Micro Action Request': props<{ id: number }>(),
        'Unregister Micro Action Request Success': emptyProps(),
        'Unregister Micro Action Request Fail': props<{ error: string }>(),

        'Create Micro Action Request': emptyProps(),
        'Create Micro Action Request Success': props<{ id: number }>(),
        'Create Micro Action Request Fail': props<{ error: string }>(),

        'Load Micro Action Request': props<{ id: number }>(),
        'Load Micro Action Request Success': props<{
            microAction: MicroActionViewModel;
        }>(),
        'Load Micro Action Request Fail': props<{ error: string }>(),

        'Archive Micro Action Request': props<{ id: number }>(),
        'Archive Micro Action Request Success': emptyProps(),
        'Archive Micro Action Request Fail': props<{ error: string }>(),

        'Mark Activity As Completed Request': props<{ id: number }>(),
        'Mark Activity As Completed Request Success': emptyProps(),
        'Mark Activity As Completed Request Fail': props<{ error: string }>(),

        'Unmark Activity As Completed Request': props<{ id: number }>(),
        'Unmark Activity As Completed Request Success': emptyProps(),
        'Unmark Activity As Completed Request Fail': props<{ error: string }>(),
    },
});
