import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const selectState = (state: AppState) => state.setup;

const selectCompany = createSelector(selectState, (state) => state.company);

const selectCustomization = createSelector(
    selectState,
    (state) => state.customization
);

const selectIsLoadingCompany = createSelector(
    selectState,
    (state) => state.isLoadingCompany
);

const selectIsLoadingAdministrativeContactUpdate = createSelector(
    selectState,
    (state) => state.isLoadingAdministrativeContactUpdate
);

const selectIsLoadingCustomizationUpdate = createSelector(
    selectState,
    (state) => state.isLoadingCustomizationUpdate
);

const selectIsLoadingCustomization = createSelector(
    selectState,
    (state) => state.isLoadingCustomization
);

export const SetupSelectors = {
    selectCompany,
    selectCustomization,
    selectIsLoadingCustomization,
    selectIsLoadingAdministrativeContactUpdate,
    selectIsLoadingCompany,
    selectIsLoadingCustomizationUpdate,
};
