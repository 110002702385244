<div
    [formGroup]="formGroup"
    class="flex flex-col justify-between gap-3 border shadow rounded-2xl h-full py-3 px-6"
>
    <div class="flex flex-col gap-1">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always">
            <mat-label>{{
                "csrapproach.matrix.title-name" | transloco
            }}</mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:pencil'"
                matPrefix
            ></mat-icon>
            <input
                [placeholder]="
                    'csrapproach.matrix.title-name-placeholder' | transloco
                "
                [formControlName]="'name'"
                matInput
            />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.name.value"
            [maxLength]="100"
        ></bslk-length-info>
    </div>
    <div class="flex flex-col gap-1">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always">
            <mat-label>{{
                "csrapproach.matrix.xaxisname" | transloco
            }}</mat-label>
            <div class="italic text-xl font-semibold mr-5" matPrefix>x</div>
            <input
                [placeholder]="
                    'csrapproach.matrix.xaxisname-placeholder' | transloco
                "
                [formControlName]="'xAxisName'"
                matInput
            />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.xAxisName.value"
            [maxLength]="50"
        ></bslk-length-info>
    </div>
    <div class="flex flex-col gap-1">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always">
            <mat-label>{{
                "csrapproach.matrix.yaxisname" | transloco
            }}</mat-label>
            <div class="italic text-xl font-semibold mr-5" matPrefix>y</div>
            <input
                [placeholder]="
                    'csrapproach.matrix.yaxisname-placeholder' | transloco
                "
                [formControlName]="'yAxisName'"
                matInput
            />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.yAxisName.value"
            [maxLength]="50"
        ></bslk-length-info>
    </div>
    <div class="flex justify-end">
        <bslk-tag-button
            (onClick)="openEditLegendsDialog()"
            [icon]="'heroicons_outline:list-bullet'"
        >
            {{
                "csrapproach.matrix.manage-legends" | transloco
            }}</bslk-tag-button
        >
    </div>
</div>
