/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DecimalPeriodChart } from '../model/decimalPeriodChart';
// @ts-ignore
import { EChartTimePeriod } from '../model/eChartTimePeriod';
// @ts-ignore
import { MissionSlotPendingViewModelPaginatedListDto } from '../model/missionSlotPendingViewModelPaginatedListDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface SuperAdminDashboardActiveUsersGetRequestParams {
    companyId?: number;
    associationId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface SuperAdminDashboardPendingMissionSlotsGetRequestParams {
    companyId?: number;
    associationId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}


@Injectable({
  providedIn: 'root'
})
export class SuperAdminDashboardService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * [SuperAdmin] Get active users over time
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public superAdminDashboardActiveUsersGet(requestParameters: SuperAdminDashboardActiveUsersGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DecimalPeriodChart>;
    public superAdminDashboardActiveUsersGet(requestParameters: SuperAdminDashboardActiveUsersGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DecimalPeriodChart>>;
    public superAdminDashboardActiveUsersGet(requestParameters: SuperAdminDashboardActiveUsersGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DecimalPeriodChart>>;
    public superAdminDashboardActiveUsersGet(requestParameters: SuperAdminDashboardActiveUsersGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/super-admin-dashboard/active-users`;
        return this.httpClient.request<DecimalPeriodChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [SuperAdmin] Get pending mission slots
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public superAdminDashboardPendingMissionSlotsGet(requestParameters: SuperAdminDashboardPendingMissionSlotsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MissionSlotPendingViewModelPaginatedListDto>;
    public superAdminDashboardPendingMissionSlotsGet(requestParameters: SuperAdminDashboardPendingMissionSlotsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MissionSlotPendingViewModelPaginatedListDto>>;
    public superAdminDashboardPendingMissionSlotsGet(requestParameters: SuperAdminDashboardPendingMissionSlotsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MissionSlotPendingViewModelPaginatedListDto>>;
    public superAdminDashboardPendingMissionSlotsGet(requestParameters: SuperAdminDashboardPendingMissionSlotsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/super-admin-dashboard/pending-mission-slots`;
        return this.httpClient.request<MissionSlotPendingViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
