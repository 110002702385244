import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnackAlertService } from 'app/layout/common/snack-alerts/snack-alert.service';
import { tap } from 'rxjs/operators';
import { AlertActions } from './alert.actions';

@Injectable()
export class AlertEffects {
    displayGenericError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertActions.displayGenericError),
                tap(() => {
                    this.snackAlertService.addSnackAlert({
                        title: 'error.generic.title',
                        message: 'error.generic.message',
                        type: 'error',
                    });
                })
            ),
        { dispatch: false }
    );

    displaySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertActions.displaySuccess),
                tap(({ key, variables, duration }) => {
                    this.snackAlertService.addSnackAlert({
                        title: `success.${key}.title`,
                        message: `success.${key}.message`,
                        type: 'success',
                        variables,
                        duration,
                    });
                })
            ),
        { dispatch: false }
    );

    displayError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertActions.displayError),
                tap(({ key, variables, duration }) => {
                    this.snackAlertService.addSnackAlert({
                        title: `error.${key}.title`,
                        message: `error.${key}.message`,
                        type: 'error',
                        variables,
                        duration,
                    });
                })
            ),
        { dispatch: false }
    );

    displayInfo$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AlertActions.displayInfo),
                tap(({ key, action, link, duration }) => {
                    this.snackAlertService.addSnackAlert({
                        title: `info.${key}.title`,
                        message: `info.${key}.message`,
                        type: 'info',
                        action,
                        link,
                        duration,
                    });
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private snackAlertService: SnackAlertService
    ) {}
}
