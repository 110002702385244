<div
    [class.bg-white]="!gradientColorFrom"
    [class.bg-gradient-to-b]="gradientColorTo && gradientColorFrom"
    [class.to-white]="gradientColorTo === 'white'"
    [class.to-primary-300]="gradientColorTo === 'primary'"
    [class.to-warn-300]="gradientColorTo === 'warn'"
    [class.to-tertiary-300]="gradientColorTo === 'tertiary'"
    [class.to-quaternary-300]="gradientColorTo === 'quaternary'"
    [class.from-quaternary-300]="gradientColorFrom === 'quaternary'"
    [class.from-warn-300]="gradientColorFrom === 'warn'"
    [class.from-primary-300]="gradientColorFrom === 'primary'"
    class="rounded-xl shadow-lg p-3 xl:p-4 xxl:p-6 w-full h-full flex flex-col"
>
    <div class="flex mb-2 justify-between">
        <div class="flex items-center truncate">
            <div class="text-bold text-xl truncate font-bold mr-4">
                {{ title }}
            </div>
            <div
                *ngIf="
                    isExpanded &&
                    isTimePeriodSelectionEnabled &&
                    formattedDateRange
                "
                class="flex gap-x-2 items-center text-hint font-semibold"
            >
                <mat-icon
                    (click)="onPeriodOffsetChange(1)"
                    class="cursor-pointer hover:text-warn duration-200 hover:scale-110"
                    [svgIcon]="'heroicons_outline:chevron-left'"
                ></mat-icon>
                {{ formattedDateRange }}

                <mat-icon
                    *ngIf="filters.periodOffset > 0"
                    (click)="onPeriodOffsetChange(-1)"
                    class="cursor-pointer hover:text-warn duration-200 hover:scale-110"
                    [svgIcon]="'heroicons_outline:chevron-right'"
                ></mat-icon>
            </div>
        </div>
        <div class="flex gap-x-4 items-center">
            <ng-content select="[headerActions]"></ng-content>
            <bslk-classic-toggle
                *ngIf="isExpanded && isTimePeriodSelectionEnabled"
                (valueChange)="onTimePeriodChange($event)"
                [value]="filters.timePeriod"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    {
                        text: 'companyadmindashboard.month' | transloco,
                        value: EChartTimePeriod.Month
                    },
                    {
                        text: 'companyadmindashboard.year' | transloco,
                        value: EChartTimePeriod.Year
                    }
                ]"
            ></bslk-classic-toggle>

            <bslk-menu
                *ngIf="isDownloadEnabled && hasData && isExpanded"
                (onAction)="download($event)"
                [icon]="'heroicons_outline:arrow-down-tray'"
                [items]="[
                    {
                        label:
                            'companyadmindashboard.download-as-csv' | transloco,
                        action: 'csv'
                    },
                    {
                        label:
                            'companyadmindashboard.download-as-svg' | transloco,
                        action: 'svg'
                    },
                    {
                        label:
                            'companyadmindashboard.download-as-png' | transloco,
                        action: 'png'
                    }
                ]"
            ></bslk-menu>
            <mat-icon
                *ngIf="isExpanded && infoTooltip"
                [svgIcon]="'heroicons_solid:information-circle'"
                [matTooltip]="infoTooltip"
                class="cursor-pointer text-tertiary hover:text-tertiary duration-200"
            ></mat-icon>
            <mat-icon
                *ngIf="isExpandable && hasData"
                (click)="toggleExpand()"
                [matTooltip]="
                    'bslk.charts.see-' + (isExpanded ? 'less' : 'more')
                        | transloco
                "
                [svgIcon]="
                    'heroicons_outline:' +
                    (isExpanded
                        ? 'magnifying-glass-minus'
                        : 'magnifying-glass-plus')
                "
                class="cursor-pointer hover:text-warn duration-200 hover:scale-110"
            ></mat-icon>
        </div>
    </div>

    <div
        *ngIf="!isLoading && hasData"
        class="flex grow relative overflow-hidden"
    >
        <div class="flex grow">
            <ng-content></ng-content>
            <bslk-chart
                #bslkChart
                class="grow"
                [series]="series"
                [labels]="labels"
                [options]="chartOptions"
            ></bslk-chart>
        </div>
    </div>

    <div class="h-full" *ngIf="isLoading">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>

    <div
        class="flex flex-col justify-center m-auto"
        *ngIf="!hasData && !isLoading"
    >
        <mat-icon
            [svgIcon]="'heroicons_outline:chart-bar-square'"
            class="icon-size-16 m-auto"
        ></mat-icon>
        <span class="text-xl font-weight mt-2 m-auto">{{
            "bslk.charts.no-data" | transloco
        }}</span>
        <span class="text-hint text-center m-auto">{{
            "bslk.charts.no-data-info" | transloco
        }}</span>
    </div>
</div>
