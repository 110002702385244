import { AsyncPipe, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkAdvancedSelectSyncComponent } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { isNil, TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { CompanyCsrApproachSelectors } from 'app/store/company/csr-approach/csr-approach.selectors';
import { MicroActionListActions } from 'app/store/micro-action/micro-action-list/micro-action-list.actions';
import { MicroActionListSelectors } from 'app/store/micro-action/micro-action-list/micro-action-list.selectors';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
    selector: 'company-csr-approach-edit-matrix-point',
    templateUrl: './edit-matrix-point.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslocoModule,
        BslkAdvancedSelectSyncComponent,
        BslkLengthInfoComponent,
        FuseDrawerComponent,
        NgIf,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        BslkClassicButtonComponent,
        MatIconModule,
        SingleSelectAsyncComponent,
        FormsModule,
        QuillEditorComponent,
        BslkAdvancedSelectSyncComponent,
    ],
})
export class CompanyCsrApproachEditMatrixPointComponent {
    @ViewChild('editMatrixPointDrawer') drawer: FuseDrawerComponent;

    @Input() isCreating: boolean = false;
    @Input() editedPointIndex: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    MicroActionListSelectors = MicroActionListSelectors;
    MicroActionListActions = MicroActionListActions;
    quillModules = QUILL_MODULES;

    legends$ = this.store.select(
        CompanyCsrApproachSelectors.selectMatrixLegendsAsSelectInput
    );

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService
    ) {}

    get formArray() {
        const matrixFormGroup = this.formService.getFormGroup.controls
            .matrix as FormGroup;
        return matrixFormGroup.controls.points as FormArray;
    }

    get formGroup() {
        // Return edited point form group, or last form group if is creating
        return this.formArray.controls.at(
            this.editedPointIndex ?? this.formArray.controls.length - 1
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !isNil(changes.editedPointIndex?.currentValue) ||
            changes.isCreating?.currentValue
        ) {
            this.drawer.open();
        }
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            this.handleClose();
        }
    }

    forceClose() {
        this.drawer.close();
        this.handleClose();
    }

    handleClose() {
        this.close.emit();
        this.isCreating = false;
        this.editedPointIndex = null;
    }

    update() {
        this.forceClose();
    }

    remove() {
        this.formArray.removeAt(this.editedPointIndex);
        this.formArray.markAsDirty();
        this.forceClose();
    }
}
