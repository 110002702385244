<div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
    <ng-container *ngIf="!isLoading; else skeletons">
        <micro-action-grid-card
            *ngFor="let microAction of microActions"
            [microAction]="microAction"
        >
        </micro-action-grid-card>
    </ng-container>
</div>

<ng-template #skeletons>
    <ngx-skeleton-loader
        *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
</ng-template>
