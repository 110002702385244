import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    EMicroActionType,
    LocationViewModel,
    MicroActionActivityViewModel,
    MicroActionViewModel,
} from 'app/api';
import { SLOT_DEFAULT_HOURS } from 'app/shared/utils/constants.utils';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { DateTime } from 'luxon';
import { IFormService } from './form-service';
import { LocationFormService } from './location-form.service';

@Injectable({
    providedIn: 'root',
})
export class MicroActionFormService
    extends LocationFormService
    implements IFormService<MicroActionViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder, private datePipe: DatePipe) {
        super(fb);
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group(
            {
                id: [null],
                name: ['', [Validators.required, Validators.maxLength(100)]],
                impact: ['', [Validators.required, Validators.maxLength(100)]],
                description: [
                    '',
                    [Validators.required, Validators.maxLength(500)],
                ],
                startTime: [
                    SLOT_DEFAULT_HOURS.START_AS_STRING,
                    CustomValidators.timeValidator,
                ],
                endTime: [
                    SLOT_DEFAULT_HOURS.END_AS_STRING,
                    CustomValidators.timeValidator,
                ],
                startDay: [''],
                endDay: [''],
                startDate: [null, Validators.required],
                endDate: [null, Validators.required],
                type: [EMicroActionType.Action, Validators.required],
                externalProvider: [null],
                isRemote: [false],
                maximumSlots: [null],
                companyAgencyId: [null],
                isAgencyRestricted: [false],
                hasMaximumSlots: [false],
                location: this.initLocationForm(true),
                sustainableDevelopmentGoals: [[], Validators.required],
                activityFields: [
                    [],
                    [
                        Validators.required,
                        CustomValidators.maxArrayLengthValidator(3),
                    ],
                ],
                activities: this.fb.array([this.createActivity()]),
            },
            { validators: CustomValidators.startAndEndDateValidator }
        );
        return this.formGroup;
    }

    createLocation(location?: LocationViewModel): FormGroup {
        return this.fb.group({
            id: [location?.id ?? 0],
            longitude: [location?.longitude ?? null],
            latitude: [location?.latitude ?? null],
            name: [location?.name ?? ''],
        });
    }

    createActivity(activity?: MicroActionActivityViewModel): FormGroup {
        return this.fb.group({
            id: [activity?.id ?? 0],
            name: [
                activity?.name,
                [Validators.required, Validators.maxLength(100)],
            ],
            description: [
                activity?.description,
                [Validators.required, Validators.maxLength(150)],
            ],
            additionalInformation: [
                activity?.additionalInformation,
                Validators.maxLength(200),
            ],
            effortLevel: [activity?.effortLevel ?? 1, Validators.required],
        });
    }

    updateForm(entity: MicroActionViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);

        this.formGroup.controls.hasMaximumSlots.setValue(
            entity.maximumSlots > 0
        );

        this.formGroup.patchValue({
            startDay: DateTime.fromISO(entity.startDate),
            endDay: DateTime.fromISO(entity.endDate),
            startTime: this.datePipe.transform(entity.startDate, 'HH:mm'),
            endTime: this.datePipe.transform(entity.endDate, 'HH:mm'),
        });

        const activitiesFormArray = this.formGroup.controls
            .activities as FormArray;
        activitiesFormArray.clear();
        entity.activities.forEach((activity) => {
            activitiesFormArray.push(this.createActivity(activity));
        });

        this.patchLocation(entity.location);
    }

    getEntity(): { [key: string]: MicroActionViewModel } {
        const microActionViewModel =
            returnOnlyNonNullFields<MicroActionViewModel>(
                this.formGroup.value
            ) as MicroActionViewModel;

        microActionViewModel.location =
            this.formGroup.value.location &&
            !this.formGroup.controls.isRemote.value
                ? returnOnlyNonNullFields<LocationViewModel>(
                      this.formGroup.value.location
                  )
                : null;

        return { microActionViewModel };
    }
}
