import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkCheckboxComponent } from '@bslk/components/form/checkbox/checkbox.component';
import { BslkRewardComponent } from '@bslk/components/reward/reward.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EReward, MicroActionActivityViewModel } from 'app/api';
import { getRewardValues } from 'app/shared/utils/rewards.utils';
import { MicroActionActions } from 'app/store/micro-action/micro-action/micro-action.action';
import { MicroActionSelectors } from 'app/store/micro-action/micro-action/micro-action.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';

@Component({
    selector: 'micro-action-activities',
    templateUrl: './activities.component.html',
    standalone: true,
    imports: [
        BslkClassicCardComponent,
        BslkCheckboxComponent,
        BslkRewardComponent,
        TranslocoModule,
        NgFor,
        NgIf,
        LetDirective,
        MatIconModule,
        MatTooltipModule,
        AsyncPipe,
    ],
})
export class MicroActionActivitiesComponent {
    @Input() activities: MicroActionActivityViewModel[];

    rewardValues = getRewardValues(EReward.MicroActionActivityCompleted);

    userId$ = this.store.select(UserSelectors.selectUserId);
    isLoading$ = this.store.select(
        MicroActionSelectors.selectIsLoadingMarkActivityAsCompleted
    );
    isRegistered$ = this.store.select(MicroActionSelectors.selectIsRegistered);

    constructor(private store: Store) {}

    isActivityCompleted(
        activity: MicroActionActivityViewModel,
        userId: number
    ) {
        return activity.users.map((u) => u.id).includes(userId);
    }

    activityCompletedChange(id: number, checked: boolean) {
        if (checked) {
            this.store.dispatch(
                MicroActionActions.markActivityAsCompletedRequest({
                    id,
                })
            );
        } else {
            this.store.dispatch(
                MicroActionActions.unmarkActivityAsCompletedRequest({
                    id,
                })
            );
        }
    }
}
