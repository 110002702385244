<div class="flex flex-col gap-3" [formGroup]="formGroup">
    <div class="flex gap-3">
        <div class="flex flex-col gap-1 flex-1">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                <mat-label>{{
                    "createmicroaction.name" | transloco
                }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:pencil'"
                    matPrefix
                ></mat-icon>
                <input
                    [placeholder]="
                        'createmicroaction.name-placeholder' | transloco
                    "
                    [formControlName]="'name'"
                    matInput
                />
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.name.value"
                [maxLength]="100"
            ></bslk-length-info>
        </div>

        <bslk-advanced-select-sync
            class="flex-1"
            *ngIf="activityFieldsTranslationsLoaded"
            [items]="activityFieldsAdvancedSelectItems"
            [multiple]="'multiple'"
            [group]="formGroup"
            [icon]="'mat_solid:category'"
            [controlName]="'activityFields'"
            [label]="'createmicroaction.activity-fields' | transloco"
            [selectPlaceholder]="
                'createmicroaction.activity-fields-placeholder' | transloco
            "
            [searchPlaceholder]="
                'createmicroaction.search-activity-fields-placeholder'
                    | transloco
            "
        ></bslk-advanced-select-sync>
    </div>

    <div class="flex gap-3">
        <div class="flex flex-col gap-1 flex-1">
            <!-- Description -->
            <bslk-info-label
                class="font-bold"
                [tooltip]="'createmicroaction.challenge-tooltip' | transloco"
                >{{
                    "createmicroaction.description" | transloco
                }}</bslk-info-label
            >
            <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                <textarea
                    class="min-h-16"
                    [placeholder]="
                        'createmicroaction.description-placeholder' | transloco
                    "
                    [formControlName]="'description'"
                    matInput
                ></textarea>
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.description.value"
                [maxLength]="500"
            ></bslk-length-info>
        </div>
        <div class="flex flex-col gap-1 flex-1">
            <!-- Type -->
            <bslk-card-toggle
                [label]="'createmicroaction.type' | transloco"
                [control]="formGroup.controls.type"
                [value]="formGroup.controls.type.value"
                [choices]="[
                    {
                        value: EMicroActionType.Action,
                        text: 'createmicroaction.action' | transloco,
                        description:
                            'createmicroaction.action-description' | transloco,
                        icon: 'feather:target'
                    },
                    {
                        value: EMicroActionType.Awareness,
                        text: 'createmicroaction.awareness' | transloco,
                        description:
                            'createmicroaction.awareness-description'
                            | transloco,
                        icon: 'heroicons_outline:academic-cap'
                    }
                ]"
            ></bslk-card-toggle>
        </div>
    </div>

    <div class="flex flex-col gap-y-3 justify-between h-full">
        <!-- Start and end date -->
        <div class="w-full flex gap-3 justify-between items-start">
            <mat-form-field
                [subscriptSizing]="'dynamic'"
                floatLabel="always"
                (click)="pickerStart.open()"
                class="w-full"
            >
                <mat-label>{{
                    "createmission.start-date" | transloco
                }}</mat-label>
                <input
                    class="cursor-pointer"
                    matInput
                    readonly
                    [matDatepicker]="pickerStart"
                    [matDatepickerFilter]="getDatesFilter"
                    [formControl]="formGroup.controls.startDay"
                    [placeholder]="
                        'createmission.start-date-placeholder' | transloco
                    "
                />
                <mat-datepicker-toggle
                    matPrefix
                    [for]="pickerStart"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field
                [subscriptSizing]="'dynamic'"
                floatLabel="always"
                (click)="pickerEnd.open()"
                class="w-full"
            >
                <mat-label>{{
                    "createmission.end-date" | transloco
                }}</mat-label>
                <input
                    class="cursor-pointer"
                    [placeholder]="
                        'createmission.end-date-placeholder' | transloco
                    "
                    matInput
                    readonly
                    [matDatepicker]="pickerEnd"
                    [matDatepickerFilter]="getDatesFilter"
                    [formControl]="formGroup.controls.endDay"
                />
                <mat-datepicker-toggle
                    matPrefix
                    [for]="pickerEnd"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- Time picker -->
        <div
            *ngIf="displayTimePicker"
            class="w-full flex justify-between items-center"
        >
            <mat-form-field [subscriptSizing]="'dynamic'" class="w-1/2 pr-3">
                <mat-label>{{
                    "createmission.start-time" | transloco
                }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:clock'"
                    matPrefix
                ></mat-icon>
                <input
                    [formControl]="formGroup.controls.startTime"
                    type="time"
                    matInput
                />
                <mat-error>
                    {{ "createmission.start-time-error" | transloco }}
                </mat-error>
            </mat-form-field>

            <mat-form-field [subscriptSizing]="'dynamic'" class="w-1/2 pl-3">
                <mat-label>{{
                    "createmission.end-time" | transloco
                }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:clock'"
                    matPrefix
                ></mat-icon>
                <input
                    [formControl]="formGroup.controls.endTime"
                    type="time"
                    matInput
                />
                <mat-error>
                    {{ "createmission.end-time-error" | transloco }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="grid grid-cols-2">
        <div class="flex">
            <bslk-classic-toggle
                [label]="'createmicroaction.has-maximum-slots' | transloco"
                [isLabelBold]="true"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.hasMaximumSlots"
                [value]="formGroup.controls.hasMaximumSlots.value"
            ></bslk-classic-toggle>
        </div>
        <bslk-slider
            *ngIf="formGroup.controls.hasMaximumSlots.value"
            [label]="'createmicroaction.maximum-slots' | transloco"
            [max]="10"
            [unitLabel]="'createmicroaction.slots' | transloco"
            [control]="formGroup.controls.maximumSlots"
            [value]="formGroup.controls.maximumSlots.value"
        ></bslk-slider>
    </div>
</div>
