import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { EditMatrixLegendsDialogService } from '../edit-matrix-legends/edit-matrix-legends.service';

@Component({
    selector: 'edit-matrix',
    templateUrl: './edit-matrix.component.html',
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        TranslocoModule,
        BslkTagButtonComponent,
        BslkLengthInfoComponent,
    ],
})
export class EditMatrixComponent {
    constructor(
        private formService: CompanyCsrApproachFormService,
        private editMatrixLegendsDialogService: EditMatrixLegendsDialogService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup.controls.matrix as FormGroup;
    }

    openEditLegendsDialog() {
        this.editMatrixLegendsDialogService.open();
    }
}
