import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachService } from 'app/api';
import { EditMatrixLegendsDialogService } from 'app/modules/shared/csr-approach/matrix/edit/edit-matrix-legends/edit-matrix-legends.service';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { CompanyCsrApproachActions } from './csr-approach.actions';
import { CompanyCsrApproachSelectors } from './csr-approach.selectors';

@Injectable()
export class CompanyCsrApproachEffects {
    loadCsrapproach$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyCsrApproachActions.loadCsrApproachRequest),
            exhaustMap(() => {
                return this.service.companyCsrApproachCompanyGet().pipe(
                    map((csrApproach) => {
                        this.formService.updateForm(csrApproach);
                        return CompanyCsrApproachActions.loadCsrApproachRequestSuccess(
                            {
                                csrApproach,
                            }
                        );
                    }),
                    catchError((httpResponse) =>
                        of(
                            CompanyCsrApproachActions.loadCsrApproachRequestFail(
                                {
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                }
                            )
                        )
                    )
                );
            })
        )
    );

    updateCsrApproach$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyCsrApproachActions.updateCsrApproachRequest),
            exhaustMap(() => {
                const formValue = this.formService.getEntity();
                return this.service.companyCsrApproachPut(formValue).pipe(
                    map((csrApproach) => {
                        this.formService.updateForm(csrApproach);
                        return CompanyCsrApproachActions.updateCsrApproachRequestSuccess(
                            {
                                csrApproach,
                            }
                        );
                    }),
                    catchError((httpResponse) =>
                        of(
                            CompanyCsrApproachActions.updateCsrApproachRequestFail(
                                {
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                }
                            )
                        )
                    )
                );
            })
        )
    );

    updateImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyCsrApproachActions.updateImageRequest),
            exhaustMap(({ file }) => {
                return this.service.companyCsrApproachImagePut({ file }).pipe(
                    map((url) => {
                        this.fileUploadDialogService.close();

                        return CompanyCsrApproachActions.updateImageRequestSuccess(
                            {
                                url,
                            }
                        );
                    }),
                    catchError((httpResponse) =>
                        of(
                            CompanyCsrApproachActions.updateImageRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    updatePillarImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyCsrApproachActions.updatePillarImageRequest),
            exhaustMap(({ id, file }) => {
                return this.service
                    .companyCsrApproachPillarPillarIdImagePut({
                        pillarId: id,
                        file,
                    })
                    .pipe(
                        map((url) => {
                            this.fileUploadDialogService.close();
                            return CompanyCsrApproachActions.updatePillarImageRequestSuccess(
                                {
                                    url,
                                    pillarId: id,
                                }
                            );
                        }),
                        catchError((httpResponse) =>
                            of(
                                CompanyCsrApproachActions.updatePillarImageRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    updateLegends$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyCsrApproachActions.createOrUpdateMatrixLegendRequest),
            exhaustMap(({ legends }) => {
                return this.service
                    .companyCsrApproachMatrixLegendsPatch({
                        companyMatrixLegendViewModel: legends,
                    })
                    .pipe(
                        map((csrApproach) => {
                            this.formService.updateForm(csrApproach);
                            return CompanyCsrApproachActions.createOrUpdateMatrixLegendRequestSuccess(
                                {
                                    csrApproach,
                                }
                            );
                        }),
                        catchError((httpResponse) =>
                            of(
                                CompanyCsrApproachActions.createOrUpdateMatrixLegendRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    closeAfterLegendsUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                CompanyCsrApproachActions.createOrUpdateMatrixLegendRequestSuccess
            ),
            map(() => {
                this.editMatrixLegendsDialogService.close();
                return AlertActions.displaySuccess({
                    key: 'csr-approach-matrix-legends-update',
                });
            })
        )
    );

    removeImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyCsrApproachActions.removeImageRequest),
            exhaustMap(() => {
                return this.service.companyCsrApproachImageDelete().pipe(
                    map(() => {
                        return CompanyCsrApproachActions.removeImageRequestSuccess();
                    }),
                    catchError((httpResponse) =>
                        of(
                            CompanyCsrApproachActions.removeImageRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    resetLegendsForm$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CompanyCsrApproachActions.resetMatrixLegendsForm),
                concatLatestFrom(() =>
                    this.store.select(
                        CompanyCsrApproachSelectors.selectCsrApproach
                    )
                ),
                tap(([, csrApproach]) => {
                    this.formService.patchLegends(csrApproach.matrix.legends);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private service: CompanyCsrApproachService,
        private formService: CompanyCsrApproachFormService,
        private fileUploadDialogService: FileUploadDialogService,
        private editMatrixLegendsDialogService: EditMatrixLegendsDialogService
    ) {}
}
