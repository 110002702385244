<div [formGroup]="formGroup" class="flex flex-col">
    <fuse-alert class="flex mt-1 justify-center" type="info">{{
        "createmicroaction.activity-info" | transloco
    }}</fuse-alert>
    <div
        class="flex flex-col mb-2 gap-2"
        *ngFor="
            let activityFormGroup of activitiesFormArray.controls;
            let i = index
        "
    >
        <span class="my-2 font-bold text-xl"
            >{{ "createmicroaction.activity-number" | transloco }}
            {{ i + 1 }}</span
        >
        <div class="flex gap-3">
            <div class="flex flex-col gap-1 flex-1">
                <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                    <mat-label>{{
                        "createmicroaction.activity-name" | transloco
                    }}</mat-label>
                    <input
                        [placeholder]="
                            'createmicroaction.activity-name-placeholder'
                                | transloco
                        "
                        [formControl]="activityFormGroup.controls.name"
                        matInput
                    />
                </mat-form-field>
                <bslk-length-info
                    [text]="activityFormGroup.controls.name.value"
                    [maxLength]="100"
                ></bslk-length-info>
            </div>
            <div class="flex flex-col gap-1 flex-1">
                <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                    <mat-label>{{
                        "createmicroaction.activity-description" | transloco
                    }}</mat-label>
                    <input
                        [placeholder]="
                            'createmicroaction.activity-description-placeholder'
                                | transloco
                        "
                        [formControl]="activityFormGroup.controls.description"
                        matInput
                    />
                </mat-form-field>
                <bslk-length-info
                    [text]="activityFormGroup.controls.description.value"
                    [maxLength]="150"
                ></bslk-length-info>
            </div>
        </div>

        <div class="flex gap-3">
            <div class="flex w-1/2 flex-col gap-1 flex-1">
                <!-- Description -->
                <bslk-info-label
                    class="font-bold"
                    [tooltip]="
                        'createmicroaction.challenge-tooltip' | transloco
                    "
                    >{{
                        "createmicroaction.activity-additional-information"
                            | transloco
                    }}</bslk-info-label
                >
                <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                    <textarea
                        class="min-h-16"
                        [placeholder]="
                            'createmicroaction.activity-additional-information-placeholder'
                                | transloco
                        "
                        [formControl]="
                            activityFormGroup.controls.additionalInformation
                        "
                        matInput
                    ></textarea>
                </mat-form-field>
                <bslk-length-info
                    [text]="
                        activityFormGroup.controls.additionalInformation.value
                    "
                    [maxLength]="200"
                ></bslk-length-info>
            </div>
            <div class="flex w-1/2 justify-center items-center">
                <bslk-star-rating
                    [label]="
                        'createmicroaction.activity-effort-level' | transloco
                    "
                    [labelTooltip]="
                        'createmicroaction.activity-effort-level-tooltip'
                            | transloco
                    "
                    [maxRating]="3"
                    [size]="'large'"
                    [control]="activityFormGroup.controls.effortLevel"
                ></bslk-star-rating>
            </div>
        </div>

        <div class="flex justify-end">
            <bslk-tag-button
                *ngIf="canDeleteActivity"
                (onClick)="removeActivity(i)"
                [icon]="'heroicons_outline:trash'"
                >{{
                    "createmicroaction.remove-activity" | transloco
                }}</bslk-tag-button
            >
        </div>

        <!-- Divider -->
        <div class="w-full border-b mt-4"></div>
    </div>

    <div class="flex justify-end">
        <bslk-tag-button
            (onClick)="addActivity()"
            [icon]="'heroicons_outline:plus'"
            >{{ "createmicroaction.add-activity" | transloco }}</bslk-tag-button
        >
    </div>
</div>
