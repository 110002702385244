import {
    MicroActionCommentViewModel,
    MicroActionCommentViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const MicroActionCommentListActions = {
    ...createRessourceListActionGroup<
        MicroActionCommentViewModel,
        MicroActionCommentViewModelPaginatedListDto
    >('MicroActionCommentList'),
};
