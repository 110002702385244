<fuse-vertical-navigation
    *ngrxLet="customization$ as customization"
    class="vertical-navigation print:hidden"
    [class.bg-warn]="!customization?.primaryColor"
    [ngStyle]="
        customization?.primaryColor
            ? { backgroundColor: customization.primaryColor }
            : {}
    "
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex p-4 pb-0 relative">
            <div class="mx-auto">
                <img
                    class="mx-auto"
                    [class.svg-white]="!customization?.imageUrl"
                    [class.max-w-40]="customization?.imageUrl"
                    [class.mt-6]="customization?.imageUrl"
                    [src]="
                        customization?.imageUrl ??
                        'assets/images/logo/logo-text-vertical.svg'
                    "
                    alt="Logo image"
                />
            </div>

            <bslk-tag
                [backgroundHexaColor]="customization?.secondaryColor"
                size="small"
                class="absolute right-3"
                >bêta</bslk-tag
            >
        </div>
    </ng-container>

    <ng-container fuseVerticalNavigationFooter>
        <div
            [matMenuTriggerFor]="userActions"
            [class.border-primary]="!customization?.secondaryColor"
            [ngStyle]="
                customization?.secondaryColor
                    ? { borderColor: customization.secondaryColor }
                    : {}
            "
            class="flex items-center w-full p-4 user border-t"
            *ngrxLet="hasAvatar$ as hasAvatar"
        >
            <div
                *ngrxLet="isAssoAdmin$ as isAssoAdmin"
                class="user__avatar flex"
            >
                <classic-avatar
                    *ngIf="isAssoAdmin"
                    [size]="12"
                    [borderSize]="4"
                    [textSize]="2"
                    [avatarUrl]="avatarUrl$ | async"
                    [fallbackLetter]="userNameFirstLetter$ | async"
                ></classic-avatar>
                <basile-avatar
                    *ngIf="!isAssoAdmin"
                    [configuration]="basileConfig$ | async"
                    [ringSize]="'small'"
                    class="flex flex-shrink-0 w-20 h-20"
                ></basile-avatar>
            </div>
            <div
                class="flex truncate flex-col items-center justify-center ml-4"
            >
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal font-medium mb-2"
                >
                    {{ displayedName$ | async }}
                </div>
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-sm"
                >
                    {{ email$ | async }}
                </div>
            </div>
        </div>

        <mat-menu [xPosition]="'before'" #userActions="matMenu">
            <button mat-menu-item>
                <span class="flex flex-col leading-none">
                    <span>{{ "shared.user.signed-in-as" | transloco }}</span>
                    <span class="mt-1.5 text-md font-medium"
                        >{{ email$ | async }}
                    </span>
                </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <a routerLink="/s/settings" mat-menu-item>
                <mat-icon
                    [svgIcon]="'heroicons_outline:cog-8-tooth'"
                ></mat-icon>
                <span>{{ "shared.user.settings" | transloco }}</span>
            </a>
            <mat-divider class="my-2"></mat-divider>
            <a routerLink="/sign-out" mat-menu-item>
                <mat-icon
                    [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
                ></mat-icon>
                <span>{{ "shared.user.sign-out" | transloco }}</span>
            </a>
        </mat-menu>
    </ng-container>
</fuse-vertical-navigation>
