<ng-container *ngrxLet="pendingMissions$ as pendingMissions">
    <base-kpi-panel
        *ngrxLet="isLoading$ as isLoading"
        [title]="'Missions en attente'"
        [kpiName]="kpiName"
        [isExpandable]="false"
        [isExpanded]="true"
        [isLoading]="isLoading"
        [isSuperAdminKpi]="true"
    >
        <div *ngIf="!isLoading" expandedContent>
            <div class="relative overflow-x-scroll">
                <table class="w-full text-left mt-1">
                    <thead
                        class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
                    >
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{ "Mission" }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "Mécènes" }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "Statut" }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "Responsable" }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "Message" }}
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngrxLet="expandedChats$ as expandedChats">
                        <tr
                            *ngFor="let row of pendingMissions.data"
                            class="bg-white border-b"
                        >
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex">
                                    <img
                                        *ngIf="row.missionSlot.mission.coverUrl"
                                        class="w-10 h-10 my-auto rounded-lg mr-2"
                                        [src]="row.missionSlot.mission.coverUrl"
                                    />
                                    <div
                                        *ngIf="
                                            !row.missionSlot.mission.coverUrl
                                        "
                                        class="w-10 h-10 my-auto rounded-lg mr-2 bg-gray-200"
                                    ></div>
                                    <div class="flex flex-col truncate">
                                        <span class="font-bold truncate">{{
                                            row.missionSlot.mission.name
                                        }}</span>
                                        <span class="text-hint truncate">{{
                                            row.missionSlot.mission.association
                                                .name
                                        }}</span>
                                    </div>
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex flex-col gap-1">
                                    <div
                                        [matTooltip]="
                                            missionSlotUser.user.email
                                        "
                                        *ngFor="
                                            let missionSlotUser of row
                                                .missionSlot.users
                                        "
                                    >
                                        {{ missionSlotUser.user.name }}
                                    </div>
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex flex-col gap-1">
                                    <div
                                        *ngFor="
                                            let missionSlotUser of row
                                                .missionSlot.users
                                        "
                                    >
                                        <mission-state
                                            [state]="
                                                missionSlotUser.missionState
                                            "
                                            [showDescriptionAsTooltip]="false"
                                        >
                                        </mission-state>
                                    </div>
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex flex-col gap-1">
                                    <div
                                        [matTooltip]="
                                            row.missionSlot.mission
                                                .responsibleUser.email
                                        "
                                    >
                                        {{
                                            row.missionSlot.mission
                                                .responsibleUser.name
                                        }}
                                    </div>
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <ng-container *ngIf="row.chat">
                                    <div
                                        *ngIf="
                                            !expandedChats.includes(row.chat.id)
                                        "
                                        class="flex flex-col gap-1"
                                    >
                                        <span
                                            class="truncate"
                                            [matTooltip]="row.chat.lastMessage"
                                        >
                                            {{ row.chat.lastMessage }}</span
                                        >
                                        <span
                                            (click)="expandChat(row.chat.id)"
                                            class="cursor-pointer font-bold text-sm"
                                            >{{ "Voir plus" }}</span
                                        >
                                    </div>
                                    <div
                                        *ngIf="
                                            expandedChats.includes(row.chat.id)
                                        "
                                        class="flex flex-col gap-1"
                                    >
                                        <span
                                            *ngFor="
                                                let message of row.chat.messages
                                            "
                                            class="truncate"
                                            [matTooltip]="message.text"
                                            [ngClass]="{
                                            'text-primary': message.senderId === row.chat.messages[0].senderId,
                                            'text-warn': message.senderId !== row.chat.messages[0].senderId,
                                        }"
                                        >
                                            {{ message.text }}</span
                                        >
                                        <span
                                            (click)="minimizeChat(row.chat.id)"
                                            *ngIf="
                                                expandedChats.includes(
                                                    row.chat.id
                                                )
                                            "
                                            class="cursor-pointer font-bold text-sm"
                                            >{{ "Voir moins" }}</span
                                        >
                                    </div>
                                </ng-container>
                                <span
                                    *ngIf="!row.chat"
                                    class="text-hint italic"
                                    >{{ "Pas de message" }}</span
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator
                class="bg-warn-100"
                [ngClass]="{
                    'pointer-events-none': isLoading
                }"
                [length]="pendingMissions.totalCount"
                [pageIndex]="pendingMissions.pageIndex"
                [pageSize]="pendingMissions.pageSize"
                [hidePageSize]="true"
                (page)="pageChangeEvent($event)"
                [showFirstLastButtons]="false"
            ></mat-paginator>
        </div>
    </base-kpi-panel>
</ng-container>
