import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CompanyCsrApproachViewModel,
    CompanyMatrixLegendViewModel,
    CompanyMatrixPointViewModel,
    CompanyMatrixViewModel,
    CompanyMetricViewModel,
    CompanyPillarActionViewModel,
    CompanyPillarViewModel,
    CompanyTimelineDateViewModel,
    CompanyValueViewModel,
    ECompanyMatrixPointType,
    EUnit,
} from 'app/api';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { DateTime } from 'luxon';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class CompanyCsrApproachFormService
    implements IFormService<CompanyCsrApproachViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [null],
            companyId: [null],
            imageUrl: [null],
            title: [null, Validators.maxLength(80)],
            description: [
                null,
                CustomValidators.htmlTextLengthValidator(0, 1000),
            ],
            hidePillars: [false],
            hideValues: [false],
            hideMetrics: [false],
            hideTimeline: [false],
            hideMatrix: [false],
            matrix: this.createMatrix(),
            pillars: this.fb.array([]),
            values: this.fb.array([]),
            metrics: this.fb.array([]),
            timelineDates: this.fb.array([]),
            dateCreated: [DateTime.now().toISO()],
        });
        return this.formGroup;
    }

    createMatrix(matrix?: CompanyMatrixViewModel): FormGroup {
        return this.fb.group({
            id: [matrix?.id ?? 0],
            name: [
                matrix?.name ?? null,
                [Validators.required, Validators.maxLength(100)],
            ],
            xAxisName: [
                matrix?.xAxisName ?? null,
                [Validators.required, Validators.maxLength(50)],
            ],
            yAxisName: [
                matrix?.yAxisName ?? null,
                [Validators.required, Validators.maxLength(50)],
            ],
            points: this.fb.array(
                matrix?.points?.map((point) => this.createMatrixPoint(point)) ||
                    []
            ),
            legends: this.fb.array(
                matrix?.legends?.map((legend) =>
                    this.createMatrixLegend(legend)
                ) || []
            ),
        });
    }

    createMatrixPoint(point?: CompanyMatrixPointViewModel): FormGroup {
        return this.fb.group({
            id: [point?.id ?? 0],
            name: [point?.name ?? null, Validators.maxLength(100)],
            description: [
                point?.description ?? null,
                CustomValidators.htmlTextLengthValidator(0, 5000),
            ],
            xAxis: [point?.xAxis ?? 0, Validators.required],
            yAxis: [point?.yAxis ?? 0, Validators.required],
            type: [point?.type ?? ECompanyMatrixPointType.Custom],
            sustainableDevelopmentGoal: [
                point?.sustainableDevelopmentGoal ?? null,
            ],
            legendId: [point?.legendId ?? null],
            color: [point?.color ?? null],
            microActionIds: [point?.microActionIds ?? []],
        });
    }

    createMatrixLegend(legend?: CompanyMatrixLegendViewModel): FormGroup {
        return this.fb.group({
            id: [legend?.id ?? 0],
            name: [legend?.name ?? null, Validators.required],
            color: [legend?.color ?? '#f4af4b', Validators.required],
        });
    }

    createPillar(pillar?: CompanyPillarViewModel): FormGroup {
        return this.fb.group({
            id: [pillar?.id ?? 0],
            name: [pillar?.name ?? null, Validators.required],
            pillar: [pillar?.pillar ?? null],
            description: [pillar?.description ?? null],
            imageUrl: [pillar?.imageUrl ?? null],
            actions: this.fb.array(
                pillar?.actions?.map((action) => this.createAction(action)) ||
                    []
            ),
        });
    }

    createAction(action?: CompanyPillarActionViewModel): FormGroup {
        return this.fb.group({
            id: [action?.id ?? 0],
            name: [
                action?.name ?? null,
                [Validators.required, Validators.maxLength(100)],
            ],
            description: [
                action?.description ?? null,
                Validators.maxLength(250),
            ],
        });
    }

    createValue(value?: CompanyValueViewModel): FormGroup {
        return this.fb.group({
            id: [value?.id ?? 0],
            name: [
                value?.name ?? null,
                [Validators.required, Validators.maxLength(100)],
            ],
            description: [
                value?.description ?? null,
                Validators.maxLength(250),
            ],
        });
    }

    createMetric(metric?: CompanyMetricViewModel): FormGroup {
        return this.fb.group({
            id: [metric?.id ?? 0],
            number: [metric?.number ?? null, Validators.required],
            name: [
                metric?.name ?? null,
                [Validators.required, Validators.maxLength(150)],
            ],
            unit: [metric?.unit ?? EUnit.None, Validators.required],
        });
    }

    createTimelineDate(timelineDate?: CompanyTimelineDateViewModel): FormGroup {
        return this.fb.group({
            id: [timelineDate?.id ?? 0],
            date: [timelineDate?.date ?? null, Validators.required],
            name: [timelineDate?.name ?? null],
            description: [timelineDate?.description ?? null],
        });
    }

    updateForm(entity: CompanyCsrApproachViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        ['pillars', 'values', 'metrics', 'timelineDates'].forEach((field) => {
            const control = this.formGroup.get(field) as FormArray;
            control.clear();
            entity[field]?.forEach((item: any) => {
                let group: FormGroup;
                switch (field) {
                    case 'pillars':
                        group = this.createPillar(item);
                        break;
                    case 'values':
                        group = this.createValue(item);
                        break;
                    case 'metrics':
                        group = this.createMetric(item);
                        break;
                    case 'timelineDates':
                        group = this.createTimelineDate(item);
                        break;
                }
                control.push(group);
            });
        });

        if (entity.matrix) {
            const matrixControl = this.formGroup.get('matrix') as FormGroup;
            matrixControl.patchValue(entity.matrix);
            const pointsControl = matrixControl.get('points') as FormArray;
            pointsControl.clear();
            entity.matrix.points?.forEach((point) =>
                pointsControl.push(this.createMatrixPoint(point))
            );

            this.patchLegends(entity.matrix.legends);
        }

        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
    }

    patchLegends(legends: CompanyMatrixLegendViewModel[]) {
        const matrixControl = this.formGroup.get('matrix') as FormGroup;
        const legendsControl = matrixControl.get('legends') as FormArray;
        legendsControl.clear();
        legends.forEach((legend) =>
            legendsControl.push(this.createMatrixLegend(legend))
        );
        legendsControl.markAsPristine();
        legendsControl.markAsUntouched();
    }

    getEntity(): { [key: string]: CompanyCsrApproachViewModel } {
        this.formGroup.controls.values.updateValueAndValidity();
        this.formGroup.controls.pillars.updateValueAndValidity();

        (this.formGroup.controls.pillars as FormArray).controls.forEach(
            (p: FormGroup) => {
                p.controls.actions.updateValueAndValidity();
            }
        );
        this.formGroup.controls.timelineDates.updateValueAndValidity();
        this.formGroup.controls.metrics.updateValueAndValidity();
        this.formGroup.updateValueAndValidity();

        return {
            companyCsrApproachViewModel: this.formGroup
                .value as CompanyCsrApproachViewModel,
        };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
