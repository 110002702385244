<bslk-classic-card
    [fullHeight]="true"
    [title]="'setup.company-customization' | transloco"
>
    <div headerActions class="flex gap-5 items-center">
        <bslk-info
            [size]="'large'"
            [tooltip]="'setup.company-customization-tooltip' | transloco"
        ></bslk-info>
    </div>

    <div
        *ngIf="!(isLoadingCustomization$ | async); else loading"
        class="flex gap-8 justify-center"
    >
        <form [formGroup]="formGroup" class="flex flex-col gap-3 w-full">
            <!-- Remove autofocus trick -->
            <div style="max-width: 0; max-height: 0; overflow: hidden">
                <input autofocus="true" />
            </div>

            <div class="grid grid-cols-2">
                <div class="flex flex-col gap-1">
                    <span class="font-bold">
                        {{ "setup.logo" | transloco }}
                    </span>
                    <bslk-tag-button
                        class="flex justify-center"
                        (onClick)="openFileUploadDialog()"
                        [icon]="'heroicons_outline:photo'"
                        >{{ "setup.edit" | transloco }}</bslk-tag-button
                    >

                    <div class="max-w-48 mt-3 self-center">
                        <img
                            *ngIf="
                                !formGroup.controls.imageAsBase64.value &&
                                !formGroup.controls.imageUrl.value
                            "
                            class="mx-auto"
                            src="assets/images/logo/logo-text-vertical.svg"
                            alt="Logo image"
                        />
                        <img
                            *ngIf="imageUrl"
                            class="mx-auto"
                            [src]="imageUrl"
                            alt="Uploaded logo image"
                        />
                        <div class="w-full flex justify-end pt-2">
                            <span
                                (click)="resetImage()"
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{ "setup.reset" | transloco }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <div class="flex flex-col gap-1">
                        <mat-form-field
                            floatLabel="always"
                            subscriptSizing="dynamic"
                            class="w-full"
                        >
                            <mat-label>{{
                                "setup.primary-color" | transloco
                            }}</mat-label>
                            <input
                                matInput
                                [formControlName]="'primaryColor'"
                                type="color"
                            />
                        </mat-form-field>
                        <span
                            (click)="formGroup.controls.primaryColor.reset()"
                            class="text-sm font-bold text-right cursor-pointer"
                            >{{ "setup.reset" | transloco }}</span
                        >
                    </div>
                    <div class="flex flex-col gap-1">
                        <mat-form-field
                            floatLabel="always"
                            subscriptSizing="dynamic"
                            class="w-full"
                        >
                            <mat-label>{{
                                "setup.secondary-color" | transloco
                            }}</mat-label>
                            <input
                                matInput
                                [formControlName]="'secondaryColor'"
                                type="color"
                            />
                        </mat-form-field>
                        <span
                            (click)="formGroup.controls.secondaryColor.reset()"
                            class="text-sm font-bold text-right cursor-pointer"
                            >{{ "setup.reset" | transloco }}</span
                        >
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="flex justify-end mt-5 gap-5">
        <bslk-classic-button
            [isDisabled]="!isFormGroupValid"
            [isLoading]="isLoadingUpdate$ | async"
            (onClick)="save()"
            >{{ "shared.common.save" | transloco }}</bslk-classic-button
        >
    </div>
</bslk-classic-card>

<ng-template #loading>
    <div class="grid grid-cols-2 gap-2">
        <div class="flex flex-col justify-center items-center gap-5">
            <div class="w-1/3 h-12">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>

            <div class="w-1/2 h-24">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
        <div class="flex flex-col gap-8">
            <div class="w-full h-10">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>

            <div class="w-full h-10">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
