import { createSelector } from '@ngrx/store';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isEmpty, isNil } from 'lodash-es';
import { AppState } from '../..';
import { MicroActionListSelectors } from '../micro-action-list/micro-action-list.selectors';
const selectState = (state: AppState) => state.microAction;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsUploadFileLoading = createSelector(
    selectState,
    (state) => state.isUploadFileLoading
);

const selectIsLoadingRegistration = createSelector(
    selectState,
    (state) => state.isLoadingRegistration
);

const selectIsLoadingArchive = createSelector(
    selectState,
    (state) => state.isLoadingArchive
);

const selectIsLoadingMarkActivityAsCompleted = createSelector(
    selectState,
    (state) => state.isLoadingMarkActivityAsCompleted
);

const selectIsCreatingLoading = createSelector(
    selectState,
    MicroActionListSelectors.selectIsLoading,
    (state, isUpdating) => state.isCreatingLoading || isUpdating
);

const selectError = createSelector(selectState, (state) => state.error);

const selectCoverFile = createSelector(selectState, (state) => state.coverFile);

const selectMicroAction = createSelector(
    selectState,
    (state) => state.microAction
);

const selectCanRegister = createSelector(
    selectMicroAction,
    (microAction) =>
        isNil(microAction?.maximumSlots) ||
        microAction?.users.length < microAction?.maximumSlots
);

const selectIsRegistered = createSelector(
    selectMicroAction,
    UserSelectors.selectUserId,
    (microAction, userId) => {
        return microAction?.users.some((user) => user.id === userId);
    }
);

const selectCanEdit = createSelector(
    selectMicroAction,
    UserSelectors.selectUserId,
    (microAction, userId) => {
        return microAction?.createdById === userId;
    }
);

const selectLocation = createSelector(
    selectMicroAction,
    (microAction) => microAction?.location
);

const selectLocationWithoutAddress = createSelector(
    selectLocation,
    (location) => {
        let locationString: string = location.country;
        if (!isEmpty(location.zipCode)) {
            locationString += ', ' + location.zipCode;
        }
        if (!isEmpty(location.city)) {
            locationString += ' ' + location.city;
        }
        return locationString;
    }
);

export const MicroActionSelectors = {
    selectMicroAction,
    selectCoverFile,
    selectError,
    selectIsCreatingLoading,
    selectIsLoading,
    selectIsLoadingRegistration,
    selectIsUploadFileLoading,
    selectLocation,
    selectIsRegistered,
    selectIsLoadingArchive,
    selectLocationWithoutAddress,
    selectCanEdit,
    selectCanRegister,
    selectIsLoadingMarkActivityAsCompleted,
};
