import { AsyncPipe, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { DonationCampaignViewModel } from 'app/api';
import { GiftCardComponent } from 'app/modules/external/donation-gift/gift-card/gift-card.component';
import { EExternalUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { RegularDashboardSelectors } from 'app/store/regular-dashboard/regular-dashboard.selectors';

@Component({
    selector: 'pending-gifts',
    templateUrl: './pending-gifts.component.html',
    standalone: true,
    imports: [TranslocoModule, AsyncPipe, NgFor, GiftCardComponent],
})
export class PendingGiftsComponent {
    pendingGifts$ = this.store.select(
        RegularDashboardSelectors.selectPendingDonationGifts
    );

    constructor(private store: Store) {}

    openGift(campaign: DonationCampaignViewModel) {
        window.open(
            UrlHelpers.getExternalUrl(EExternalUrl.DonationGift, {
                id: campaign.accessToken,
            }),
            '_blank'
        );
    }
}
