import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { merge } from 'lodash-es';
import { AssociationDashboardActions } from './association-dashboard.actions';
import { AssociationDashboardState } from './association-dashboard.state';

export const associationDashboardInitialState: AssociationDashboardState = {
    isLoading: {
        'project-donations': false,
        'missions-kpis': false,
        'pending-association-approvals': false,
    },
    kpiFilters: {
        'project-donations': {},
        'missions-kpis': {},
        'pending-association-approvals': {},
    },
    kpiResults: {},
};

export const associationDashboardReducer = createReducer(
    associationDashboardInitialState,

    on(AssociationDashboardActions.initDashboard, (state) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = null;
        })
    ),
    on(AssociationDashboardActions.loadKpiRequest, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.isLoading[kpi] = true;
        })
    ),
    on(
        AssociationDashboardActions.loadKpiRequestSuccess,
        (state, { kpi, response }) =>
            produce(state, (draftState) => {
                draftState.isLoading[kpi] = false;
                draftState.kpiResults[kpi] = response;
            })
    ),
    on(AssociationDashboardActions.loadKpiRequestFail, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.isLoading[kpi] = false;
        })
    ),
    on(AssociationDashboardActions.expandKpi, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = kpi;
        })
    ),
    on(AssociationDashboardActions.minimizeKpi, (state) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = null;
        })
    ),
    on(
        AssociationDashboardActions.updateKpiFilters,
        (state, { kpi, filters }) =>
            produce(state, (draftState) => {
                let newFilters = merge({}, state.kpiFilters[kpi], filters);
                draftState.kpiFilters[kpi] = newFilters;
            })
    )
);
