<div
    *ngrxLet="kpis$ as kpis"
    class="col-span-4 lg:col-span-2 grid grid-cols-2 gap-4 h-full"
>
    <ng-container *ngIf="!(isLoading$ | async); else loading">
        <!-- Total Missions -->
        <div
            class="rounded-2xl bg-tertiary hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
        >
            <div
                class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
            >
                <div class="font-bold text-4xl xl:text-5xl">
                    <span>{{ kpis.totalMissions }}</span>
                </div>
                <span class="text-lg">{{
                    "associationdashboard.total-missions" | transloco
                }}</span>
            </div>

            <mat-icon
                class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                [svgIcon]="'heroicons_outline:clipboard-document'"
            ></mat-icon>
        </div>
        <!-- Active missions -->
        <div
            class="rounded-2xl bg-error hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
        >
            <div
                class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
            >
                <div class="font-bold text-4xl xl:text-5xl">
                    <span>{{ kpis.activeMissions }}</span>
                </div>
                <span class="text-lg">{{
                    "associationdashboard.active-missions" | transloco
                }}</span>
            </div>

            <mat-icon
                class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                [svgIcon]="'heroicons_outline:wrench'"
            ></mat-icon>
        </div>
        <!-- Finished missions -->
        <div
            class="rounded-2xl bg-warn hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
        >
            <div
                class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
            >
                <div class="font-bold text-4xl xl:text-5xl">
                    <span>{{ kpis.finishedMissions }}</span>
                </div>
                <span class="text-lg">{{
                    "associationdashboard.finished-missions" | transloco
                }}</span>
            </div>

            <mat-icon
                class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                [svgIcon]="'heroicons_outline:check-badge'"
            ></mat-icon>
        </div>
        <!-- Total volunteers -->
        <div
            class="rounded-2xl bg-primary hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
        >
            <div
                class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
            >
                <div class="font-bold text-4xl xl:text-5xl">
                    <span>{{ kpis.totalVolunteers }}</span>
                </div>
                <span class="text-lg">{{
                    "associationdashboard.total-volunteers" | transloco
                }}</span>
            </div>

            <mat-icon
                class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                [svgIcon]="'heroicons_outline:users'"
            ></mat-icon>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    <div class="shadow-lg min-h-24">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
    <div class="shadow-lg min-h-24">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
    <div class="shadow-lg min-h-24">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
    <div class="shadow-lg min-h-24">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
