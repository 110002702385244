import { createReducer, on } from '@ngrx/store';
import { SetupActions } from './setup.action';
import { SetupState } from './setup.state';

export const setupInitialState: SetupState = {
    isLoadingAdministrativeContactUpdate: false,
    isLoadingCompany: false,
    isLoadingCustomization: false,
    isLoadingCustomizationUpdate: false,
};

export const setupReducer = createReducer(
    setupInitialState,

    on(SetupActions.loadCompanyRequest, (state) => {
        return {
            ...state,
            isLoadingCompany: true,
        };
    }),
    on(SetupActions.loadCompanyRequestSuccess, (state, { response }) => {
        return {
            ...state,
            company: response,
            isLoadingCompany: false,
        };
    }),
    on(SetupActions.loadCompanyRequestFail, (state) => {
        return {
            ...state,
            isLoadingCompany: false,
        };
    }),

    on(SetupActions.loadCompanyCustomizationRequest, (state) => {
        return {
            ...state,
            isLoadingCustomization: true,
        };
    }),
    on(
        SetupActions.loadCompanyCustomizationRequestSuccess,
        (state, { response }) => {
            return {
                ...state,
                customization: response,
                isLoadingCustomization: false,
            };
        }
    ),
    on(SetupActions.loadCompanyCustomizationRequestFail, (state) => {
        return {
            ...state,
            isLoadingCustomization: false,
        };
    }),

    on(SetupActions.updateAdministrativeContactRequest, (state) => {
        return {
            ...state,
            isLoadingAdministrativeContactUpdate: true,
        };
    }),
    on(SetupActions.updateAdministrativeContactRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingAdministrativeContactUpdate: false,
        };
    }),
    on(SetupActions.updateAdministrativeContactRequestFail, (state) => {
        return {
            ...state,
            isLoadingAdministrativeContactUpdate: false,
        };
    }),

    on(SetupActions.updateCustomizationRequest, (state) => {
        return {
            ...state,
            isLoadingCustomizationUpdate: true,
        };
    }),
    on(SetupActions.updateCustomizationRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingCustomizationUpdate: false,
        };
    }),
    on(SetupActions.updateCustomizationRequestFail, (state) => {
        return {
            ...state,
            isLoadingCustomizationUpdate: false,
        };
    })
);
