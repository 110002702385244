import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AssociationDashboardService } from 'app/api';
import { catchError, filter, map, mergeMap, Observable, of, tap } from 'rxjs';
import { AssociationDashboardActions } from './association-dashboard.actions';
import { AssociationDashboardSelectors } from './association-dashboard.selectors';
import {
    AssociationKpi,
    AssociationKpiResponse,
} from './association-dashboard.state';

@Injectable()
export class AssociationDashboardEffects {
    checkIfKpiIsLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationDashboardActions.checkIfKpiIsLoaded),
            concatLatestFrom(({ kpi }) =>
                this.store.select(
                    AssociationDashboardSelectors.selectIsKpiResultReady(kpi)
                )
            ),
            filter(([, isKpiResultReady]) => !isKpiResultReady),
            map(([{ kpi }]) =>
                AssociationDashboardActions.loadKpiRequest({
                    kpi,
                })
            )
        )
    );

    triggerLoadOnFilterUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationDashboardActions.updateKpiFilters),
            map(({ kpi }) =>
                AssociationDashboardActions.loadKpiRequest({
                    kpi,
                })
            )
        )
    );

    scrollTopOnExpand$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AssociationDashboardActions.expandKpi),
                tap(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
            ),
        { dispatch: false }
    );

    loadKpi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationDashboardActions.loadKpiRequest),
            concatLatestFrom(({ kpi }) =>
                this.store.select(
                    AssociationDashboardSelectors.selectKpiFilters(kpi)
                )
            ),
            mergeMap(([{ kpi }, filters]) => {
                const apiCall = this.getKpiApiCall(kpi);
                const boundApiCall = apiCall.bind(
                    this.associationDashboardApiService
                );
                const apiObservable = boundApiCall(
                    filters
                ) as Observable<AssociationKpiResponse>;
                return apiObservable.pipe(
                    map((response) =>
                        AssociationDashboardActions.loadKpiRequestSuccess({
                            kpi,
                            response,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            AssociationDashboardActions.loadKpiRequestFail({
                                kpi,
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    getKpiApiCall(kpi: AssociationKpi) {
        switch (kpi) {
            case 'project-donations':
                return this.associationDashboardApiService
                    .associationDashboardProjectDonationsGet;
            case 'missions-kpis':
                return this.associationDashboardApiService
                    .associationDashboardMissionsKpisGet;
            case 'pending-association-approvals':
                return this.associationDashboardApiService
                    .associationDashboardPendingAssociationApprovalsGet;
            default:
                throw Error(`KPI ${kpi} not supported`);
        }
    }

    constructor(
        private actions$: Actions,
        private store: Store,
        private associationDashboardApiService: AssociationDashboardService
    ) {}
}
