import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import { EditMatrixLegendsDialog } from './edit-matrix-legends.component';

@Injectable({ providedIn: 'root' })
export class EditMatrixLegendsDialogService implements IDialogService {
    private dialogRef: MatDialogRef<EditMatrixLegendsDialog>;

    constructor(private dialog: MatDialog) {}

    open() {
        this.dialogRef = this.dialog.open(EditMatrixLegendsDialog);
    }

    close() {
        this.dialogRef.close();
    }
}
