import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { getUserFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { MissionListSelectors } from 'app/store/mission/mission-list/mission-list.selectors';
import { MissionUserMatchActions } from 'app/store/mission/mission-user-match/mission-user-match.action';
import { MissionUserMatchSelectors } from 'app/store/mission/mission-user-match/mission-user-match.selectors';
import { isEmpty } from 'lodash-es';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { PotentialMatchComponent } from './potential-match/potential-match.component';

@Component({
    selector: 'find-potential-matches',
    standalone: true,
    templateUrl: './find-potential-matches.component.html',
    imports: [
        TranslocoModule,
        LetDirective,
        AsyncPipe,
        NgIf,
        NgClass,
        SingleSelectAsyncComponent,
        NgxSkeletonLoaderModule,
        PotentialMatchComponent,
    ],
})
export class FindPotentialMatchesComponent implements OnInit, OnDestroy {
    MissionListSelectors = MissionListSelectors;
    MissionListActions = MissionListActions;
    filters = [
        {
            key: 'associationId',
            value: getUserFromLocalStorage().association.id,
        },
        {
            key: 'hasAnyAvailableSpotLeft',
            value: true,
        },
    ];
    formControl = new FormControl();
    unsubscribeAll: Subject<any> = new Subject<any>();

    missions$ = this.store
        .select(MissionListSelectors.selectData)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((missions) => {
            if (!isEmpty(missions)) {
                this.formControl.setValue(missions[0].id);
            }
        });

    isLoadingMissions$ = this.store.select(
        MissionListSelectors.selectIsLoading
    );
    isLoadingMatches$ = this.store.select(
        MissionUserMatchSelectors.selectIsLoading
    );

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.formControl.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((missionId) => {
                this.store.dispatch(
                    MissionUserMatchActions.loadPotentialMatchesRequest({
                        missionId,
                    })
                );
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
