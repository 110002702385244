<div class="flex flex-col w-full">
    <div class="p-4 md:p-6 xxl:p-8 w-full grow">
        <div class="w-full mt-4 md:mt-0 flex flex-col">
            <div class="flex justify-between">
                <div class="text-2xl font-bold tracking-tight">
                    {{ "microactions.title" | transloco }}
                </div>

                <div class="flex gap-5">
                    <mat-checkbox
                        (change)="onlyMineChanged($event)"
                        color="warn"
                        class="bg-white rounded-lg"
                    >
                        <span class="font-semibold">{{
                            "microactions.only-mine" | transloco
                        }}</span>
                    </mat-checkbox>

                    <bslk-tag-button
                        (onClick)="openCreateMicroActionDialog()"
                        [icon]="'heroicons_outline:plus'"
                        >{{ "microactions.new" | transloco }}</bslk-tag-button
                    >
                </div>
            </div>
            <div class="mt-6">
                <!-- If no micro-actions -->
                <micro-action-grid-view
                    [microActions]="microActions$ | async"
                    [isLoading]="isLoading$ | async"
                ></micro-action-grid-view>

                <div
                    class="flex flex-col w-full text-center"
                    *ngIf="(totalCount$ | async) === 0"
                >
                    <span class="font-bold text-2xl">{{
                        "microactions.no-micro-action-title" | transloco
                    }}</span>
                    <span class="mt-1">{{
                        "microactions.no-micro-action-description" | transloco
                    }}</span>
                    <mat-icon
                        class="icon-size-8 mt-3 m-auto"
                        [svgIcon]="'heroicons_outline:face-frown'"
                    ></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <mat-paginator
        class="bg-white w-full"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>
