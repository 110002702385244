<mission-row-card [onWhiteBackground]="onWhiteBackground" [mission]="mission">
    <div class="p-3 flex flex-col">
        <div
            class="font-bold flex items-center justify-center text-2xl text-warn"
        >
            <mat-icon class="mr-1 text-warn" [svgIcon]="'heroicons_solid:bolt'">
            </mat-icon>
            {{ "missions.card.expected-impact" | transloco }}
        </div>
        <span class="font-bold">{{ mission.expectedImpact }}</span>
    </div>
    <div footer class="flex flex-col overflow-y-scroll">
        <div class="mt-2 ml-4">
            {{
                (mission.isDateSelectionVolunteerBased
                    ? "missions.card.dates-tbd"
                    : "missions.card.available-slots"
                ) | transloco
            }}
        </div>
        <div class="flex flex-wrap">
            <mat-accordion
                *ngIf="availableSlots.length > 1; else oneSlot"
                class="w-full"
            >
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header class="h-fit px-4 py-2">
                        <mat-panel-description class="flex justify-between">
                            <span class="text-warn">{{
                                formatDateRange(
                                    availableSlots[0]?.startDate,
                                    availableSlots[0]?.endDate
                                )
                            }}</span>
                            <span class="font-normal">
                                + {{ availableSlots.length - 1 }}
                                {{ "missions.card.dates" | transloco }}</span
                            >
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="list-none">
                        <li *ngFor="let slot of otherAvailableSlots">
                            <span class="text-warn font-bold">{{
                                formatDateRange(slot.startDate, slot.endDate)
                            }}</span>
                        </li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
            <ng-template #oneSlot>
                <div class="px-4 py-2 text-warn font-bold">
                    <span *ngIf="!mission.isDateSelectionVolunteerBased">
                        {{
                            formatDateRange(
                                availableSlots[0]?.startDate,
                                availableSlots[0]?.endDate
                            )
                        }}
                    </span>
                    <span *ngIf="mission.isDateSelectionVolunteerBased">
                        {{ "enums.EDuration." + mission.duration | transloco }}
                    </span>
                </div>
            </ng-template>
        </div>
    </div>

    <div actions>
        <div
            *ngIf="!mission?.isTeamBuilding"
            class="mt-auto overflow-y-scroll flex flex-wrap gap-x-2 gap-y-1"
        >
            <bslk-tag
                [background]="'tertiary'"
                [size]="'small'"
                *ngFor="let skill of mission.skills"
                >{{ "enums.ESkill." + skill | transloco }}</bslk-tag
            >
        </div>
        <div
            *ngIf="mission?.isTeamBuilding"
            class="my-auto overflow-y-scroll flex flex-wrap gap-x-2 gap-y-1"
        >
            <bslk-tag
                [size]="'small'"
                [background]="'primary-light'"
                [textColor]="'primary'"
                [icon]="'heroicons_outline:user-group'"
                >{{ "missions.card.team-building" | transloco }}</bslk-tag
            >
            <bslk-tag
                [size]="'small'"
                [background]="'warn-light'"
                [textColor]="'warn'"
                [icon]="'heroicons_outline:banknotes'"
            >
                <span *ngIf="mission.isPaidTeamBuilding">
                    {{ mission.paidTeamBuildingPricePerPerson
                    }}{{
                        "enums.ECurrency." + mission.paidTeamBuildingCurrency
                            | transloco
                    }}/{{ "missions.card.pers" | transloco }}</span
                >
                <span *ngIf="!mission.isPaidTeamBuilding">{{
                    "missions.card.free" | transloco
                }}</span>
            </bslk-tag>
        </div>
    </div>
</mission-row-card>
