import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { CompanyCsrApproachSelectors } from 'app/store/company/csr-approach/csr-approach.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject } from 'rxjs';
import { CompanyCsrApproachDescriptionComponent } from './description/description.component';
import { CompanyCsrApproachMatrixComponent } from './matrix/matrix.component';
import { CompanyCsrApproachMetricsComponent } from './metrics/metrics.component';
import { CompanyCsrApproachPillarsComponent } from './pillars/pillars.component';
import { CompanyCsrApproachTimelineComponent } from './timeline/timeline.component';
import { CompanyCsrApproachValuesComponent } from './values/values.component';

@Component({
    selector: 'company-csr-approach',
    templateUrl: './csr-approach.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        CompanyCsrApproachTimelineComponent,
        CompanyCsrApproachMetricsComponent,
        CompanyCsrApproachValuesComponent,
        CompanyCsrApproachMatrixComponent,
        CompanyCsrApproachPillarsComponent,
        CompanyCsrApproachDescriptionComponent,
        LetDirective,
        TranslocoModule,
    ],
})
export class CompanyCsrApproachComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(CompanyCsrApproachSelectors.selectIsLoading);
    isLoadingUpdate$ = this.store.select(
        CompanyCsrApproachSelectors.selectIsLoadingUpdate
    );
    csrApproach$ = this.store.select(
        CompanyCsrApproachSelectors.selectCsrApproach
    );
    isCompanyAdmin$ = this.store.select(UserSelectors.selectIsCompanyAdmin);

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    ngOnInit(): void {
        this.store.dispatch(CompanyCsrApproachActions.loadCsrApproachRequest());
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
