import { createSelector } from '@ngrx/store';
import {
    EDonationCampaignState,
    EDonationCampaignWhitelistType,
    ProjectViewModel,
} from 'app/api';
import { EExternalUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { AppState } from 'app/store';
import { environment } from 'environments/environment';
import { isNil } from 'lodash-es';
import { DonationCampaignListSelectors } from '../donation-campaign-list/donation-campaign-list.selectors';
import { donationCampaignRequestsDisplayedStep } from './donation-campaign.reducers';

const selectState = (state: AppState) => state.donationCampaign;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsLoadingUpdate = createSelector(
    selectState,
    (state) => state.isLoadingUpdate
);

const selectIsLoadingContactMe = createSelector(
    selectState,
    (state) => state.isLoadingContactMe
);

const selectIsLoadingShare = createSelector(
    selectState,
    (state) => state.isLoadingShare
);

const selecHasAskedToBeContacted = createSelector(
    selectState,
    (state) => state.hasAskedToBeContacted
);

const selectDonationCampaign = createSelector(
    selectState,
    (state) => state.donationCampaign
);

const selectIsWaitingForTieSettlement = createSelector(
    selectDonationCampaign,
    (campaign) => campaign.isWaitingForTieSettlement
);

const selectWinningProjectId = createSelector(
    selectDonationCampaign,
    (campaign) => campaign.envelopeWinningProjectId
);

const selectTiedWinningProjectIds = createSelector(
    selectDonationCampaign,
    (campaign) => campaign.tiedWinningProjectIds
);

const selectChosenProject = createSelector(
    selectState,
    (state) => state.chosenProject
);

const selectEmail = createSelector(selectState, (state) => state.email);

const selectHasChosenProject = createSelector(
    selectState,
    (state) => state.hasChosenProject
);

const selectHasShared = createSelector(selectState, (state) => state.hasShared);

const selectHasFailedToShare = createSelector(
    selectState,
    (state) => state.hasFailedToShare
);

const selectIsLoadingDonationRequest = createSelector(
    selectState,
    (state) => state.isLoadingDonationRequest
);

export type ProjectRequestsRepartition = {
    project: ProjectViewModel;
    requestsCount: number;
    percentage: number;
};

const selectRequestsRepartition = createSelector(selectState, (state) => {
    const splits: ProjectRequestsRepartition[] = [];

    if (!state.donationCampaign || !state.donationCampaign.requests) {
        return splits;
    }

    const { requests, projects } = state.donationCampaign;

    const projectRequestsMap: Record<
        number,
        { count: number; project: ProjectViewModel }
    > = {};

    requests.forEach((request) => {
        const projectId = request.projectId;
        if (projectId) {
            if (!projectRequestsMap[projectId]) {
                const project = projects?.find((p) => p.id === projectId);
                if (project) {
                    projectRequestsMap[projectId] = { count: 0, project };
                }
            }
            if (projectRequestsMap[projectId]) {
                projectRequestsMap[projectId].count++;
            }
        }
    });

    const totalRequests = requests.length;

    projects?.forEach((project) => {
        const projectId = project.id;
        const count = projectRequestsMap[projectId]?.count || 0;
        const percentage =
            totalRequests > 0 ? Math.round((count / totalRequests) * 100) : 0;
        splits.push({
            project,
            requestsCount: count,
            percentage,
        });
    });

    return splits;
});

const selectDisplayedRequests = createSelector(selectState, (state) =>
    state.donationCampaign?.requests.slice(0, state.requestsDisplayed)
);

const selectCanDisplayMoreRequests = createSelector(selectState, (state) => {
    return state.donationCampaign?.requests.length > state.requestsDisplayed;
});

const selectCanDisplayLessRequests = createSelector(selectState, (state) => {
    return state.requestsDisplayed > donationCampaignRequestsDisplayedStep;
});

const selectRequestsCount = createSelector(
    selectState,
    (state) => state.donationCampaign?.requests.length
);

const selectWhitelistedCodes = createSelector(
    selectState,
    (state) => state.donationCampaign?.whitelistedCodes
);

const selectDonationRequestId = createSelector(
    selectState,
    (state) => state.donationRequest?.id
);

const selectHasDonationRequestId = createSelector(
    selectDonationRequestId,
    (donationRequestId) => !isNil(donationRequestId)
);

const selectDonationRequest = createSelector(
    selectState,
    (state) => state.donationRequest
);

const selectIsDonationRequestLoaded = createSelector(
    selectDonationRequest,
    (donationRequest) => !isNil(donationRequest)
);

const selectDisplayWhitelistedCodes = createSelector(
    selectState,
    (state) =>
        state.donationCampaign?.whitelistType ==
        EDonationCampaignWhitelistType.Code
);

const selectUpdateError = createSelector(
    selectState,
    (state) => state.updateError
);

const selectIsCampaignInFinished = createSelector(
    selectState,
    (state) =>
        state.donationCampaign?.state === EDonationCampaignState.Completed ||
        state.donationCampaign?.state === EDonationCampaignState.Expired
);

const selectIsCampaignIsNotPaid = createSelector(
    selectState,
    (state) => !state.donationCampaign?.isPaid
);

const selectChosenProjectError = createSelector(
    selectState,
    (state) => state.chosenProjectError
);

const selectIsCreatingLoading = createSelector(
    DonationCampaignListSelectors.selectIsLoading,
    selectState,
    (isLoading, state) => isLoading || state.isLoadingUpdate
);

const selectDonationGiftUrl = createSelector(selectState, (state) => {
    return (
        environment.redirectUri +
        UrlHelpers.getExternalUrl(EExternalUrl.DonationGift, {
            id: state.donationCampaign?.accessToken.toString(),
        })
    );
});

const selectDonationGiftPreviewUrl = createSelector(selectState, (state) => {
    return (
        environment.redirectUri +
        UrlHelpers.getExternalUrl(EExternalUrl.DonationGiftPreview, {
            id: state.donationCampaign?.accessToken.toString(),
        })
    );
});

const selectChosenProjectContribution = createSelector(
    selectChosenProject,
    selectDonationCampaign,
    (chosenProject, donationCampaign) => {
        if (!chosenProject || !donationCampaign) return null;

        const { baseContributionAmount, impactUnitQuantity } = chosenProject;
        const { donationAmount } = donationCampaign;

        return Math.ceil(
            (donationAmount / baseContributionAmount) * impactUnitQuantity
        );
    }
);
export const DonationCampaignSelectors = {
    selecHasAskedToBeContacted,
    selectCanDisplayLessRequests,
    selectCanDisplayMoreRequests,
    selectChosenProject,
    selectChosenProjectContribution,
    selectChosenProjectError,
    selectDisplayedRequests,
    selectDisplayWhitelistedCodes,
    selectDonationCampaign,
    selectDonationGiftPreviewUrl,
    selectDonationGiftUrl,
    selectDonationRequest,
    selectDonationRequestId,
    selectEmail,
    selectHasChosenProject,
    selectHasDonationRequestId,
    selectHasFailedToShare,
    selectHasShared,
    selectIsCampaignInFinished,
    selectIsCampaignIsNotPaid,
    selectIsCreatingLoading,
    selectIsDonationRequestLoaded,
    selectIsLoading,
    selectIsLoadingContactMe,
    selectIsLoadingDonationRequest,
    selectIsLoadingShare,
    selectIsLoadingUpdate,
    selectIsWaitingForTieSettlement,
    selectRequestsCount,
    selectRequestsRepartition,
    selectTiedWinningProjectIds,
    selectUpdateError,
    selectWhitelistedCodes,
    selectWinningProjectId,
};
