import { NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'company-csr-approach-card',
    templateUrl: './card.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        BslkTagComponent,
        NgIf,
        TranslocoModule,
        NgxSkeletonLoaderModule,
    ],
})
export class CompanyCsrApproachCardComponent {
    @Input() title: string;
    @Input() isLoading: boolean;
    @Input() isLoadingUpdate: boolean;
    @Input() isValidateDisabled: boolean;
    @Input() isHidden: boolean = false;
    @Input() isEditing: boolean = false;
    @Input() isCompanyAdmin = false;
    @Input() forceWhiteBackground = false;
    @Input() group: FormGroup;

    @Output() editing: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() hiding: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public elementRef: ElementRef) {}

    get hasUnsavedChanges(): boolean {
        return this.isEditing && this.group?.dirty;
    }

    setIsEditing(isEditing: boolean) {
        this.editing.emit(isEditing);
    }

    setIsHiding(isHiding: boolean) {
        this.hiding.emit(isHiding);
    }
}
