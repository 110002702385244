import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachViewModel, CompanyPillarViewModel } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { CompanyCsrApproachSelectors } from 'app/store/company/csr-approach/csr-approach.selectors';
import { Subject, takeUntil } from 'rxjs';
import { CompanyCsrApproachCardComponent } from '../card/card.component';
import { CompanyCsrApproachEditPillarComponent } from './edit/edit.component';
import { CompanyCsrApproachViewPillarComponent } from './view/view.component';

@Component({
    selector: 'company-csr-approach-pillars',
    templateUrl: './pillars.component.html',
    standalone: true,
    imports: [
        BslkIconButtonComponent,
        CompanyCsrApproachCardComponent,
        CompanyCsrApproachEditPillarComponent,
        CompanyCsrApproachViewPillarComponent,
        MatIconModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class CompanyCsrApproachPillarsComponent
    implements OnInit, OnDestroy, OnChanges
{
    @Input() isLoading: boolean;
    @Input() isLoadingUpdate: boolean;
    @Input() isCompanyAdmin = false;
    @Input() csrApproach: CompanyCsrApproachViewModel;

    unsubscribeAll: Subject<any> = new Subject<any>();

    isCreating = false;
    isEditing = false;
    editedPillarIndex: number;
    viewedPillar: CompanyPillarViewModel;
    viewedPillarColor: BslkColor;

    pillarsPage = 0;
    pillarsPerPage = 4;

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService,
        private fileUploadDialogService: FileUploadDialogService,
        private mediaWatcherService: FuseMediaWatcherService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get hasNextPage() {
        return (
            !this.isEditing &&
            this.csrApproach?.pillars &&
            this.csrApproach.pillars.length >
                this.pillarsPerPage * (this.pillarsPage + 1)
        );
    }

    get hasPreviousPage() {
        return !this.isEditing && this.pillarsPage > 0;
    }

    get displayedPillars() {
        return this.isEditing
            ? this.csrApproach?.pillars
            : this.csrApproach?.pillars.slice(
                  this.pillarsPage * this.pillarsPerPage,
                  (this.pillarsPage + 1) * this.pillarsPerPage
              );
    }

    ngOnInit(): void {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.pillarsPage = 0;
                this.pillarsPerPage =
                    this.mediaWatcherService.responsiveMapValue(
                        matchingAliases,
                        {
                            sm: 1,
                            md: 2,
                            lg: 3,
                            xl: 4,
                        },
                        1
                    );
            });
    }

    ngOnChanges(): void {
        this.isEditing =
            this.isEditing ||
            (this.isCompanyAdmin && this.csrApproach?.pillars.length === 0);
    }

    addPillar() {
        this.isCreating = true;
    }

    editDrawerClosed() {
        this.isCreating = false;
        this.editedPillarIndex = null;
    }

    viewDrawerClosed() {
        this.viewedPillar = null;
    }

    onEdit(isEditing: boolean) {
        this.isEditing = isEditing;
        this.pillarsPage = 0;
    }

    onHiding(isHiding: boolean) {
        this.formGroup.controls.hidePillars.setValue(isHiding);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    viewPillar(pillar: CompanyPillarViewModel, index: number) {
        this.viewedPillar = pillar;
        if (index % 4 === 0 || index % 4 === 3) {
            this.viewedPillarColor = 'primary';
        } else if (index % 4 === 1) {
            this.viewedPillarColor = 'warn';
        } else {
            this.viewedPillarColor = 'tertiary';
        }
    }

    editPillar(event: MouseEvent, index: number) {
        this.editedPillarIndex = index;
        event.stopPropagation();
    }

    editPillarImage(event: MouseEvent, id: number) {
        this.fileUploadDialogService.open({
            action: CompanyCsrApproachActions.updatePillarImageRequest,
            isLoading$: this.store.select(
                CompanyCsrApproachSelectors.selectIsLoadingImageUpload
            ),
            id,
            maxFileSize: 2000 * 1024,
            infoTextKey: 'csrapproach.pillar-image-info',
        });
        event.stopPropagation();
    }

    removePillar(event: MouseEvent, index: number) {
        const formArray = this.formGroup.controls.pillars as FormArray;
        formArray.removeAt(index);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
        event.stopPropagation();
    }

    nextPage() {
        this.pillarsPage += 1;
    }

    previousPage() {
        this.pillarsPage -= 1;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
