<div
    class="flex max-w-[95vw] relative flex-col min-w-[30vw] max-h-[100vh] -m-6"
    [class.lg:max-w-[60vw]]="size === 'small'"
    [class.xl:max-w-[40vw]]="size === 'small'"
    [class.xxl:max-w-[30vw]]="size === 'small'"
    [class.lg:max-w-[70vw]]="size === 'medium'"
    [class.xl:max-w-[60vw]]="size === 'medium'"
    [class.xxl:max-w-[50vw]]="size === 'medium'"
    [class.lg:max-w-[80vw]]="size === 'large'"
    [class.xl:max-w-[75vw]]="size === 'large'"
    [class.xxl:max-w-[70vw]]="size === 'large'"
>
    <!-- Header -->
    <div
        *ngIf="showHeader"
        [class.bg-primary]="color === 'primary'"
        [class.bg-tertiary]="color === 'tertiary'"
        [class.bg-warn]="color === 'warn'"
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 text-white"
    >
        <div class="text-lg font-medium">{{ title }}</div>
        <button
            class="flex z-10"
            (click)="close()"
            mat-icon-button
            [tabIndex]="-1"
        >
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x-mark'"
            ></mat-icon>
        </button>
    </div>

    <!-- Body -->
    <div
        [ngClass]="{
            'p-0': hideBodyPadding,
            'p-2 md:p-4 lg:p-6': !hideBodyPadding && !scrollablePadding,
            'py-2 pl-2 md:py-4 md:pl-4 md:pr-2 lg:px-6 lg:pl-6 lg:pr-4':
                scrollablePadding
        }"
        class="flex flex-col items-center justify-center"
    >
        <ng-content select="[body]"></ng-content>
    </div>

    <!-- Actions -->
    <div *ngIf="showActions" class="flex p-4 bg-gray-50 justify-end">
        <ng-content select="[actions]"></ng-content>
    </div>

    <!-- Fireworks effect -->
    <div
        *ngIf="showFireworks"
        class="absolute w-full h-full overflow-hidden pointer-events-none pyro"
    >
        <div class="before"></div>
        <div class="after"></div>
    </div>
</div>
