<base-dialog
    *ngrxLet="hasContactedMatch$ as hasContactedMatch"
    (onClose)="close()"
    [title]="'findvolunteers.contact-match-dialog.title' | transloco"
    [showActions]="true"
    [size]="'small'"
>
    <div
        *ngIf="!hasContactedMatch"
        class="w-full h-full flex flex-col justify-center"
        body
    >
        <fuse-alert type="info" class="mb-5">
            {{ "findvolunteers.contact-match-dialog.message-info" | transloco }}
        </fuse-alert>

        <mat-form-field [subscriptSizing]="'dynamic'">
            <mat-label>{{
                "findvolunteers.contact-match-dialog.message" | transloco
            }}</mat-label>
            <textarea
                class="min-h-26"
                [placeholder]="
                    'findvolunteers.contact-match-dialog.message-placeholder'
                        | transloco
                "
                matInput
                [(ngModel)]="message"
            ></textarea>
        </mat-form-field>
    </div>
    <div
        *ngIf="hasContactedMatch"
        class="w-full h-full flex flex-col justify-center"
        body
    >
        <div class="text-center p-2">
            <div class="text-2xl font-bold">
                {{
                    "findvolunteers.contact-match-dialog.message-sent-title"
                        | transloco
                }}
                🎉
            </div>
            <div class="text-xl">
                <span
                    >{{
                        "findvolunteers.contact-match-dialog.message-sent-explication"
                            | transloco
                    }}
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="!hasContactedMatch" class="flex justify-end" actions>
        <bslk-classic-button
            (click)="send()"
            [isDisabled]="isMessageEmpty"
            [icon]="'heroicons_outline:envelope'"
            [isLoading]="isLoadingContact$ | async"
            >{{ "shared.common.send" | transloco }}</bslk-classic-button
        >
    </div>
    <div *ngIf="hasContactedMatch" class="flex gap-3 justify-end" actions>
        <bslk-classic-button
            (click)="seeChat()"
            [icon]="'heroicons_outline:chat-bubble-oval-left'"
            [isDisabled]="isLoadingChats$ | async"
            >{{
                "findvolunteers.contact-match-dialog.see-chat" | transloco
            }}</bslk-classic-button
        >
        <bslk-classic-button
            (click)="close()"
            [icon]="'heroicons_outline:user-group'"
            >{{
                "findvolunteers.contact-match-dialog.continue" | transloco
            }}</bslk-classic-button
        >
    </div>
</base-dialog>
