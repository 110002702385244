import { NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { isNil, TranslocoModule } from '@ngneat/transloco';
import { CompanyMatrixPointViewModel } from 'app/api';
import { MicroActionGridViewComponent } from 'app/modules/regular/micro-action/micro-action-list/micro-action-grid-view/micro-action-grid-view.component';
import { SanitizePipe } from 'app/shared/pipes/sanitize.pipe';

@Component({
    selector: 'company-csr-approach-view-matrix-point',
    templateUrl: './view-matrix-point.component.html',
    standalone: true,
    imports: [
        FuseDrawerComponent,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MicroActionGridViewComponent,
        NgIf,
        ReactiveFormsModule,
        SanitizePipe,
        TranslocoModule,
    ],
})
export class CompanyCsrApproachViewMatrixPointComponent implements OnInit {
    @ViewChild('viewMatrixPointDrawer') drawer: FuseDrawerComponent;

    @Input() point: CompanyMatrixPointViewModel;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNil(changes.point?.currentValue)) {
            this.drawer.open();
        }
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            this.close.emit();
        }
    }
}
