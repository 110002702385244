import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isEmpty } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'unsubscribe',
    templateUrl: './unsubscribe.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        FormsModule,
        MatInputModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        BslkClassicButtonComponent,
        NgIf,
        TranslocoModule,
        LetDirective,
    ],
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();
    email: string;

    isLoading$ = this.store.select(UserSelectors.selectIsLoadingUnsubscribe);
    hasUnsubscribed$ = this.store.select(UserSelectors.selectHasUnsubscribed);

    constructor(private route: ActivatedRoute, private store: Store) {}

    get isEmailEmpty() {
        return isEmpty(this.email);
    }

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                this.email = params['email'];
            });
    }

    submit() {
        if (!this.isEmailEmpty) {
            this.store.dispatch(
                UserActions.unsubscribeRequest({ email: this.email })
            );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
