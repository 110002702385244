import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MissionUnregisteredViewModel } from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { MissionRowCardComponent } from '../mission-row-card.component';

@Component({
    selector: 'mission-unregistered-row-card',
    standalone: true,
    templateUrl: './mission-unregistered-row-card.component.html',
    imports: [
        RouterLink,
        BslkTagComponent,
        MatIconModule,
        MatButtonModule,
        TextFieldModule,
        MatDividerModule,
        MatTooltipModule,
        NgClass,
        MatChipsModule,
        AsyncPipe,
        NgIf,
        NgFor,
        TranslocoModule,
        NgTemplateOutlet,
        DatePipe,
        MatExpansionModule,
        MissionRowCardComponent,
    ],
})
export class MissionUnregisterdRowCardComponent {
    @Input() mission: MissionUnregisteredViewModel;
    @Input() onWhiteBackground = false;

    constructor(private datePipe: DatePipe, private router: Router) {}

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    get availableSlots() {
        return this.mission.missionSlots.filter(
            (s) => s.availableSpotsLeft > 0
        );
    }

    get otherAvailableSlots() {
        return this.availableSlots.slice(1);
    }
}
