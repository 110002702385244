import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BslkBusinessSdgPickerComponent } from '@bslk-business/components/form/sdg-picker/sdg-picker.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { EActivityField, EMicroActionType, LocationViewModel } from 'app/api';
import { LocationSelectionComponent } from 'app/shared/components/location-selection/location-selection.component';
import { MicroActionFormService } from 'app/shared/services/form/micro-action-form.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'create-micro-action-step-1',
    standalone: true,
    templateUrl: './create-micro-action-step-1.component.html',
    imports: [
        AsyncPipe,
        BslkBusinessSdgPickerComponent,
        BslkClassicToggleComponent,
        BslkInfoLabelComponent,
        BslkLengthInfoComponent,
        BslkAdvancedSelectSyncComponent,
        LocationSelectionComponent,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CreateMicroActionStep1Component implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    EActivityField = EActivityField;
    EMicroActionType = EMicroActionType;
    quillModules = QUILL_MODULES;

    activityFieldsTranslationsLoaded: boolean = false;
    activityFieldsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    companyHasAgencies$ = this.store.select(
        UserSelectors.selectCompanyHasAgencies
    );
    companyAgencies$ = this.store.select(UserSelectors.selectCompanyAgencies);

    constructor(
        private translationService: TranslationService,
        private store: Store,
        private microActionFormService: MicroActionFormService
    ) {}

    get formGroup() {
        return (
            this.microActionFormService.getFormGroup ??
            this.microActionFormService.initForm()
        );
    }

    get locationsFormArray() {
        return this.formGroup.controls.locations as FormArray;
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsAdvancedSelectItems = translatedItems;
                this.activityFieldsTranslationsLoaded = true;
            });
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    onLocationSelected(location: LocationViewModel) {
        this.locationsFormArray.push(
            this.microActionFormService.createLocation(location)
        );
    }

    removeActionZone(locationFormGroup: FormGroup) {
        this.locationsFormArray.removeAt(
            this.locationsFormArray.controls.indexOf(locationFormGroup)
        );
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
