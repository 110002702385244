import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyAdminDashboardService, ExportService } from 'app/api';
import { getCompanyAdminDashboardConfigFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isNil, merge } from 'lodash-es';
import { catchError, filter, map, mergeMap, Observable, of, tap } from 'rxjs';
import { CompanyAdminDashboardActions } from './company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from './company-admin-dashboard.selectors';
import {
    CompanyAdminDashboardConfiguration,
    CompanyAdminDashboardTabConfiguration,
    CompanyAdminKpi,
    companyAdminKpiLinkedFeature,
    CompanyAdminKpiResponse,
    CompanyAdminTab,
} from './company-admin-dashboard.state';

@Injectable()
export class CompanyAdminDashboardEffects {
    initConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAdminDashboardActions.initDashboard),
            concatLatestFrom(() => [
                this.store.select(
                    CompanyAdminDashboardSelectors.selectConfiguration
                ),
                this.store.select(UserSelectors.selectAllowedFeatures),
            ]),
            filter(
                () => !isNil(getCompanyAdminDashboardConfigFromLocalStorage())
            ),
            map(([, initialConfiguration, allowedFeatures]) => {
                const config = getCompanyAdminDashboardConfigFromLocalStorage();
                // Merge config with local storage config
                const mergedConfig = merge({}, initialConfiguration, config);
                // Filter to keep only keys present in initial config (to prevent save of old removed kpis)
                let filteredConfig: CompanyAdminDashboardConfiguration =
                    this.filterConfigByInitialKeys(
                        mergedConfig,
                        initialConfiguration
                    );
                // Override and set to false all kpis that are not linked with allowed features
                filteredConfig = Object.keys(filteredConfig).reduce(
                    (acc, tab) => {
                        const tabConfig =
                            filteredConfig[tab as CompanyAdminTab];
                        if (tabConfig) {
                            acc[tab as CompanyAdminTab] = Object.keys(
                                tabConfig
                            ).reduce((tabAcc, kpi) => {
                                const kpiKey = kpi as CompanyAdminKpi;
                                const feature =
                                    companyAdminKpiLinkedFeature[kpiKey];
                                // Set KPI to false if the feature is not allowed
                                tabAcc[kpiKey] = allowedFeatures.includes(
                                    feature
                                )
                                    ? tabConfig[kpiKey]
                                    : false;
                                return tabAcc;
                            }, {} as CompanyAdminDashboardTabConfiguration);
                        }
                        return acc;
                    },
                    {} as CompanyAdminDashboardConfiguration
                );

                return CompanyAdminDashboardActions.updateConfiguration({
                    config: filteredConfig,
                });
            })
        )
    );

    checkIfKpiIsLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAdminDashboardActions.checkIfKpiIsLoaded),
            concatLatestFrom(({ kpi }) =>
                this.store.select(
                    CompanyAdminDashboardSelectors.selectIsKpiResultReady(kpi)
                )
            ),
            filter(([, isKpiResultReady]) => !isKpiResultReady),
            map(([{ kpi }]) =>
                CompanyAdminDashboardActions.loadKpiRequest({
                    kpi,
                })
            )
        )
    );

    triggerLoadOnFilterUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAdminDashboardActions.updateKpiFilters),
            map(({ kpi }) =>
                CompanyAdminDashboardActions.loadKpiRequest({
                    kpi,
                })
            )
        )
    );

    scrollTopOnExpand$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CompanyAdminDashboardActions.expandKpi),
                tap(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
            ),
        { dispatch: false }
    );

    loadKpi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAdminDashboardActions.loadKpiRequest),
            concatLatestFrom(({ kpi }) =>
                this.store.select(
                    CompanyAdminDashboardSelectors.selectKpiFilters(kpi)
                )
            ),
            mergeMap(([{ kpi }, filters]) => {
                const apiCall = this.getKpiApiCall(kpi);
                const boundApiCall = apiCall.bind(this.companyAdminapiService);
                const apiObservable = boundApiCall(
                    filters
                ) as Observable<CompanyAdminKpiResponse>;
                return apiObservable.pipe(
                    map((response) =>
                        CompanyAdminDashboardActions.loadKpiRequestSuccess({
                            kpi,
                            response,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            CompanyAdminDashboardActions.loadKpiRequestFail({
                                kpi,
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    exportData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAdminDashboardActions.exportDataRequest),
            mergeMap(({ config }) => {
                return this.exportApiService
                    .exportCompanyGet(config, 'response')
                    .pipe(
                        map((response) => {
                            const contentDisposition = response.headers.get(
                                'Content-Disposition'
                            );
                            let filename = 'exported_data.csv'; // Default filename
                            if (contentDisposition) {
                                const matches =
                                    /filename[^;=\n]*=(['"]?)([^'";\n]*)\1/.exec(
                                        contentDisposition
                                    );
                                if (matches != null && matches[2]) {
                                    filename = matches[2];
                                }
                            }
                            return CompanyAdminDashboardActions.exportDataRequestSuccess(
                                {
                                    blob: response.body,
                                    filename,
                                }
                            );
                        }),
                        catchError((error) =>
                            of(
                                CompanyAdminDashboardActions.exportDataRequestFail(
                                    {
                                        error: error?.error ?? error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    triggerDownload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CompanyAdminDashboardActions.exportDataRequestSuccess),
                tap(({ blob, filename }) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                })
            ),
        { dispatch: false }
    );

    getKpiApiCall(kpi: CompanyAdminKpi) {
        switch (kpi) {
            case 'participation-rate':
                return this.companyAdminapiService
                    .companyAdminDashboardParticipationRateGet;
            case 'gender-participation-repartition':
                return this.companyAdminapiService
                    .companyAdminDashboardGenderParticipationRepartitionGet;
            case 'satisfaction-rate':
                return this.companyAdminapiService
                    .companyAdminDashboardSatisfactionRateGet;
            case 'tracking-my-impact':
            case 'missions-by-goals':
                return this.companyAdminapiService
                    .companyAdminDashboardMissionKpisBySdgGet;
            case 'employees-on-assignment':
                return this.companyAdminapiService
                    .companyAdminDashboardEmployeesOnAssignementGet;
            case 'all-company-missions':
                return this.companyAdminapiService
                    .companyAdminDashboardAllCompanyMissionsGet;
            case 'awareness-rate':
                return this.companyAdminapiService
                    .companyAdminDashboardAwarenessRateGet;
            case 'awareness-progression':
                return this.companyAdminapiService
                    .companyAdminDashboardAwarenessProgressionGet;
            case 'dual-quiz-answers-by-sdg':
                return this.companyAdminapiService
                    .companyAdminDashboardDualQuizAnswersBySdgGet;
            case 'cumulative-spending':
                return this.companyAdminapiService
                    .companyAdminDashboardCumulativeSpendingGet;
            case 'donation-campaigns-impact':
                return this.companyAdminapiService
                    .companyAdminDashboardDonationCampaignKpisBySdgGet;
            default:
                throw Error(`KPI ${kpi} not supported`);
        }
    }

    filterConfigByInitialKeys(
        config: CompanyAdminDashboardConfiguration,
        initialConfiguration: CompanyAdminDashboardConfiguration
    ): CompanyAdminDashboardConfiguration {
        return Object.keys(config).reduce((acc, key) => {
            if (initialConfiguration.hasOwnProperty(key)) {
                if (
                    typeof config[key] === 'object' &&
                    config[key] !== null &&
                    !Array.isArray(config[key])
                ) {
                    acc[key] = this.filterConfigByInitialKeys(
                        config[key],
                        initialConfiguration[key]
                    );
                } else {
                    acc[key] = config[key];
                }
            }
            return acc;
        }, {}) as CompanyAdminDashboardConfiguration;
    }

    constructor(
        private actions$: Actions,
        private store: Store,
        private companyAdminapiService: CompanyAdminDashboardService,
        private exportApiService: ExportService
    ) {}
}
