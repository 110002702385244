import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkAnimatedNumberComponent } from '@bslk/components/animated-number/animated-number.component';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachViewModel, EUnit } from 'app/api';
import { FormatNumberPipe } from 'app/shared/pipes/format-number.pipe';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { Subject, takeUntil } from 'rxjs';
import { CompanyCsrApproachCardComponent } from '../card/card.component';
import { CompanyCsrApproachEditMetricComponent } from './edit/edit.component';

@Component({
    selector: 'company-csr-approach-metrics',
    templateUrl: './metrics.component.html',
    standalone: true,
    imports: [
        BslkAnimatedNumberComponent,
        CompanyCsrApproachCardComponent,
        TranslocoModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        FormatNumberPipe,
        NgClass,
        MatIconModule,
        MatButtonModule,
        CompanyCsrApproachEditMetricComponent,
        BslkIconButtonComponent,
    ],
})
export class CompanyCsrApproachMetricsComponent
    implements OnInit, OnDestroy, OnChanges
{
    @Input() isLoading: boolean;
    @Input() isLoadingUpdate: boolean;
    @Input() isCompanyAdmin = false;
    @Input() csrApproach: CompanyCsrApproachViewModel;

    EUnit = EUnit;

    unsubscribeAll: Subject<any> = new Subject<any>();

    isCreating = false;
    isEditing = false;
    editedMetricIndex: number;

    metricsPage = 0;
    metricsPerPage = 3;

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService,
        private mediaWatcherService: FuseMediaWatcherService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get hasNextPage() {
        return (
            !this.isEditing &&
            this.csrApproach?.metrics &&
            this.csrApproach.metrics.length >
                this.metricsPerPage * (this.metricsPage + 1)
        );
    }

    get hasPreviousPage() {
        return !this.isEditing && this.metricsPage > 0;
    }

    get displayedMetrics() {
        return this.isEditing
            ? this.csrApproach?.metrics
            : this.csrApproach?.metrics.slice(
                  this.metricsPage * this.metricsPerPage,
                  (this.metricsPage + 1) * this.metricsPerPage
              );
    }

    ngOnInit(): void {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.metricsPage = 0;
                this.metricsPerPage =
                    this.mediaWatcherService.responsiveMapValue(
                        matchingAliases,
                        {
                            sm: 1,
                            md: 2,
                            xl: 3,
                        },
                        1
                    );
            });
    }

    ngOnChanges(): void {
        this.isEditing =
            this.isEditing ||
            (this.isCompanyAdmin && this.csrApproach?.metrics.length === 0);
    }

    addMetric() {
        this.isCreating = true;
    }

    drawerClosed() {
        this.isCreating = false;
        this.editedMetricIndex = null;
    }

    onEdit(isEditing: boolean) {
        this.isEditing = isEditing;
        this.metricsPage = 0;
    }

    onHiding(isHiding: boolean) {
        this.formGroup.controls.hideMetrics.setValue(isHiding);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    editMetric(index: number) {
        this.editedMetricIndex = index;
    }

    removeMetric(index: number) {
        const formArray = this.formGroup.controls.metrics as FormArray;
        formArray.removeAt(index);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    nextPage() {
        this.metricsPage += 1;
    }

    previousPage() {
        this.metricsPage -= 1;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
