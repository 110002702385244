export enum EAssociationUrl {
    AssociationEditPage,
    AssociationViewPage,
    AssociationMissionsPage,
    AssociationMissionManagePage,
    AssociationFindVolunteersPage,
}

export enum ERegularUrl {
    RegularDashboardPage,
    RegularMissionPage,
    RegularMissionManagePage,
    RegularCoursesPage,
    RegularCourseChapterPage,
    RegularCourseUserReviewPage,
}

export enum ESharedUrl {
    SharedDualQuizChallengePage,
    SharedDualQuizChallengesPage,
    SharedDualQuizChallengeResultsPage,
    SharedMyFiles,
}

export enum ECompanyAdminUrl {
    CompanyAdminDonationCampaignPage,
    CompanyAdminDonationCampaignsPage,
}

export enum EAccountUrl {
    AccountSettings,
}

export enum EExternalUrl {
    DonationGift,
    DonationGiftPreview,
}

export type UrlArgs = {
    id?: number | string;
    missionSlotId?: number | string;
};

const getAssociationUrl = (urlType: EAssociationUrl, args?: UrlArgs) => {
    switch (urlType) {
        case EAssociationUrl.AssociationViewPage:
            return '/a/organization/view';
        case EAssociationUrl.AssociationEditPage:
            return '/a/organization/edit';
        case EAssociationUrl.AssociationMissionsPage:
            return '/a/missions';
        case EAssociationUrl.AssociationFindVolunteersPage:
            return '/a/find-volunteers';
        case EAssociationUrl.AssociationMissionManagePage:
            return (
                '/a/missions/' +
                args.id +
                '/manage' +
                (args.missionSlotId ? '/' + args.missionSlotId : '')
            );
    }
};

const getRegularUrl = (urlType: ERegularUrl, args?: UrlArgs) => {
    switch (urlType) {
        case ERegularUrl.RegularMissionPage:
            return '/missions/' + args.id;
        case ERegularUrl.RegularMissionManagePage:
            return '/missions/' + args.id + '/manage';
        case ERegularUrl.RegularDashboardPage:
            return '/dashboard';
        case ERegularUrl.RegularCoursesPage:
            return '/awareness/courses';
        case ERegularUrl.RegularCourseChapterPage:
            return '/awareness/courses/chapter/' + args.id;
        case ERegularUrl.RegularCourseUserReviewPage:
            return '/awareness/courses/review/' + args.id;
    }
};

const getSharedUrl = (urlType: ESharedUrl, args?: UrlArgs) => {
    switch (urlType) {
        case ESharedUrl.SharedDualQuizChallengesPage:
            return '/s/dual-quiz';
        case ESharedUrl.SharedDualQuizChallengePage:
            return '/s/dual-quiz/' + args.id;
        case ESharedUrl.SharedDualQuizChallengeResultsPage:
            return '/s/dual-quiz/' + args.id + '/results';
        case ESharedUrl.SharedMyFiles:
            return '/s/my-files' + (args?.id ? '/' + args.id : '');
    }
};

const getAccountUrl = (urlType: EAccountUrl) => {
    switch (urlType) {
        case EAccountUrl.AccountSettings:
            return '/s/settings';
    }
};

const getExternalUrl = (urlType: EExternalUrl, args?: UrlArgs) => {
    switch (urlType) {
        case EExternalUrl.DonationGift:
            return '/d/' + args.id;
        case EExternalUrl.DonationGiftPreview:
            return '/d-preview/' + args.id;
    }
};

const getCompanyAdminUrl = (urlType: ECompanyAdminUrl, args?: UrlArgs) => {
    switch (urlType) {
        case ECompanyAdminUrl.CompanyAdminDonationCampaignPage:
            return '/ca/donation-campaigns/' + args.id;
        case ECompanyAdminUrl.CompanyAdminDonationCampaignsPage:
            return '/ca/donation-campaigns';
    }
};

export const UrlHelpers = {
    getAssociationUrl,
    getAccountUrl,
    getRegularUrl,
    getSharedUrl,
    getExternalUrl,
    getCompanyAdminUrl,
};
