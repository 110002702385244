/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ECompanyMatrixPointType = 'Custom' | 'SustainableDevelopmentGoal';

export const ECompanyMatrixPointType = {
    Custom: 'Custom' as ECompanyMatrixPointType,
    SustainableDevelopmentGoal: 'SustainableDevelopmentGoal' as ECompanyMatrixPointType
};

