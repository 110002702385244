// dialog.service.ts

import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MicroActionFormService } from 'app/shared/services/form/micro-action-form.service';

@Injectable({ providedIn: 'root' })
export class CreateMicroActionValidationService {
    constructor(
        private microActionFormService: MicroActionFormService,
        private translocoService: TranslocoService
    ) {}

    get formGroup() {
        return this.microActionFormService.getFormGroup;
    }

    get isStep0Valid() {
        return this.getStep0Errors.length === 0;
    }

    get isStep1Valid() {
        return this.getStep1Errors.length === 0;
    }

    get isStep2Valid() {
        return this.getStep2Errors.length === 0;
    }

    isStepValid(stepIndex: number): boolean {
        switch (stepIndex) {
            case 0:
                return this.isStep0Valid;
            case 1:
                return this.isStep1Valid;
            case 2:
                return this.isStep2Valid;
            default:
                return false;
        }
    }

    getCurrentStepErrors(step: number): string[] {
        switch (step) {
            case 0:
                return this.getStep0Errors;
            case 1:
                return this.getStep1Errors;
            case 2:
                return this.getStep2Errors;
        }
    }

    get getStep0Errors(): string[] {
        const errors = [];
        // Clear validators set by the calendar with matDatepickerFilter
        // We don't want to set as invalid a slot previously created
        this.formGroup.controls.startDay.clearValidators();
        this.formGroup.controls.endDay.clearValidators();
        this.formGroup.controls.startDay.updateValueAndValidity();
        this.formGroup.controls.endDay.updateValueAndValidity();
        this.checkFields(
            [
                'name',
                'description',
                'startDate',
                'endDate',
                'activityFields',
                'type',
                'externalProvider',
            ],
            errors
        );

        if (
            this.formGroup.controls.hasMaximumSlots.value &&
            !this.formGroup.controls.maximumSlots.value
        ) {
            errors.push(
                this.translocoService.translate(
                    'createmicroaction.errors.maximum-slots'
                )
            );
        }

        if (this.formGroup.hasError('dateMismatch')) {
            errors.push(
                this.translocoService.translate(
                    'createmicroaction.errors.date-mismatch'
                )
            );
        }
        return errors;
    }

    get getStep1Errors(): string[] {
        const errors = [];

        this.checkFields(['impact', 'sustainableDevelopmentGoals'], errors);

        if (!this.formGroup.controls.isRemote.value) {
            this.checkFields(['location'], errors);
        }

        if (
            this.formGroup.controls.isAgencyRestricted.value &&
            !this.formGroup.controls.companyAgencyId.value
        ) {
            errors.push(
                this.translocoService.translate(
                    'createmicroaction.errors.company-agency-id'
                )
            );
        }

        return errors;
    }

    get getStep2Errors(): string[] {
        const errors = [];
        const formArray = this.formGroup.controls.activities as FormArray;
        formArray.controls.forEach((formGroup: FormGroup, index) => {
            this.pushErrorIfInvalid(
                'description',
                errors,
                'activities',
                formGroup,
                {
                    index: index + 1,
                }
            );
            this.pushErrorIfInvalid('name', errors, 'activities', formGroup, {
                index: index + 1,
            });
            this.pushErrorIfInvalid(
                'additionalInformation',
                errors,
                'activities',
                formGroup,
                { index: index + 1 }
            );
            this.pushErrorIfInvalid(
                'effortLevel',
                errors,
                'activities',
                formGroup,
                {
                    index: index + 1,
                }
            );
        });

        return errors;
    }

    checkFields(fieldsToCheck: string[], errors: string[]) {
        fieldsToCheck.forEach((field) => {
            this.pushErrorIfInvalid(field, errors);
        });
        return errors;
    }

    pushErrorIfInvalid(
        controlName: string,
        errors: string[],
        extraPrefix?: string,
        formGroup?: FormGroup,
        params?: any
    ) {
        const control = (formGroup ?? this.formGroup).controls[controlName];
        if (!control) {
            throw new Error('Control ' + controlName + '  not found');
        }

        if (control.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createmicroaction.errors.' +
                        (extraPrefix ? extraPrefix + '.' : '') +
                        controlName,
                    params
                )
            );
        }
    }
}
