import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MissionUserMatchService } from 'app/api';
import { ChatActions } from 'app/store/chat/chat.action';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { MissionUserMatchActions } from './mission-user-match.action';
import { MissionUserMatchSelectors } from './mission-user-match.selectors';

@Injectable()
export class MissionUserMatchEffects {
    loadPotentialMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionUserMatchActions.loadPotentialMatchesRequest),
            exhaustMap(({ missionId }) => {
                return this.missionUserMatchService
                    .missionUserMatchIdPotentialUserMatchesGet({
                        id: missionId,
                    })
                    .pipe(
                        map((potentialMatches) =>
                            MissionUserMatchActions.loadPotentialMatchesRequestSuccess(
                                {
                                    potentialMatches,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionUserMatchActions.loadPotentialMatchesRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    contactPotentialMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionUserMatchActions.contactPotentialMatcheRequest),
            exhaustMap(({ potentialMatch }) => {
                return this.missionUserMatchService
                    .missionUserMatchContactPotentialMatchPost({
                        missionUserMatchViewModel: potentialMatch,
                    })
                    .pipe(
                        map((chat) =>
                            MissionUserMatchActions.contactPotentialMatcheRequestSuccess(
                                {
                                    chat,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionUserMatchActions.contactPotentialMatcheRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    denyPotentialMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionUserMatchActions.denyMatchRequest),
            exhaustMap(({ potentialMatch }) => {
                return this.missionUserMatchService
                    .missionUserMatchDenyPotentialMatchPost({
                        missionUserMatchViewModel: potentialMatch,
                    })
                    .pipe(
                        map(() =>
                            MissionUserMatchActions.denyMatchRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionUserMatchActions.denyMatchRequestFail({
                                    error: httpResponse?.error ?? httpResponse,
                                })
                            )
                        )
                    );
            })
        )
    );

    reloadChatsAfterContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MissionUserMatchActions.contactPotentialMatcheRequestSuccess
            ),
            map(() => ChatActions.loadChatsRequest())
        )
    );

    seeChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionUserMatchActions.seeChat),
            concatLatestFrom(() =>
                this.store.select(MissionUserMatchSelectors.selectChat)
            ),
            map(([, chat]) => {
                this.router.navigateByUrl(`/s/chat`);
                return ChatActions.selectChat({
                    chatId: chat.id,
                });
            })
        )
    );

    constructor(
        private actions$: Actions,
        private missionUserMatchService: MissionUserMatchService,
        private store: Store,
        private router: Router
    ) {}
}
