import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    DonationCampaignViewModel,
    DonationRequestViewModel,
    LinkedInImageShare,
} from 'app/api';
import { CreateDonationCampaignDialogInput } from 'app/modules/company-admin/donation-campaigns/create/create.component';

export const DonationCampaignActions = createActionGroup({
    source: 'DonationCampaign',
    events: {
        'Open Dialog': props<{ input: CreateDonationCampaignDialogInput }>(),
        'Close Dialog': emptyProps(),

        'Load Donation Campaign Request By Token': props<{ token: string }>(),
        'Load Donation Campaign Request By Id': props<{ id: number }>(),
        'Load Donation Campaign Request Success': props<{
            donationCampaign: DonationCampaignViewModel;
        }>(),
        'Load Donation Campaign Request Failure': props<{ error: string }>(),

        'Update Donation Campaign Request': emptyProps(),
        'Update Donation Campaign Request Success': emptyProps(),
        'Update Donation Campaign Request Failure': props<{ error: string }>(),

        'Set Winning Project Request': props<{ projectId: number }>(),
        'Set Winning Project Request Success': emptyProps(),
        'Set Winning Project Request Failure': props<{ error: string }>(),

        'Choose Project': props<{ id: number }>(),

        'Fake Choose Project Request': props<{
            donationRequest: DonationRequestViewModel;
        }>(),
        'Choose Project Request': props<{
            donationRequest: DonationRequestViewModel;
        }>(),
        'Choose Project Request Success': props<{
            donationRequest: DonationRequestViewModel;
        }>(),
        'Choose Project Request Failure': props<{ error: string }>(),

        'Show More Requests': emptyProps(),
        'Show Less Requests': emptyProps(),
        'Show All Requests': emptyProps(),

        'Contact Me Request': emptyProps(),
        'Contact Me Request Success': emptyProps(),
        'Contact Me Request Failure': props<{ error: string }>(),

        'Share Certificate On LinkedIn': emptyProps(),

        'Load Donation Request Request': props<{ id: number }>(),
        'Load Donation Request Request Success': props<{
            donationRequest: DonationRequestViewModel;
        }>(),
        'Load Donation Request Request Failure': props<{ error: string }>(),

        'Share Certificate On LinkedIn Request': props<{
            linkedInImageShare: LinkedInImageShare;
        }>(),
        'Share Certificate On LinkedIn Request Success': emptyProps(),
        'Share Certificate On LinkedIn Request Failure': props<{
            error: string;
        }>(),
    },
});
