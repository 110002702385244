import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizQuestionViewModel, EAwarenessThematic } from 'app/api';
import {
    ClassicErrorStateMatcher,
    IFormComponent,
} from 'app/shared/services/form/classic-error-state-matcher';
import { DualQuizQuestionFormService } from 'app/shared/services/form/dual-quiz-question-form.service';
import { DualQuizQuestionActions } from 'app/store/dual-quiz/questions/questions.actions';
import { DualQuizQuestionSelectors } from 'app/store/dual-quiz/questions/questions.selectors';

@Component({
    selector: 'questions',
    standalone: true,
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSortModule,
        NgFor,
        NgTemplateOutlet,
        MatPaginatorModule,
        NgClass,
        MatSlideToggleModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        BslkClassicToggleComponent,
        TranslocoModule,
        AsyncPipe,

        LetDirective,
        DatePipe,
    ],
})
export class QuestionsComponent implements OnInit, IFormComponent {
    EAwarenessThematic = EAwarenessThematic;
    filteredthematic: EAwarenessThematic;
    matcher: ClassicErrorStateMatcher;
    isSubmitted = false;

    questions$ = this.store.select(DualQuizQuestionSelectors.selectData);
    selectedQuestion$ = this.store.select(
        DualQuizQuestionSelectors.selectSelectedItem
    );
    isItemSelected$ = this.store.select(
        DualQuizQuestionSelectors.selectIsItemSelected
    );
    isLoading$ = this.store.select(DualQuizQuestionSelectors.selectIsLoading);
    pageIndex$ = this.store.select(DualQuizQuestionSelectors.selectPageIndex);
    totalPages$ = this.store.select(DualQuizQuestionSelectors.selectTotalPages);
    totalCount$ = this.store.select(DualQuizQuestionSelectors.selectTotalCount);
    pageSize$ = this.store.select(DualQuizQuestionSelectors.selectPageSize);
    isCreating$ = this.store.select(DualQuizQuestionSelectors.selectIsCreating);

    constructor(
        private store: Store,
        private formService: DualQuizQuestionFormService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get getIsSubmitted(): boolean {
        return this.isSubmitted;
    }

    ngOnInit(): void {
        this.store.dispatch(DualQuizQuestionActions.Initialize());
        this.matcher = new ClassicErrorStateMatcher(this);
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            DualQuizQuestionActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    startCreation() {
        this.store.dispatch(DualQuizQuestionActions.StartCreation());
    }

    createQuestion() {
        this.isSubmitted = true;
        if (this.formGroup.valid) {
            this.store.dispatch(DualQuizQuestionActions.CreateItem());
            this.isSubmitted = false;
        }
    }

    updateSelectedQuestion() {
        this.isSubmitted = true;
        if (this.formGroup.valid) {
            this.store.dispatch(DualQuizQuestionActions.UpdateSelectedItem());
            this.isSubmitted = false;
        }
    }

    deleteQuestion(id: number, isCreating: boolean) {
        if (isCreating) {
            this.store.dispatch(DualQuizQuestionActions.CancelCreation());
            return;
        }
        this.store.dispatch(DualQuizQuestionActions.DeleteItem({ id }));
    }

    searchQuestion(event: InputEvent) {
        const input: HTMLInputElement = event.target as HTMLInputElement;
        this.store.dispatch(
            DualQuizQuestionActions.FilterListRequest({
                filters: [{ key: 'questionContains', value: input.value }],
            })
        );
    }

    changeThematicFilter(event: EAwarenessThematic) {
        this.store.dispatch(
            DualQuizQuestionActions.FilterListRequest({
                filters: [{ key: 'thematic', value: event }],
            })
        );
    }

    toggleDetails(
        questionId: number,
        isCreating: boolean,
        selectedQuestion?: DualQuizQuestionViewModel
    ): void {
        this.isSubmitted = false;
        if (selectedQuestion && selectedQuestion?.id === questionId) {
            if (isCreating) {
                this.store.dispatch(DualQuizQuestionActions.CancelCreation());
            } else {
                this.store.dispatch(DualQuizQuestionActions.UnselectItems());
            }
            return;
        }
        this.store.dispatch(
            DualQuizQuestionActions.SelectItem({ id: questionId })
        );
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }
}
