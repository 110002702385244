import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'bslk-reward',
    standalone: true,
    templateUrl: './reward.component.html',
    imports: [
        NgIf,
        MatIconModule,
        TranslocoModule,
        MatTooltipModule,
        TranslocoModule,
    ],
})
export class BslkRewardComponent {
    @Input() purposeCoins: number;
    @Input() experiencePoints: number;
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    @Input() multiplier = 1;

    get rewardPurposeCoins() {
        return Math.ceil(this.purposeCoins * this.multiplier);
    }

    get rewardExperiencePoints() {
        return Math.ceil(this.experiencePoints * this.multiplier);
    }

    get isSmall() {
        return this.size === 'small';
    }

    get isMedium() {
        return this.size === 'medium';
    }

    get isLarge() {
        return this.size === 'large';
    }
}
