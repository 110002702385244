<div class="flex flex-col gap-3" [formGroup]="formGroup">
    <div class="flex flex-col gap-1 flex-1">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always">
            <mat-label>{{ "createmicroaction.impact" | transloco }}</mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:pencil'"
                matPrefix
            ></mat-icon>
            <input
                [placeholder]="
                    'createmicroaction.impact-placeholder' | transloco
                "
                [formControlName]="'impact'"
                matInput
            />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.impact.value"
            [maxLength]="100"
        ></bslk-length-info>
    </div>

    <div class="w-full">
        <bslk-info-label
            class="font-bold"
            [tooltip]="'createproject.sdg-impact-info' | transloco"
            >{{ "createproject.sdg-impact" | transloco }}*</bslk-info-label
        >
        <bslk-business-sdg-picker
            [control]="formGroup.controls.sustainableDevelopmentGoals"
            [justify]="'start'"
            [displayValueInField]="true"
        ></bslk-business-sdg-picker>
    </div>

    <!-- Agency -->
    <div *ngIf="companyHasAgencies$ | async" class="flex flex-col gap-2">
        <div class="flex">
            <bslk-classic-toggle
                [label]="'createmicroaction.is-agency-restricted' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [isLabelBold]="true"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isAgencyRestricted"
                [value]="formGroup.controls.isAgencyRestricted.value"
            ></bslk-classic-toggle>
        </div>

        <bslk-advanced-select-sync
            *ngIf="formGroup.controls.isAgencyRestricted.value"
            class="w-full"
            [items]="companyAgencies$ | async"
            [group]="formGroup"
            [controlName]="'companyAgencyId'"
            [icon]="'heroicons_solid:building-office'"
            [label]="'createmicroaction.agency' | transloco"
            [disableSearch]="true"
        ></bslk-advanced-select-sync>
    </div>

    <!-- Location -->
    <div class="flex flex-col gap-2">
        <location-selection
            *ngIf="!formGroup.controls.isRemote.value"
            [formGroup]="formGroup.controls.location"
        ></location-selection>
        <div class="flex">
            <bslk-classic-toggle
                [label]="'createmicroaction.is-remote' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [isLabelBold]="true"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isRemote"
                [value]="formGroup.controls.isRemote.value"
            ></bslk-classic-toggle>
        </div>
    </div>
</div>
