<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent">
        <!-- Drawer -->
        <mat-drawer
            (closed)="onDrawerClosed()"
            class="w-full sm:w-100 dark:bg-gray-900"
            [mode]="drawerMode"
            [opened]="hasSelectedItem$ | async"
            [position]="'end'"
            [disableClose]="false"
            #matDrawer
        >
            <file-manager-details
                *ngIf="hasSelectedItem$ | async"
                [item]="selectedItem$ | async"
            ></file-manager-details>
        </mat-drawer>

        <mat-drawer-content
            *ngrxLet="items$ as items"
            class="flex flex-col bg-accent"
        >
            <!-- Main -->
            <div class="flex flex-col flex-auto">
                <!-- Header -->
                <div
                    class="flex flex-col sm:flex-row items-start sm:items-center sm:justify-between p-4 sm:py-6 md:px-6 border-b bg-card dark:bg-transparent"
                >
                    <!-- Title -->
                    <div>
                        <div
                            class="text-2xl font-bold tracking-tight leading-none"
                        >
                            {{ "files.my-files" | transloco }}
                        </div>
                        <div
                            class="flex items-center mt-0.5 font-medium text-secondary"
                        >
                            <!-- Breadcrumbs -->
                            <ng-container
                                *ngIf="items.path.length && (hasData$ | async)"
                            >
                                <div class="flex items-center space-x-2">
                                    <div
                                        class="text-primary cursor-pointer"
                                        (click)="unselectFolder()"
                                    >
                                        {{ "files.home" | transloco }}
                                    </div>
                                    <div class="">/</div>
                                    <ng-container
                                        *ngFor="
                                            let folderPath of items.path;
                                            let last = last;
                                            trackBy: trackByFn
                                        "
                                    >
                                        <ng-container *ngIf="!last">
                                            <div
                                                class="text-primary truncate cursor-pointer"
                                                (click)="
                                                    selectFolder(folderPath)
                                                "
                                            >
                                                {{
                                                    folderPath.name ??
                                                        ("files.folder-types." +
                                                            folderPath.folderType
                                                            | transloco)
                                                }}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="last">
                                            <div class="truncate">
                                                {{
                                                    folderPath.name ??
                                                        ("files.folder-types." +
                                                            folderPath.folderType
                                                            | transloco)
                                                }}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!last">
                                            <div class="">/</div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- Items list -->
                <ng-container
                    *ngIf="
                        items &&
                            (items.folders.length > 0 ||
                                items.files.length > 0);
                        else noItems
                    "
                >
                    <div class="grow p-6 md:p-8 space-y-8">
                        <!-- Folders -->
                        <ng-container *ngIf="items.folders.length > 0">
                            <div>
                                <bslk-tag
                                    [size]="'small'"
                                    [icon]="'heroicons_outline:folder'"
                                    >{{ "files.folders" | transloco }}</bslk-tag
                                >
                                <div class="flex flex-wrap -m-2 mt-2">
                                    <ng-container
                                        *ngFor="
                                            let folder of items.folders;
                                            trackBy: trackByFn
                                        "
                                    >
                                        <div
                                            class="relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card"
                                        >
                                            <div
                                                class="absolute z-20 top-1.5 right-1.5 w-8 h-8 min-h-8"
                                                (click)="selectFile(folder)"
                                                mat-icon-button
                                            >
                                                <mat-icon
                                                    [matTooltip]="
                                                        'files.see-details'
                                                            | transloco
                                                    "
                                                    class="cursor-pointer icon-size-5 text-tertiary"
                                                    [svgIcon]="
                                                        'heroicons_solid:information-circle'
                                                    "
                                                ></mat-icon>
                                            </div>
                                            <div
                                                class="z-10 absolute inset-0 flex flex-col p-4 cursor-pointer"
                                                (click)="selectFolder(folder)"
                                            >
                                                <div class="aspect-[9/6]">
                                                    <div
                                                        class="flex items-center justify-center h-full"
                                                    >
                                                        <!-- Icon -->
                                                        <mat-icon
                                                            class="icon-size-14 text-tertiary opacity-50"
                                                            [svgIcon]="
                                                                'heroicons_solid:folder'
                                                            "
                                                        ></mat-icon>
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
                                                >
                                                    <div
                                                        *ngIf="folder.name"
                                                        class="truncate"
                                                        [matTooltip]="
                                                            folder.name
                                                        "
                                                    >
                                                        {{ folder.name }}
                                                    </div>
                                                    <div
                                                        *ngIf="!folder.name"
                                                        class="truncate"
                                                        [matTooltip]="
                                                            'files.folder-types.' +
                                                                folder.folderType
                                                                | transloco
                                                        "
                                                    >
                                                        {{
                                                            "files.folder-types." +
                                                                folder.folderType
                                                                | transloco
                                                        }}
                                                    </div>
                                                    <ng-container>
                                                        <div
                                                            *ngIf="
                                                                folder.shortDescription
                                                            "
                                                            class="text-secondary truncate"
                                                        >
                                                            {{
                                                                folder.shortDescription
                                                            }}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Files -->
                        <ng-container *ngIf="items.files.length > 0">
                            <div>
                                <bslk-tag
                                    [size]="'small'"
                                    [background]="'warn'"
                                    [icon]="'heroicons_outline:document'"
                                    >{{ "files.files" | transloco }}</bslk-tag
                                >
                                <div class="flex flex-wrap -m-2 mt-2">
                                    <ng-container
                                        *ngFor="
                                            let file of items.files;
                                            trackBy: trackByFn
                                        "
                                    >
                                        <div
                                            class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card"
                                            (click)="selectFile(file)"
                                        >
                                            <div class="aspect-[9/6]">
                                                <div
                                                    class="flex items-center justify-center h-full"
                                                >
                                                    <!-- Icons -->
                                                    <div class="relative">
                                                        <mat-icon
                                                            class="icon-size-14 text-tertiary opacity-50"
                                                            [svgIcon]="
                                                                'heroicons_solid:document'
                                                            "
                                                        ></mat-icon>
                                                        <div
                                                            *ngIf="
                                                                file.fileExtension !==
                                                                    EFileExtension.Missing;
                                                                else emptyFile
                                                            "
                                                            class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                                            [class.bg-indigo-600]="
                                                                file.fileExtension ===
                                                                EFileExtension.Folder
                                                            "
                                                            [class.bg-red-600]="
                                                                file.fileExtension ===
                                                                EFileExtension.Pdf
                                                            "
                                                            [class.bg-blue-600]="
                                                                file.fileExtension ===
                                                                EFileExtension.Txt
                                                            "
                                                            [class.bg-amber-600]="
                                                                file.fileExtension ===
                                                                EFileExtension.Jpeg
                                                            "
                                                            [class.bg-green-600]="
                                                                file.fileExtension ===
                                                                EFileExtension.Png
                                                            "
                                                        >
                                                            {{
                                                                file.fileExtension.toUpperCase()
                                                            }}
                                                        </div>
                                                        <ng-template #emptyFile>
                                                            <div
                                                                class="bg-warn flex items-center gap-2 absolute left-1/2 -translate-x-1/2 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                                            >
                                                                <mat-icon
                                                                    [svgIcon]="
                                                                        'heroicons_outline:exclamation-triangle'
                                                                    "
                                                                    class="text-current icon-size-4"
                                                                ></mat-icon>
                                                                <span
                                                                    class="text-sm"
                                                                    >{{
                                                                        "files.missing-file"
                                                                            | transloco
                                                                    }}</span
                                                                >
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
                                            >
                                                <div
                                                    *ngIf="
                                                        file.fileType !==
                                                        EFileType.AssociationLegalDocument
                                                    "
                                                    class="truncate"
                                                    [matTooltip]="
                                                        file.name ??
                                                        ('files.file-types.' +
                                                            file.fileType
                                                            | transloco)
                                                    "
                                                >
                                                    {{
                                                        file.name ??
                                                            ("files.file-types." +
                                                                file.fileType
                                                                | transloco)
                                                    }}
                                                </div>
                                                <div
                                                    *ngIf="
                                                        file.fileType ===
                                                        EFileType.AssociationLegalDocument
                                                    "
                                                    class="truncate"
                                                    [matTooltip]="
                                                        'files.association-legal-document.' +
                                                            file.legalDocumentType +
                                                            '.title' | transloco
                                                    "
                                                >
                                                    {{
                                                        "files.association-legal-document." +
                                                            file.legalDocumentType +
                                                            ".title" | transloco
                                                    }}
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        file.shortDescription
                                                    "
                                                >
                                                    <div
                                                        class="text-secondary truncate"
                                                    >
                                                        {{
                                                            file.shortDescription
                                                        }}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <mat-paginator
                        class="sm:sticky sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                        [ngClass]="{
                            'pointer-events-none': isLoading$ | async
                        }"
                        [length]="totalCount$ | async"
                        [pageIndex]="pageIndex$ | async"
                        [pageSize]="pageSize$ | async"
                        [hidePageSize]="true"
                        (page)="pageChangeEvent($event)"
                        [showFirstLastButtons]="true"
                    ></mat-paginator>
                </ng-container>

                <!-- No items template -->
                <ng-template #noItems>
                    <ng-container *ngrxLet="isLoading$ as isLoading">
                        <div
                            class="flex flex-auto flex-col items-center justify-center bg-accent"
                        >
                            <mat-icon
                                *ngIf="!isLoading"
                                class="icon-size-24"
                                [svgIcon]="'heroicons_outline:folder-open'"
                            ></mat-icon>
                            <div
                                class="mt-4 text-2xl font-semibold tracking-tight text-secondary"
                                *ngIf="!isLoading"
                            >
                                {{ "files.empty-folder" | transloco }}
                            </div>
                            <mat-spinner *ngIf="isLoading"></mat-spinner>
                        </div>
                    </ng-container>
                </ng-template>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
