import { createSelector } from '@ngrx/store';
import { EFeature, MissionRegisteredViewModel } from 'app/api';
import { DateRange } from 'app/shared/types/common.types';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isNil } from 'lodash-es';
import { AppState } from '../..';
import {
    CompanyAdminKpi,
    companyAdminKpiLinkedFeature,
    CompanyAdminTab,
} from './company-admin-dashboard.state';

const selectState = (state: AppState) => state.companyAdminDashboard;

const selectIsLoading = (kpi: CompanyAdminKpi) =>
    createSelector(selectState, (state) => state.isLoading[kpi]);

const selectTabConfiguration = (tab: CompanyAdminTab) =>
    createSelector(selectState, (state) => state.configuration[tab]);

const selectConfiguration = createSelector(
    selectState,
    (state) => state.configuration
);

const selectActiveTab = createSelector(selectState, (state) => state.activeTab);

const selectIsKpiDisplayed = (kpi: CompanyAdminKpi) =>
    createSelector(
        selectState,
        (state) => state.configuration[state.activeTab][kpi]
    );

const selectKpiFilters = (kpi: CompanyAdminKpi) =>
    createSelector(selectState, (state) => state.kpiFilters[kpi]);

const selectKpiResult = (kpi: CompanyAdminKpi) =>
    createSelector(selectState, (state) => state.kpiResults[kpi]);

const selectKpiResultDates = (kpi: CompanyAdminKpi) =>
    createSelector(
        selectKpiResult(kpi),
        (kpiResult: any) =>
            ({
                startDate: kpiResult?.startDate,
                endDate: kpiResult?.endDate,
            } as DateRange)
    );

const selectIsKpiResultReady = (kpi: CompanyAdminKpi) =>
    createSelector(selectKpiResult(kpi), (kpiResult) => !isNil(kpiResult));

const selectIsKpiExpanded = (kpi: CompanyAdminKpi) =>
    createSelector(selectState, (state) => state.expandedKpi === kpi);

const selectIsKpiExpandedAnyOf = (kpis: [CompanyAdminKpi]) =>
    createSelector(selectState, (state) => kpis.includes(state.expandedKpi));

const selectEmployeesOnAssignementOverview = createSelector(
    selectState,
    (state) =>
        (
            state.kpiResults[
                'employees-on-assignment'
            ] as MissionRegisteredViewModel[]
        )?.slice(0, 4)
);

const selectIsLoadingExport = createSelector(
    selectState,
    (state) => state.isLoadingExport
);

const selectEnabledTabs = createSelector(
    UserSelectors.selectAllowedFeatures,
    (allowedFeatures) => ({
        overview: true,
        employee: true,
        missions: allowedFeatures?.includes(EFeature.SkillsSponsorship),
        accounting: allowedFeatures?.includes(EFeature.DonationGifts),
    })
);

const selectActiveTabIndex = createSelector(
    selectActiveTab,
    selectEnabledTabs,
    (activeTab, enabledTabs) => Object.keys(enabledTabs).indexOf(activeTab)
);

const selectDisabledKpis = createSelector(
    UserSelectors.selectAllowedFeatures,
    (allowedFeatures) => {
        return Object.keys(companyAdminKpiLinkedFeature).filter(
            (element) =>
                !allowedFeatures?.includes(
                    companyAdminKpiLinkedFeature[element]
                )
        );
    }
);

export const CompanyAdminDashboardSelectors = {
    selectActiveTab,
    selectActiveTabIndex,
    selectDisabledKpis,
    selectConfiguration,
    selectEmployeesOnAssignementOverview,
    selectIsLoadingExport,
    selectEnabledTabs,
    selectIsKpiDisplayed,
    selectIsKpiExpanded,
    selectIsKpiExpandedAnyOf,
    selectIsKpiResultReady,
    selectIsLoading,
    selectKpiResult,
    selectState,
    selectTabConfiguration,
    selectKpiFilters,
    selectKpiResultDates,
};
