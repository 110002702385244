<div
    *ngrxLet="snackAlerts$ as snackAlerts"
    class="snack-alert-container px-2 md:px-0 fixed bottom-[1em] z-99999 w-96 md:w-[30rem] left-1/2 transform -translate-x-1/2 md:left-auto md:transform-none md:right-[2em]"
>
    <div *ngFor="let alert of snackAlerts">
        <fuse-alert
            (click)="dispatchAction(alert)"
            class="snack-alert my-3"
            [class.cursor-pointer]="alert.action || alert.link"
            [type]="alert.type"
            [name]="alert.title"
            [dismissible]="true"
            [dismissed]="false"
            [appearance]="'outline'"
        >
            <span fuseAlertTitle>{{
                "alerts." + alert.title | transloco : alert.variables
            }}</span>
            <ng-container *ngIf="alert.link; else noLink">
                <a [routerLink]="alert.link">{{
                    "alerts." + alert.message | transloco : alert.variables
                }}</a>
            </ng-container>
            <ng-template #noLink>
                <span>{{
                    "alerts." + alert.message | transloco : alert.variables
                }}</span>
            </ng-template>
        </fuse-alert>
    </div>
</div>
