import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import {
    FormArray,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { CompanyCsrApproachSelectors } from 'app/store/company/csr-approach/csr-approach.selectors';

@Component({
    selector: 'edit-matrix-legends-dialog',
    standalone: true,
    templateUrl: './edit-matrix-legends.component.html',
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkTagButtonComponent,
        BslkClassicButtonComponent,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        NgFor,
        NgIf,
        TranslocoModule,
        FormsModule,
    ],
})
export class EditMatrixLegendsDialog {
    editedLegendFormGroup: FormGroup;

    isLoading$ = this.store.select(
        CompanyCsrApproachSelectors.selectIsLoadingLegendCreateOrUpdate
    );

    constructor(
        private dialogRef: MatDialogRef<EditMatrixLegendsDialog>,
        private store: Store,
        private companyCsrApproachFormService: CompanyCsrApproachFormService
    ) {}

    get legendsFormArray() {
        return (
            this.companyCsrApproachFormService.getFormGroup.controls
                .matrix as FormGroup
        ).controls.legends as FormArray;
    }

    addLegend() {
        const formGroup =
            this.companyCsrApproachFormService.createMatrixLegend();
        this.legendsFormArray.push(formGroup);
        this.editedLegendFormGroup = formGroup;
    }

    deleteLegend() {
        this.legendsFormArray.removeAt(
            this.legendsFormArray.controls.indexOf(this.editedLegendFormGroup)
        );
        this.editedLegendFormGroup = null;
    }

    editedLegendChange(change: MatSelectChange) {
        this.editedLegendFormGroup = change.value;
    }

    save() {
        if (this.legendsFormArray.valid) {
            this.store.dispatch(
                CompanyCsrApproachActions.createOrUpdateMatrixLegendRequest({
                    legends: this.legendsFormArray.value,
                })
            );
        }
    }

    close() {
        this.store.dispatch(CompanyCsrApproachActions.resetMatrixLegendsForm());
        this.dialogRef.close();
    }
}
