import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkColor, BslkSizeExtended } from '@bslk/types/bslk-types';
import { FormControlComponent } from '../form-control-component';

@Component({
    selector: 'bslk-checkbox',
    templateUrl: './checkbox.component.html',
    standalone: true,
    imports: [MatIconModule, NgClass],
})
export class BslkCheckboxComponent extends FormControlComponent {
    @Input() size: BslkSizeExtended = 'medium';
    @Input() color: BslkColor = 'primary';
    @Input() checked: boolean;
    @Input() disabled: boolean;
    @Input() isLoading: boolean;

    constructor() {
        super();
    }

    toggle() {
        if (!this.disabled && !this.isLoading) {
            this.checked = !this.checked;
            this.setValue(this.checked);
        }
    }
}
