import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { FooterComponent } from 'app/shared/components/footer/footer.component';
import { generateGUID } from 'app/shared/utils/guid.utils';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { Subject, takeUntil } from 'rxjs';
import { DonationCertificateComponent } from '../donation-certificate.component';

@Component({
    selector: 'share-certificate',
    templateUrl: './share-certificate.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkLengthInfoComponent,
        BslkTagButtonComponent,
        DonationCertificateComponent,
        FooterComponent,
        FormsModule,
        FuseAlertComponent,
        LetDirective,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        MatProgressSpinnerModule,
    ],
})
export class ShareCertificateComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    code: string;
    postText?: string; // LinkedIn post text (optional)
    triggerCapture: string; // Trigger certificate image capture event

    isRequestLoaded$ = this.store.select(
        DonationCampaignSelectors.selectIsDonationRequestLoaded
    );

    isLoadingShare$ = this.store.select(
        DonationCampaignSelectors.selectIsLoadingShare
    );
    isLoadingRequest$ = this.store.select(
        DonationCampaignSelectors.selectIsLoadingDonationRequest
    );

    hasShared$ = this.store.select(DonationCampaignSelectors.selectHasShared);

    hasFailedToShare$ = this.store.select(
        DonationCampaignSelectors.selectHasFailedToShare
    );

    request$ = this.store
        .select(DonationCampaignSelectors.selectDonationRequest)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((request) => {
            if (request) {
                this.translocoService
                    .selectTranslate('donationgift.share-message', {
                        company: request.donationCampaign.isClientCampaign
                            ? request.donationCampaign.clientName
                            : request.donationCampaign.company.name,
                    })
                    .pipe(takeUntil(this.unsubscribeAll))
                    .subscribe((text) => {
                        this.postText = text;
                    });
            }
        });

    constructor(
        private activatedRoute: ActivatedRoute,
        private store: Store,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                this.code = params['code'];
                const donationCampaignRequestId = params['donationRequestId'];
                if (donationCampaignRequestId) {
                    this.store.dispatch(
                        DonationCampaignActions.loadDonationRequestRequest({
                            id: donationCampaignRequestId,
                        })
                    );
                }
            });
    }

    onImageCapture(imageBase64: string) {
        this.store.dispatch(
            DonationCampaignActions.shareCertificateOnLinkedInRequest({
                linkedInImageShare: {
                    imageBase64,
                    code: this.code,
                    text: this.postText,
                },
            })
        );
    }

    post() {
        this.triggerCapture = generateGUID();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
