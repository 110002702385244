<div
    *ngrxLet="hasUnsubscribed$ as hasUnsubscribed"
    class="w-screen flex flex-col items-center gap-8 justify-center p-10"
>
    <a href="/">
        <img
            class="w-72"
            src="assets/images/logo/logo-text-horizontal.png"
            alt="Logo image"
        />
    </a>

    <div class="bg-white border rounded-xl shadow-lg p-8">
        <ng-container *ngIf="!hasUnsubscribed; else unsubscribe">
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded w-full min-w-64 xl:min-w-80"
                [subscriptSizing]="'dynamic'"
            >
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:envelope'"
                ></mat-icon>
                <input matInput [(ngModel)]="email" />
            </mat-form-field>

            <bslk-classic-button
                class="flex mt-4 justify-center"
                [isLoading]="isLoading$ | async"
                [isDisabled]="isEmailEmpty"
                (click)="submit()"
                >{{
                    "shared.unsubscribe.unsubscribe" | transloco
                }}</bslk-classic-button
            >
        </ng-container>
    </div>
</div>

<ng-template #unsubscribe>
    <div class="flex items-center justify-center gap-4">
        <img
            class="w-36"
            src="assets/basile-postures/hello.png"
            alt="Basile saying goodbye"
        />
        <p class="text-lg font-semibold max-w-72">
            {{ "shared.unsubscribe.success" | transloco }}
        </p>
    </div>
</ng-template>
