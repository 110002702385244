// dialog.service.ts

import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { isEmpty, isNil } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CreateMissionValidationService {
    constructor(
        private missionFormService: MissionFormService,
        private translocoService: TranslocoService
    ) {}

    get formGroup() {
        return this.missionFormService.getFormGroup;
    }

    get missionSlotsArray() {
        return this.formGroup.controls.missionSlots as FormArray;
    }

    get missionHasAResponsibleUser() {
        return (
            this.formGroup.controls.isMissionCreatorResponsibleUser.value ||
            !isNil(this.formGroup.controls.responsibleUserId.value) ||
            !isEmpty(
                this.formGroup.controls.notRegisteredResponsibleUserEmail.value
            )
        );
    }

    get isStep0Valid() {
        return this.getStep0Errors.length === 0;
    }

    get isStep1Valid() {
        return this.getStep1Errors.length === 0;
    }

    get isStep2Valid() {
        return this.getStep2Errors.length === 0;
    }

    isStepValid(stepIndex: number): boolean {
        switch (stepIndex) {
            case 0:
                return this.isStep0Valid;
            case 1:
                return this.isStep1Valid;
            case 2:
                return this.isStep2Valid;
            default:
                return false;
        }
    }

    getCurrentStepErrors(step: number): string[] {
        switch (step) {
            case 0:
                return this.getStep0Errors;
            case 1:
                return this.getStep1Errors;
            case 2:
                return this.getStep2Errors;
        }
    }

    get getStep0Errors(): string[] {
        const errors = [];
        if (this.formGroup.controls.name.invalid) {
            errors.push(
                this.translocoService.translate('createmission.name-error')
            );
        }
        if (this.formGroup.controls.skills.invalid) {
            errors.push(
                this.translocoService.translate('createmission.skills-error')
            );
        }
        const locationForm = this.formGroup.controls.location as FormGroup;
        const isOnSite = this.formGroup.controls.isOnSite.value;
        if (isOnSite && locationForm.invalid) {
            errors.push(
                this.translocoService.translate('createmission.location-error')
            );
        }
        if (this.formGroup.hasError('missingMinPerson')) {
            errors.push(
                this.translocoService.translate(
                    'createmission.min-person-error'
                )
            );
        }
        if (this.formGroup.hasError('missingPricePerPerson')) {
            errors.push(
                this.translocoService.translate(
                    'createmission.price-per-person-error'
                )
            );
        }
        if (this.formGroup.hasError('missingCurrency')) {
            errors.push(
                this.translocoService.translate('createmission.currency-error')
            );
        }
        return errors;
    }

    get getStep1Errors(): string[] {
        // Clear validators set by the calendar with matDatepickerFilter
        // We don't want to set as invalid a slot previously created
        this.missionSlotsArray.controls.forEach((formGroup: FormGroup) => {
            formGroup.controls.startDay.clearValidators();
            formGroup.controls.endDay.clearValidators();
            formGroup.controls.startDay.updateValueAndValidity();
            formGroup.controls.endDay.updateValueAndValidity();
        });

        const errors = [];
        if (this.missionSlotsArray.controls.some((c) => c.invalid)) {
            errors.push(
                this.translocoService.translate(
                    'createmission.invalid-slots-error'
                )
            );
        }
        if (
            !this.formGroup.controls.isDateSelectionVolunteerBased.value &&
            this.missionSlotsArray.controls.length === 0
        ) {
            errors.push(
                this.translocoService.translate('createmission.no-slot-error')
            );
        }

        if (this.formGroup.hasError('invalidMaxUniqueSlotProposals')) {
            errors.push(
                this.translocoService.translate(
                    'createmission.invalid-unique-slot-proposals'
                )
            );
        }

        if (this.formGroup.hasError('minHigherThanMaxVolunteersBySlot')) {
            errors.push(
                this.translocoService.translate(
                    'createmission.min-higher-than-max-volunteers-by-slot'
                )
            );
        }
        return errors;
    }

    get getStep2Errors(): string[] {
        const errors = [];
        if (this.formGroup.controls.description.errors) {
            errors.push(
                this.translocoService.translate(
                    'createmission.description-error'
                )
            );
        }
        if (!this.missionHasAResponsibleUser) {
            errors.push(
                this.translocoService.translate(
                    'createmission.responsible-person-error'
                )
            );
        }
        if (this.formGroup.controls.notRegisteredResponsibleUserEmail.errors) {
            errors.push(
                this.translocoService.translate(
                    'createmission.responsible-user-email-error'
                )
            );
        }
        if (this.formGroup.controls.expectedImpact.errors) {
            errors.push(
                this.translocoService.translate(
                    'createmission.expected-impact-error'
                )
            );
        }
        if (this.formGroup.controls.additionalInformation.errors) {
            errors.push(
                this.translocoService.translate(
                    'createmission.additional-information-error'
                )
            );
        }
        if (this.formGroup.controls.sustainableDevelopmentGoals.errors) {
            errors.push(
                this.translocoService.translate(
                    'createmission.sdg-impact-error'
                )
            );
        }
        return errors;
    }
}
