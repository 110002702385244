<bslk-classic-card
    [disabled]="!(isRegistered$ | async)"
    [disabledText]="'microactionview.activities-not-registered' | transloco"
    [title]="'microactionview.activities-title' | transloco"
>
    <div class="flex flex-col gap-8">
        <div
            class="border-2 shadow rounded-lg"
            *ngFor="let activity of activities"
        >
            <div
                *ngrxLet="userId$ as userId"
                class="flex items-center gap-3 py-2 px-4"
            >
                <bslk-checkbox
                    (valueChange)="activityCompletedChange(activity.id, $event)"
                    [checked]="isActivityCompleted(activity, userId)"
                    [isLoading]="isLoading$ | async"
                    [size]="'large'"
                >
                </bslk-checkbox>
                <div class="flex justify-between grow">
                    <div class="flex flex-col gap-1">
                        <span class="text-xl font-semibold">{{
                            activity.name
                        }}</span>
                        <span>{{ activity.description }}</span>
                    </div>

                    <mat-icon
                        *ngIf="activity.additionalInformation"
                        [svgIcon]="'heroicons_solid:information-circle'"
                        [matTooltip]="activity.additionalInformation"
                        class="text-tertiary icon-size-6"
                    ></mat-icon>
                </div>
            </div>
            <div
                class="bg-primary-200 py-1 px-4 flex items-center justify-between"
            >
                <div class="flex gap-3">
                    <span class="uppercase text-sm">{{
                        "microactionview.level-of-effort" | transloco
                    }}</span>

                    <div class="flex gap-2 items-center">
                        <div
                            class="border-warn border rounded-full bg-warn w-4 h-4"
                        ></div>
                        <div
                            [class.bg-warn]="activity.effortLevel >= 2"
                            class="border-warn border rounded-full bg-warn w-4 h-4"
                        ></div>
                        <div
                            [class.bg-warn]="activity.effortLevel >= 3"
                            class="border-warn border rounded-full bg-warn w-4 h-4"
                        ></div>
                    </div>
                </div>
                <div class="flex gap-3">
                    <span class="uppercase text-sm">{{
                        "microactionview.rewards" | transloco
                    }}</span>
                    <bslk-reward
                        [size]="'small'"
                        [purposeCoins]="rewardValues.purposeCoins"
                        [experiencePoints]="rewardValues.experiencePoints"
                        [multiplier]="activity.effortLevel"
                    ></bslk-reward>
                </div>
            </div>
        </div>
    </div>
</bslk-classic-card>
