import { createReducer, on } from '@ngrx/store';
import { DonationCampaignActions } from './donation-campaign.action';
import { DonationCampaignState } from './donation-campaign.state';

export const donationCampaignRequestsDisplayedStep = 5;

export const donationCampaignInitialState: DonationCampaignState = {
    isLoading: false,
    isLoadingUpdate: false,
    isLoadingDonationRequest: false,
    isLoadingContactMe: false,
    isLoadingShare: false,
    hasAskedToBeContacted: false,
    hasChosenProject: false,
    hasShared: false,
    hasFailedToShare: false,
    requestsDisplayed: donationCampaignRequestsDisplayedStep,
};

export const donationCampaignReducer = createReducer(
    donationCampaignInitialState,

    on(DonationCampaignActions.loadDonationCampaignRequestByToken, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(DonationCampaignActions.loadDonationCampaignRequestById, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(
        DonationCampaignActions.loadDonationCampaignRequestSuccess,
        (state, { donationCampaign }) => ({
            ...state,
            isLoading: false,
            donationCampaign,
        })
    ),

    on(DonationCampaignActions.loadDonationCampaignRequestFailure, (state) => ({
        ...state,
        isLoading: false,
    })),

    on(DonationCampaignActions.chooseProject, (state, { id }) => {
        return {
            ...state,
            chosenProject: state.donationCampaign.projects.find(
                (project) => project.id === id
            ),
        };
    }),

    on(DonationCampaignActions.fakeChooseProjectRequest, (state) => ({
        ...state,
        hasChosenProject: true,
    })),

    on(
        DonationCampaignActions.chooseProjectRequest,
        (state, { donationRequest }) => ({
            ...state,
            isLoadingDonationRequest: true,
            email: donationRequest.email,
        })
    ),

    on(
        DonationCampaignActions.chooseProjectRequestSuccess,
        (state, { donationRequest }) => ({
            ...state,
            isLoadingDonationRequest: false,
            hasChosenProject: true,
            donationRequest,
        })
    ),

    on(
        DonationCampaignActions.fakeChooseProjectRequest,
        (state, { donationRequest }) => ({
            ...state,
            isLoadingDonationRequest: false,
            hasChosenProject: true,
            donationRequest,
        })
    ),

    on(
        DonationCampaignActions.chooseProjectRequestFailure,
        (state, { error }) => ({
            ...state,
            isLoadingDonationRequest: false,
            chosenProjectError: error,
        })
    ),

    on(DonationCampaignActions.showMoreRequests, (state) => ({
        ...state,
        requestsDisplayed:
            state.requestsDisplayed + donationCampaignRequestsDisplayedStep,
    })),

    on(DonationCampaignActions.showLessRequests, (state) => ({
        ...state,
        requestsDisplayed:
            state.requestsDisplayed - donationCampaignRequestsDisplayedStep,
    })),

    on(DonationCampaignActions.showAllRequests, (state) => ({
        ...state,
        requestsDisplayed: state.donationCampaign.requests.length,
    })),

    on(DonationCampaignActions.updateDonationCampaignRequest, (state) => ({
        ...state,
        isLoadingUpdate: true,
    })),

    on(
        DonationCampaignActions.updateDonationCampaignRequestSuccess,
        (state) => ({
            ...state,
            isLoadingUpdate: false,
            updateError: null,
        })
    ),

    on(
        DonationCampaignActions.updateDonationCampaignRequestFailure,
        (state, { error }) => ({
            ...state,
            isLoadingUpdate: false,
            updateError: error,
        })
    ),

    on(DonationCampaignActions.setWinningProjectRequest, (state) => ({
        ...state,
        isLoadingUpdate: true,
    })),

    on(DonationCampaignActions.setWinningProjectRequestSuccess, (state) => ({
        ...state,
        isLoadingUpdate: false,
        updateError: null,
    })),

    on(
        DonationCampaignActions.setWinningProjectRequestFailure,
        (state, { error }) => ({
            ...state,
            isLoadingUpdate: false,
            updateError: error,
        })
    ),

    on(DonationCampaignActions.contactMeRequest, (state) => ({
        ...state,
        isLoadingContactMe: true,
    })),

    on(DonationCampaignActions.contactMeRequestSuccess, (state) => ({
        ...state,
        isLoadingContactMe: false,
        hasAskedToBeContacted: true,
    })),

    on(DonationCampaignActions.contactMeRequestFailure, (state) => ({
        ...state,
        isLoadingContactMe: false,
    })),

    on(DonationCampaignActions.loadDonationRequestRequest, (state) => ({
        ...state,
        isLoadingDonationRequest: true,
    })),

    on(
        DonationCampaignActions.loadDonationRequestRequestSuccess,
        (state, { donationRequest }) => ({
            ...state,
            isLoadingDonationRequest: false,
            donationRequest,
            donationCampaign: donationRequest.donationCampaign,
            chosenProject: donationRequest.project,
            hasChosenProject: true,
        })
    ),

    on(DonationCampaignActions.loadDonationRequestRequestFailure, (state) => ({
        ...state,
        isLoadingDonationRequest: false,
    })),

    on(DonationCampaignActions.shareCertificateOnLinkedInRequest, (state) => ({
        ...state,
        isLoadingShare: true,
    })),

    on(
        DonationCampaignActions.shareCertificateOnLinkedInRequestSuccess,
        (state) => ({
            ...state,
            isLoadingShare: false,
            hasShared: true,
        })
    ),

    on(
        DonationCampaignActions.shareCertificateOnLinkedInRequestFailure,
        (state) => ({
            ...state,
            isLoadingShare: false,
            hasShared: true,
            hasFailedToShare: true,
        })
    )
);
