import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {
    EDuration,
    EMissionRecurrenceType,
    EMissionType,
    ETimePeriod,
    LocationViewModel,
    MissionSlotViewModel,
    MissionUnregisteredViewModel,
} from 'app/api';
import { SLOT_DEFAULT_HOURS } from 'app/shared/utils/constants.utils';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { generateGUID } from 'app/shared/utils/guid.utils';
import { isEmpty, isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import { Observable, first, of, switchMap } from 'rxjs';
import { IFormService } from './form-service';
import { LocationFormService } from './location-form.service';

@Injectable({
    providedIn: 'root',
})
export class MissionFormService
    extends LocationFormService
    implements IFormService<MissionUnregisteredViewModel>
{
    private formGroup: FormGroup;

    constructor(
        protected fb: FormBuilder,
        private datePipe: DatePipe,
        private translocoService: TranslocoService
    ) {
        super(fb);
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group(
            {
                id: [null],
                name: ['', [Validators.required, Validators.maxLength(100)]],
                type: [
                    EMissionType.SkillsBasedSponsorship,
                    Validators.required,
                ],
                isDateSelectionVolunteerBased: [false, Validators.required],
                maxUniqueSlotProposals: [1],
                maxVolunteersBySlot: [1, Validators.required],
                isConsistentGroupAcrossSlots: [null],
                duration: [EDuration.OneDay, Validators.required],
                expirationDate: [DateTime.now().plus({ year: 1 })],
                description: [
                    '',
                    [
                        Validators.required,
                        CustomValidators.htmlTextLengthValidator(50, 3000),
                    ],
                ],
                expectedImpact: [
                    '',
                    [Validators.required, Validators.maxLength(100)],
                ],
                isActive: [true],
                isOnSite: [true],
                isRemote: [false],
                isPmrAvailable: [true],
                isTeamBuilding: [false],
                isPaidTeamBuilding: [null],
                paidTeamBuildingCurrency: [null],
                paidTeamBuildingMinPerson: [null],
                paidTeamBuildingPricePerPerson: [null],
                complexity: [null],
                additionalInformation: ['', Validators.maxLength(200)],
                coverUrl: [''],
                coverFile: [''],
                skills: [[], CustomValidators.maxArrayLengthValidator(5)],
                sustainableDevelopmentGoals: [[], Validators.required],
                associationId: [''],
                isMissionCreatorResponsibleUser: [true],
                isMissionCreatorResponsibleOnBasilik: [true],
                responsibleUserId: [''],
                notRegisteredResponsibleUserEmail: ['', Validators.email],
                location: this.initLocationForm(true),
                missionSlots: this.fb.array([]),
            },
            { validator: this.dynamicValidator() }
        );

        this.getDescriptionCanvas()
            .pipe(first())
            .subscribe((description) => {
                this.formGroup.controls.description.setValue(description);
            });

        return this.formGroup;
    }

    resetMissionSlotFormArray() {
        this.formGroup.controls.missionSlots = this.fb.array([]);
    }

    createMissionSlot(slot?: MissionSlotViewModel): FormGroup {
        return this.fb.group(
            {
                // GUID purpose is to do the full calendar events / form array mapping, and to be able delete recurrent child events at once
                guid: [slot?.guid ?? generateGUID()],
                parentGuid: [slot?.parentGuid],
                id: [slot?.id ?? 0],
                startTime: [
                    slot
                        ? DateTime.fromISO(slot.startDate).toFormat('HH:mm')
                        : SLOT_DEFAULT_HOURS.START_AS_STRING,
                    CustomValidators.timeValidator,
                ],
                endTime: [
                    slot
                        ? DateTime.fromISO(slot.endDate).toFormat('HH:mm')
                        : SLOT_DEFAULT_HOURS.END_AS_STRING,
                    CustomValidators.timeValidator,
                ],
                startDay: [
                    slot ? DateTime.fromISO(slot.startDate).toString() : '',
                ],
                endDay: [slot ? DateTime.fromISO(slot.endDate).toString() : ''],
                startDate: [
                    slot ? DateTime.fromISO(slot.startDate) : '',
                    Validators.required,
                ],
                endDate: [
                    slot ? DateTime.fromISO(slot.endDate) : '',
                    Validators.required,
                ],
                recurrenceType: [
                    slot?.recurrenceType ?? EMissionRecurrenceType.None,
                ],
                customRecurrenceType: [
                    slot?.customRecurrenceType ?? ETimePeriod.Week,
                ],
                customRecurrenceUnit: [slot?.customRecurrenceUnit],
                estimatedTimeInHours: [slot?.estimatedTimeInHours],
            },
            { validators: CustomValidators.startAndEndDateValidator }
        );
    }

    updateForm(entity: MissionUnregisteredViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        this.patchLocation(entity.location);
        if (entity.missionSlots) {
            this.formGroup.controls.missionSlots = this.fb.array([]);
            const missionSlotsControl = this.formGroup.controls
                .missionSlots as FormArray;
            entity.missionSlots
                .filter(
                    (slot) =>
                        !isEmpty(slot.users) ||
                        new Date(slot.startDate) > new Date()
                )
                .forEach((slot) => {
                    const slotControl = this.createMissionSlot(slot);
                    this.patchSlotDates(slotControl, slot);
                    missionSlotsControl.push(slotControl);
                });
        }
    }

    updateFormFromUser(entity: MissionUnregisteredViewModel, userId: number) {
        this.updateForm(entity);
        this.formGroup.controls.isMissionCreatorResponsibleOnBasilik.setValue(
            !isNil(entity.responsibleUserId)
        );
        this.formGroup.controls.isMissionCreatorResponsibleUser.setValue(
            !isNil(entity.responsibleUserId) &&
                entity.responsibleUserId === userId
        );
    }

    patchSlotDates(formGroup: FormGroup, slot: MissionSlotViewModel): void {
        formGroup.patchValue({
            startDay: DateTime.fromISO(slot.startDate),
            endDay: DateTime.fromISO(slot.endDate),
            startTime: this.datePipe.transform(slot.startDate, 'HH:mm'),
            endTime: this.datePipe.transform(slot.endDate, 'HH:mm'),
        });
    }

    getEntity(): { [key: string]: MissionUnregisteredViewModel } {
        this.formGroup.updateValueAndValidity();

        const missionUnregisteredViewModel =
            returnOnlyNonNullFields<MissionUnregisteredViewModel>(
                this.formGroup.value
            ) as MissionUnregisteredViewModel;

        missionUnregisteredViewModel.location =
            this.formGroup.value.location &&
            this.formGroup.controls.isOnSite.value
                ? returnOnlyNonNullFields<LocationViewModel>(
                      this.formGroup.value.location
                  )
                : null;

        return { missionUnregisteredViewModel };
    }

    getDescriptionCanvas(): Observable<string> {
        return this.translocoService
            .selectTranslateObject('description-canvas', {}, 'createmission')
            .pipe(
                switchMap((translations) => {
                    return of(this.buildDescription(translations));
                })
            );
    }

    buildDescription(translations: any) {
        let canvas = '';
        const firstQuestion = translations['description-first-question'];
        const secondQuestion = translations['description-second-question'];
        const thirdQuestion = translations['description-third-question'];
        canvas += `<p><strong>${firstQuestion}</strong></p>`;
        canvas += `<br><br>`;
        canvas += `<p><strong>${secondQuestion}</strong></p>`;
        canvas += `<br><br>`;
        canvas += `<p><strong>${thirdQuestion}</strong></p>`;
        return canvas;
    }

    dynamicValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let slotsError = {};
            let teamBuildingErrors = {};

            const formGroup = control as FormGroup;
            if (formGroup.controls.isDateSelectionVolunteerBased.value) {
                const maxUniqueSlotProposals =
                    formGroup.controls.maxUniqueSlotProposals.value;

                if (!maxUniqueSlotProposals || maxUniqueSlotProposals <= 0) {
                    slotsError = { invalidMaxUniqueSlotProposals: true };
                }
            }

            if (formGroup.controls.isPaidTeamBuilding.value) {
                teamBuildingErrors = {
                    missingPricePerPerson: isNil(
                        formGroup.controls.paidTeamBuildingPricePerPerson.value
                    ),
                    missingMinPerson: isNil(
                        formGroup.controls.paidTeamBuildingMinPerson.value
                    ),
                    missingCurrency: isNil(
                        formGroup.controls.paidTeamBuildingCurrency.value
                    ),
                    minHigherThanMaxVolunteersBySlot:
                        formGroup.controls.paidTeamBuildingMinPerson.value >
                        formGroup.controls.maxVolunteersBySlot.value,
                };
            }

            return {
                ...slotsError,
                ...teamBuildingErrors,
            };
        };
    }
}
