import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    ContactMatchDialogComponent,
    ContactMatchDialogInput,
} from './contact-match-dialog.component';

@Injectable({ providedIn: 'root' })
export class ContactMatchDialogService implements IDialogService {
    private dialogRef: MatDialogRef<ContactMatchDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input?: ContactMatchDialogInput) {
        this.dialogRef = this.dialog.open(ContactMatchDialogComponent, {
            data: input,
        });
    }

    close() {
        this.dialogRef.close();
    }
}
