<div
    *ngIf="isCompanyAdmin || !isHidden"
    [class.bg-white]="isCompanyAdmin || forceWhiteBackground"
    [class.shadow-lg]="isCompanyAdmin || forceWhiteBackground"
    class="rounded-xl p-4 lg:p-6"
>
    <div class="flex items-center justify-between mb-4">
        <span
            [class.opacity-50]="isHidden && !isEditing"
            class="font-bold text-3xl truncate"
            >{{ title }}</span
        >
        <div *ngIf="isCompanyAdmin" class="flex items-center gap-3">
            <bslk-tag
                *ngIf="hasUnsavedChanges"
                [background]="'warn'"
                [icon]="'heroicons_outline:exclamation-triangle'"
                >{{ "csrapproach.unsaved-changes" | transloco }}</bslk-tag
            >

            <bslk-classic-button
                (onClick)="setIsEditing(true)"
                [icon]="'heroicons_outline:cog-6-tooth'"
                [isLoading]="isLoadingUpdate"
                *ngIf="!isEditing"
                [label]="'csrapproach.update' | transloco"
            ></bslk-classic-button>
            <bslk-classic-button
                (onClick)="setIsEditing(false)"
                [icon]="'heroicons_outline:check'"
                [isLoading]="isLoadingUpdate"
                [isDisabled]="isValidateDisabled"
                *ngIf="isEditing"
                [label]="'csrapproach.validate' | transloco"
            ></bslk-classic-button>
            <bslk-classic-button
                *ngIf="!isHidden"
                (onClick)="setIsHiding(true)"
                [isLoading]="isLoadingUpdate"
                [background]="'primary'"
                [icon]="'heroicons_outline:eye-slash'"
                [label]="'csrapproach.hide' | transloco"
            ></bslk-classic-button>
            <bslk-classic-button
                *ngIf="isHidden"
                [background]="'primary'"
                (onClick)="setIsHiding(false)"
                [isLoading]="isLoadingUpdate"
                [icon]="'heroicons_outline:eye'"
                [label]="'csrapproach.show' | transloco"
            ></bslk-classic-button>
        </div>
    </div>

    <div
        [class.opacity-50]="isHidden && !isEditing"
        *ngIf="!isLoading; else loading"
    >
        <ng-content></ng-content>
    </div>
</div>

<ng-template #loading>
    <div class="flex justify-between gap-5">
        <div class="rounded-3xl flex-1 min-h-40 overflow-hidden">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>
        <div class="rounded-3xl flex-1 min-h-40 overflow-hidden">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>
        <div class="rounded-3xl flex-1 min-h-40 overflow-hidden">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
