import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { CompanyCsrApproachActions } from './csr-approach.actions';
import { CompanyCsrApproachState } from './csr-approach.state';

export const companyCsrApproachInitialState: CompanyCsrApproachState = {
    isLoading: false,
    isLoadingImageUpload: false,
    isLoadingLegendCreateOrUpdate: false,
    isLoadingUpdate: false,
};

export const companyCsrApproachReducer = createReducer(
    companyCsrApproachInitialState,

    on(CompanyCsrApproachActions.loadCsrApproachRequest, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(
        CompanyCsrApproachActions.loadCsrApproachRequestSuccess,
        (state, { csrApproach }) => ({
            ...state,
            isLoading: false,
            csrApproach,
        })
    ),

    on(CompanyCsrApproachActions.loadCsrApproachRequestFail, (state) => ({
        ...state,
        isLoading: false,
    })),

    on(CompanyCsrApproachActions.updateCsrApproachRequest, (state) => ({
        ...state,
        isLoadingUpdate: true,
    })),
    on(
        CompanyCsrApproachActions.updateCsrApproachRequestSuccess,
        (state, { csrApproach }) => ({
            ...state,
            csrApproach,
            isLoadingUpdate: false,
        })
    ),
    on(CompanyCsrApproachActions.updateCsrApproachRequestFail, (state) => ({
        ...state,
        isLoadingUpdate: false,
    })),

    on(CompanyCsrApproachActions.updateImageRequest, (state) => ({
        ...state,
        isLoadingImageUpload: true,
    })),

    on(CompanyCsrApproachActions.updateImageRequestSuccess, (state, { url }) =>
        produce(state, (draftState) => {
            draftState.csrApproach.imageUrl = url;
            draftState.isLoadingImageUpload = false;
        })
    ),

    on(CompanyCsrApproachActions.updateImageRequestFail, (state) => ({
        ...state,
        isLoadingImageUpload: false,
    })),

    on(CompanyCsrApproachActions.removeImageRequestSuccess, (state) =>
        produce(state, (draftState) => {
            draftState.csrApproach.imageUrl = null;
        })
    ),

    on(CompanyCsrApproachActions.updatePillarImageRequest, (state) => ({
        ...state,
        isLoadingImageUpload: true,
    })),

    on(
        CompanyCsrApproachActions.updatePillarImageRequestSuccess,
        (state, { pillarId, url }) =>
            produce(state, (draftState) => {
                draftState.csrApproach.pillars.find(
                    (pillar) => pillar.id === pillarId
                ).imageUrl = url;
                draftState.isLoadingImageUpload = false;
            })
    ),

    on(CompanyCsrApproachActions.updatePillarImageRequestFail, (state) => ({
        ...state,
        isLoadingImageUpload: false,
    })),

    on(
        CompanyCsrApproachActions.createOrUpdateMatrixLegendRequest,
        (state) => ({
            ...state,
            isLoadingLegendCreateOrUpdate: true,
        })
    ),

    on(
        CompanyCsrApproachActions.createOrUpdateMatrixLegendRequestSuccess,
        (state, { csrApproach }) => ({
            ...state,
            csrApproach,
            isLoadingLegendCreateOrUpdate: false,
        })
    ),

    on(
        CompanyCsrApproachActions.createOrUpdateMatrixLegendRequestFail,
        (state) => ({
            ...state,
            isLoadingLegendCreateOrUpdate: false,
        })
    )
);
