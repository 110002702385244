import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachViewModel } from 'app/api';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { CompanyCsrApproachCardComponent } from '../card/card.component';
import { CompanyCsrApproachEditDateComponent } from './edit/edit.component';

@Component({
    selector: 'company-csr-approach-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    standalone: true,
    imports: [
        CompanyCsrApproachCardComponent,
        TranslocoModule,
        NgIf,
        NgFor,
        DatePipe,
        NgClass,
        MatIconModule,
        MatTooltipModule,
        BslkIconButtonComponent,
        CompanyCsrApproachEditDateComponent,
    ],
})
export class CompanyCsrApproachTimelineComponent implements OnChanges {
    @ViewChildren('bubble', { read: ElementRef })
    bubbles: QueryList<ElementRef>;
    @ViewChildren('container', { read: ElementRef })
    containers: QueryList<ElementRef>;
    @ViewChildren('timelineElement') timelineElements: QueryList<ElementRef>;
    @ViewChild('scrollContainer') scrollContainer: ElementRef;

    @Input() isLoading: boolean;
    @Input() isLoadingUpdate: boolean;
    @Input() isCompanyAdmin = false;
    @Input() csrApproach: CompanyCsrApproachViewModel;

    isEditing = false;
    isCreating = false;
    editedDateIndex: number;

    focusIndex = 0;
    slideAnimationInterval: number;
    slideAnimationStopped = false;

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService
    ) {}
    get formGroup() {
        return this.formService.getFormGroup;
    }

    addDate() {
        this.isCreating = true;
    }

    onEdit(isEditing: boolean) {
        this.isEditing = isEditing;
        this.scrollContainer.nativeElement.scrollLeft = 0;
        if (isEditing) {
            this.stopSlideAnimation();
        } else {
            this.startSlideAnimation();
        }
    }

    onHiding(isHiding: boolean) {
        this.formGroup.controls.hideTimeline.setValue(isHiding);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    drawerClosed() {
        this.isCreating = false;
        this.editedDateIndex = null;
    }

    editDate(index: number) {
        this.editedDateIndex = index;
    }

    removeDate(index: number) {
        const formArray = this.formGroup.controls.timelineDates as FormArray;
        formArray.removeAt(index);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isEditing =
            this.isEditing ||
            (this.isCompanyAdmin &&
                this.csrApproach?.timelineDates.length === 0);
        if (changes.csrApproach?.currentValue) {
            this.startSlideAnimation();
        }
    }

    adjustContainerHeights(): void {
        let maxHeight = 0;
        this.containers.toArray().forEach((c, index) => {
            const bubble = this.bubbles.toArray()[index];
            if (bubble) {
                const height = bubble.nativeElement.offsetHeight;
                if (height > maxHeight) {
                    maxHeight = height;
                }
            }
        });

        this.containers.toArray().forEach((container) => {
            container.nativeElement.style.height = `${maxHeight}px`;
            container.nativeElement.classList.remove('h-32');
        });
    }

    startSlideAnimation() {
        if (!this.csrApproach) {
            return;
        }
        setTimeout(() => {
            this.adjustContainerHeights();
        }, 0);
        window.clearInterval(this.slideAnimationInterval);
        this.focusIndex = 0;
        this.slideAnimationStopped = false;
        this.slideAnimationInterval = window.setInterval(() => {
            this.focusIndex =
                (this.focusIndex + 1) % this.csrApproach.timelineDates.length;
            this.scrollToFocusIndex();
        }, 5000);
    }

    stopSlideAnimation() {
        window.clearInterval(this.slideAnimationInterval);
        this.slideAnimationStopped = true;
    }

    scrollToFocusIndex(): void {
        if (this.timelineElements.length === 0) {
            return;
        }

        const targetElement =
            this.timelineElements.toArray()[this.focusIndex].nativeElement;
        const containerElement = this.scrollContainer.nativeElement;

        const elementLeft = targetElement.offsetLeft;
        const elementWidth = targetElement.clientWidth;
        const containerWidth = containerElement.clientWidth;
        const scrollLeft = elementLeft - containerWidth / 2 + elementWidth / 2;

        // Needed as transition-all doesn't support scrollLeft
        this.smoothHorizontalScroll(containerElement, scrollLeft);
    }

    smoothHorizontalScroll(
        element: HTMLElement,
        endPos: number,
        duration = 500
    ) {
        const startPos = element.scrollLeft;
        const distance = endPos - startPos;
        const startTime = performance.now();

        const animateScroll = (currentTime: number) => {
            const timeElapsed = currentTime - startTime;
            const nextScrollPos = this.easeInOutQuad(
                timeElapsed,
                startPos,
                distance,
                duration
            );

            element.scrollLeft = nextScrollPos;

            if (timeElapsed < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    }

    easeInOutQuad(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    }
}
