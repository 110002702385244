import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { AppState } from '..';

const selectState = (state: AppState) => state.regularDashboard;

const selectUserRecap = createSelector(selectState, (state) => state.recap);

const selectIsLoadingUserRecap = createSelector(
    selectState,
    (state) => state.isLoadingRecap
);

const selectIsLoadingManagerPendingApprovals = createSelector(
    selectState,
    (state) => state.isLoadingManagerPendingApprovals
);

const selectManagerPendingApprovals = createSelector(
    selectState,
    (state) => state.managerPendingApprovals
);

const selectNoManagerPendingApprovals = createSelector(
    selectManagerPendingApprovals,
    (pendingApprovals) => isEmpty(pendingApprovals)
);

const selectPendingDonationGifts = createSelector(
    selectState,
    (state) => state.recap?.pendingDonationGifts
);

const selectHasAnyPendingDonationGift = createSelector(
    selectPendingDonationGifts,
    (pendingDonationGifts) => !isEmpty(pendingDonationGifts)
);

export const RegularDashboardSelectors = {
    selectState,
    selectIsLoadingManagerPendingApprovals,
    selectIsLoadingUserRecap,
    selectManagerPendingApprovals,
    selectPendingDonationGifts,
    selectNoManagerPendingApprovals,
    selectHasAnyPendingDonationGift,
    selectUserRecap,
};
