<company-csr-approach-card
    [isLoading]="isLoading"
    (editing)="onEdit($event)"
    (hiding)="onHiding($event)"
    [isLoading]="isLoading"
    [isCompanyAdmin]="isCompanyAdmin"
    [isHidden]="csrApproach?.hideTimeline"
    [isEditing]="isEditing"
    [forceWhiteBackground]="true"
    [title]="'csrapproach.timeline' | transloco"
>
    <div
        #scrollContainer
        (click)="stopSlideAnimation()"
        [class.hide-scrollbar]="!slideAnimationStopped"
        [class.pl-22]="!isEditing"
        class="flex flex-nowrap pt-4 pb-8 pr-3 overflow-x-scroll transition-all"
    >
        <div
            *ngIf="isEditing"
            class="flex-0 self-start flex flex-col items-center gap-2"
        >
            <bslk-icon-button
                (click)="addDate()"
                [matTooltip]="'csrapproach.new-date' | transloco"
                [icon]="'heroicons_solid:plus'"
            ></bslk-icon-button>
            <div class="relative">
                <img
                    class="h-32 w-32 rotate-180"
                    src="/assets/images/leafs/basile.svg"
                />

                <span
                    class="font-bold text-lg absolute left-1/2 -translate-x-1/2 top-7"
                    >?</span
                >
            </div>
        </div>

        <div
            #timelineElement
            class="flex-0"
            *ngFor="
                let timelineDate of csrApproach?.timelineDates;
                let index = index;
                let last = last
            "
        >
            <div class="flex flex-col gap-4">
                <div
                    #container
                    class="h-32 flex flex-col justify-end -ml-22"
                    *ngIf="!isEditing"
                >
                    <div
                        *ngIf="index % 2 === 0"
                        #bubble
                        [ngClass]="{
                           'shadow-light bg-opacity-10': focusIndex === index,
                            'shadow-lg': focusIndex !== index,
                            'shadow-warn bg-warn': focusIndex === index && index % 3 === 0,
                            'shadow-primary bg-primary': focusIndex === index && index % 3 === 1,
                            'shadow-tertiary bg-tertiary': focusIndex === index && index % 3 === 2,

                        }

                        "
                        class="max-w-80 border duration-700 rounded-xl flex flex-col p-2"
                    >
                        <span
                            [ngClass]="{
                            'text-warn': index % 3 === 0,
                            'text-primary': index % 3 === 1,
                            'text-tertiary': index % 3 === 2,
                        }
                        "
                            >{{ timelineDate.date | date : "MM/yyyy" }}</span
                        >
                        <span class="text-xl font-bold"
                            >{{ timelineDate.name }}
                        </span>
                        <span class="text-sm">{{
                            timelineDate.description
                        }}</span>
                    </div>
                </div>

                <div class="flex">
                    <div class="flex flex-col items-center gap-2">
                        <bslk-icon-button
                            *ngIf="isEditing"
                            (click)="editDate(index)"
                            [matTooltip]="'csrapproach.edit' | transloco"
                            [icon]="'heroicons_solid:pencil'"
                        ></bslk-icon-button>
                        <div
                            [class.-ml-6]="index > 0 && !isEditing"
                            [class.-ml-10]="last && !isEditing"
                            class="relative"
                        >
                            <img
                                [class.rotate-180]="index % 2 === 1"
                                class="h-32 w-32"
                                src="/assets/images/leafs/basile.svg"
                            />

                            <span
                                [ngClass]="
                                    index % 2 === 0 ? 'bottom-7' : 'top-7'
                                "
                                class="font-bold text-lg absolute left-1/2 -translate-x-1/2"
                                >{{ timelineDate.date | date : "yyyy" }}</span
                            >
                        </div>
                        <bslk-icon-button
                            *ngIf="isEditing"
                            (click)="removeDate(index)"
                            [matTooltip]="'csrapproach.remove' | transloco"
                            [icon]="'heroicons_solid:x-mark'"
                        ></bslk-icon-button>
                    </div>

                    <img
                        *ngIf="!isEditing && !last"
                        class="-ml-4 min-w-40"
                        [src]="
                            '/assets/images/leafs/' +
                            (index % 2 === 0 ? 'up' : 'down') +
                            '.svg'
                        "
                    />
                </div>
                <div
                    *ngIf="!isEditing && index % 2 === 1"
                    class="transition-all duration-700 max-w-80 border rounded-xl flex flex-col p-2 -ml-22"
                    [ngClass]="{
                            'shadow-light bg-opacity-10': focusIndex === index,
                            'shadow-lg': focusIndex !== index,
                            'shadow-warn bg-warn': focusIndex === index && index % 3 === 0,
                            'shadow-primary bg-primary': focusIndex === index && index % 3 === 1,
                            'shadow-tertiary bg-tertiary': focusIndex === index && index % 3 === 2,
                        }
                        "
                >
                    <span
                        [ngClass]="{
                            'text-warn': index % 3 === 0,
                            'text-primary': index % 3 === 1,
                            'text-tertiary': index % 3 === 2,
                        }
                        "
                        >{{ timelineDate.date | date : "MM/yyyy" }}</span
                    >
                    <span class="text-xl font-bold"
                        >{{ timelineDate.name }}
                    </span>
                    <span class="text-sm">{{ timelineDate.description }}</span>
                </div>
            </div>
        </div>
    </div>
</company-csr-approach-card>

<company-csr-approach-edit-date
    (close)="drawerClosed()"
    [isCreating]="isCreating"
    [editedDateIndex]="editedDateIndex"
></company-csr-approach-edit-date>
