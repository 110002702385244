import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatViewModel, MissionUserMatchViewModel } from 'app/api';

export const MissionUserMatchActions = createActionGroup({
    source: 'MissionUserMatch',
    events: {
        'Load Potential Matches Request': props<{ missionId: number }>(),
        'Load Potential Matches Request Success': props<{
            potentialMatches: MissionUserMatchViewModel[];
        }>(),
        'Load Potential Matches Request Fail': props<{ error: string }>(),

        'Contact Potential Matche Request': props<{
            potentialMatch: MissionUserMatchViewModel;
        }>(),
        'Contact Potential Matche Request Success': props<{
            chat: ChatViewModel;
        }>(),
        'Contact Potential Matche Request Fail': props<{
            error: string;
        }>(),

        'Deny Match Request': props<{
            potentialMatch: MissionUserMatchViewModel;
        }>(),
        'Deny Match Request Success': emptyProps(),
        'Deny Match Request Fail': props<{
            error: string;
        }>(),

        'Reset Has Contacted Match': emptyProps(),
        'See Chat': emptyProps(),
    },
});
