import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { BslkJustify, BslkSize } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { FormControlComponent } from '../form-control-component';
import { BslkStarComponent } from './star/star.component';

export type Rating = 1 | 2 | 3 | 4 | 5;

@Component({
    selector: 'bslk-star-rating',
    standalone: true,
    templateUrl: './star-rating.component.html',
    imports: [
        BslkStarComponent,
        BslkInfoLabelComponent,
        MatTooltipModule,
        NgIf,
        TranslocoModule,
    ],
})
export class BslkStarRatingComponent
    extends FormControlComponent
    implements OnInit
{
    @Input() minLabel: string;
    @Input() maxLabel: string;
    @Input() size: BslkSize = 'medium';
    @Input() justify: BslkJustify = 'center';
    @Input() maxRating = 5;
    @Input() isLabelBold = true; //override default value

    get isFilled1() {
        return this.value >= 1;
    }

    get isFilled2() {
        return this.value >= 2;
    }

    get isFilled3() {
        return this.value >= 3;
    }

    get isFilled4() {
        return this.value >= 4;
    }

    get isFilled5() {
        return this.value >= 5;
    }

    onStarClick(rating: Rating) {
        this.setValue(rating);
    }
}
