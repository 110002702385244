<div
    [class.bg-warn]="color === 'warn' && isActive"
    [class.bg-primary]="color === 'primary' && isActive"
    [class.bg-tertiary]="color === 'tertiary' && isActive"
    [class.bg-error]="color === 'error' && isActive"
    [class.bg-warn-300]="color === 'warn' && !isActive"
    [class.bg-primary-300]="color === 'primary' && !isActive"
    [class.bg-tertiary-300]="color === 'tertiary' && !isActive"
    [class.bg-error-300]="color === 'error' && !isActive"
    [class.hover:bg-warn]="color === 'warn' && !isDisabled"
    [class.hover:bg-primary]="color === 'primary' && !isDisabled"
    [class.hover:bg-tertiary]="color === 'tertiary' && !isDisabled"
    [class.hover:bg-error]="color === 'error' && !isDisabled"
    [class.cursor-pointer]="!isDisabled"
    class="rounded-2xl flex py-4 xl:py-6 xxl:py-8 text-white relative overflow-hidden transition-colors duration-500"
>
    <div class="mx-auto font-bold text-2xl xl:text-3xl flex items-center">
        <mat-icon class="text-current mr-3" [svgIcon]="icon"></mat-icon>
        <ng-content></ng-content>
    </div>

    <mat-icon
        class="absolute text-current h-24 w-24 xl:h-36 xl:w-36 opacity-20 -rotate-12 -top-5 -left-5 xl:-left-10"
        [svgIcon]="bgIcon"
    ></mat-icon>

    <div *ngIf="isDisabled" class="absolute bottom-2 right-5">
        <bslk-tag
            [icon]="'heroicons_solid:lock-closed'"
            [textColor]="'warn'"
            [background]="'warn-light'"
            [size]="'very-small'"
            >{{ "companyadmindashboard.not-enabled" | transloco }}</bslk-tag
        >
    </div>
</div>
