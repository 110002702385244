<ng-container *ngrxLet="isCompanyAdmin$ as isCompanyAdmin">
    <ng-container *ngrxLet="csrApproach$ as csrApproach">
        <div
            *ngrxLet="isLoading$ as isLoading"
            class="p-4 md:p-6 xxl:p-8 w-full flex flex-col gap-4 md:gap-6 xxl:gap-8"
        >
            <company-csr-approach-description
                [isLoading]="isLoading"
                [isLoadingUpdate]="isLoadingUpdate$ | async"
                [isCompanyAdmin]="isCompanyAdmin"
                [csrApproach]="csrApproach"
            ></company-csr-approach-description>
            <company-csr-approach-matrix
                [isLoading]="isLoading"
                [isLoadingUpdate]="isLoadingUpdate$ | async"
                [isCompanyAdmin]="isCompanyAdmin"
                [csrApproach]="csrApproach"
            >
            </company-csr-approach-matrix>
            <company-csr-approach-values
                [isLoading]="isLoading"
                [isLoadingUpdate]="isLoadingUpdate$ | async"
                [isCompanyAdmin]="isCompanyAdmin"
                [csrApproach]="csrApproach"
            ></company-csr-approach-values>
            <company-csr-approach-pillars
                [isLoading]="isLoading"
                [isLoadingUpdate]="isLoadingUpdate$ | async"
                [isCompanyAdmin]="isCompanyAdmin"
                [csrApproach]="csrApproach"
            ></company-csr-approach-pillars>
            <company-csr-approach-metrics
                [isLoading]="isLoading"
                [isLoadingUpdate]="isLoadingUpdate$ | async"
                [isCompanyAdmin]="isCompanyAdmin"
                [csrApproach]="csrApproach"
            ></company-csr-approach-metrics>
            <company-csr-approach-timeline
                [isLoading]="isLoading"
                [isLoadingUpdate]="isLoadingUpdate$ | async"
                [isCompanyAdmin]="isCompanyAdmin"
                [csrApproach]="csrApproach"
            ></company-csr-approach-timeline>
        </div>
    </ng-container>
</ng-container>
