<div
    (click)="toggle()"
    [ngClass]="{
      'cursor-pointer': !disabled,
      'w-10 h-10': size === 'large',
      'w-6 h-6': size === 'medium',
      'border-primary text-primary': color === 'primary',
    }"
    class="border-2 rounded flex justify-center items-center"
>
    <mat-icon
        [ngClass]="{
          'w-0 h-0 min-w-0 min-h-0': !checked,
          'icon-size-5': size === 'medium' && checked,
          'icon-size-8': size === 'large' && checked,
        }"
        class="text-current transition-all duration-200"
        [svgIcon]="'heroicons_solid:check'"
    ></mat-icon>
</div>
