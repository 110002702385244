import { MicroActionViewModel } from 'app/api';
import { AppState } from '../..';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.microActionCommentList;

type MicroActionCommentListSelectors =
    RessourceListSelectors<MicroActionViewModel>;

export const MicroActionCommentListSelectors: MicroActionCommentListSelectors =
    {
        ...createRessourceListSelectors('microActionCommentList'),
    };
