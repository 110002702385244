import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CompanyCustomizationViewModel, CompanyViewModel } from 'app/api';

export const SetupActions = createActionGroup({
    source: 'Setup',
    events: {
        Reset: emptyProps(),
        'Load Company Request': emptyProps(),
        'Load Company Request Success': props<{
            response: CompanyViewModel;
        }>(),
        'Load Company Request Fail': props<{ error: string }>(),

        'Load Company Customization Request': emptyProps(),
        'Load Company Customization Request Success': props<{
            response: CompanyCustomizationViewModel;
        }>(),
        'Load Company Customization Request Fail': props<{ error: string }>(),

        'Update Administrative Contact Request': props<{ userId?: number }>(),
        'Update Administrative Contact Request Success': emptyProps(),
        'Update Administrative Contact Request Fail': props<{
            error: string;
        }>(),

        'Update Customization Request': props<{
            customization: CompanyCustomizationViewModel;
        }>(),
        'Update Customization Request Success': props<{
            customization: CompanyCustomizationViewModel;
        }>(),
        'Update Customization Request Fail': props<{
            error: string;
        }>(),
    },
});
