<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-280 z-999"
    fixed
    [mode]="'over'"
    (openedChanged)="openedChanged($event)"
    [name]="'viewMatrixPointDrawer'"
    [position]="'right'"
    #viewMatrixPointDrawer
>
    <div *ngIf="point" class="flex flex-col w-full max-h-screen bg-card">
        <div class="flex gap-2 flex-row items-center p-6 text-white bg-warn">
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ point.name }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="viewMatrixPointDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div class="bg-accent p-4 flex flex-col gap-5 h-full overflow-auto">
            <div [innerHTML]="point.description | sanitize"></div>
            <div class="border-t-2 w-full"></div>
            <div
                *ngIf="point.microActions?.length > 0"
                class="flex flex-col gap-3"
            >
                <span class="text-2xl font-bold">{{
                    "csrapproach.matrix.related-micro-actions" | transloco
                }}</span>
                <micro-action-grid-view
                    [microActions]="point.microActions"
                ></micro-action-grid-view>
            </div>
        </div>
    </div>
</fuse-drawer>
