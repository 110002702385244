import { DatePipe } from '@angular/common';
import { EChartTimePeriod } from 'app/api';
import { isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
export function areDatesOnSameDay(date1: DateTime, date2: DateTime) {
    if (isEmpty(date1) || isEmpty(date2) || !date1.hasSame) {
        return false;
    }
    return date1.hasSame(date2, 'day');
}

export function areStringDatesOnSameDay(date1: string, date2: string) {
    return areDatesOnSameDay(DateTime.fromISO(date1), DateTime.fromISO(date2));
}

export function addTimeToDate(date: DateTime, time: string): DateTime {
    const [hour, minute, second] = time.split(':').map(Number);
    return date.set({ hour, minute, second: second || 0 });
}

export function computeWorkingHoursBetweenDates(
    start: DateTime,
    end: DateTime
): number {
    let workingHours = 0;

    if (start.hasSame(end, 'day')) {
        workingHours = end.diff(start, 'hours').hours;
        return workingHours;
    }

    let currentDate = start.startOf('day');
    while (currentDate <= end.startOf('day')) {
        if (currentDate.weekday <= 5) {
            workingHours += 8;
        }
        currentDate = currentDate.plus({ days: 1 });
    }

    return Math.max(Math.round(workingHours), 0);
}

export function formatDateRange(
    datePipe: DatePipe,
    startDate: string,
    endDate: string
): string {
    // Convert dates to the desired format
    let startDay = datePipe.transform(startDate, 'd');
    let startMonth = datePipe.transform(startDate, 'MMM');
    let startYear = datePipe.transform(startDate, 'y');
    let startTime = datePipe.transform(startDate, 'HH:mm'); // 24-hour format

    let endDay = datePipe.transform(endDate, 'd');
    let endMonth = datePipe.transform(endDate, 'MMM');
    let endYear = datePipe.transform(endDate, 'y');
    let endTime = datePipe.transform(endDate, 'HH:mm'); // 24-hour format

    // If both dates are the same day
    if (
        startDay === endDay &&
        startMonth === endMonth &&
        startYear === endYear
    ) {
        return `${startDay} ${startMonth}. ${startYear}, ${startTime} - ${endTime}`;
    }

    // If both months are the same, only show one
    if (startMonth === endMonth && startYear === endYear) {
        startMonth = '';
    }

    // If both years are the same, only show one
    if (startYear === endYear) {
        startYear = '';
    }

    return `${startDay} ${startMonth} ${startYear} ⋅ ${endDay} ${endMonth}. ${endYear}`;
}

export function weekendsDatesFilter(d: DateTime): boolean {
    const day = d?.weekday;

    return day && day !== 6 && day !== 7;
}

export function fromTodayDatesFilter(d: DateTime): boolean {
    return d > DateTime.utc();
}

export function getChartDateRepresentation(
    datePipe: DatePipe,
    date: string,
    period: EChartTimePeriod
) {
    if (period === EChartTimePeriod.Year) {
        const month = datePipe.transform(date, 'MMMM');
        return month ? month.charAt(0).toUpperCase() + month.slice(1) : '';
    }
    if (period === EChartTimePeriod.Month) {
        return datePipe.transform(date, 'd');
    }
    return '';
}

export function calculateDateRangeCompletion(
    startDateStr: string,
    endDateStr: string
): number {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const totalDuration = endDate.getTime() - startDate.getTime();
    const elapsedDuration = new Date().getTime() - startDate.getTime();
    return Math.max(
        0,
        Math.min(100, Math.round((elapsedDuration / totalDuration) * 100))
    );
}
