<div
    [class.max-h-96]="disabled"
    [class.h-full]="fullHeight"
    [class]="'relative w-full rounded-xl shadow-lg overflow-' + overflow"
>
    <div
        [class.h-full]="fullHeight"
        class="bg-white flex flex-col justify-between px-4 md:px-8 py-6 relative"
    >
        <div class="flex items-center justify-between mb-4">
            <div class="flex items-center gap-2 font-bold text-2xl">
                <mat-icon
                    class="icon-size-7"
                    *ngIf="titleIcon"
                    [svgIcon]="titleIcon"
                ></mat-icon>
                <div *ngIf="title" [matTooltip]="title">
                    {{ title }}
                </div>
                <ng-content select="[title]"></ng-content>
            </div>

            <div>
                <ng-content *ngIf="!isLoading" select="[headerActions]">
                </ng-content>

                <mat-progress-spinner
                    *ngIf="isLoading"
                    [diameter]="35"
                    [mode]="'indeterminate'"
                >
                </mat-progress-spinner>
            </div>
        </div>

        <div class="w-full h-full flex flex-col">
            <ng-content></ng-content>
        </div>
    </div>

    <ng-content select="[footer]"></ng-content>

    <!-- Overlay for disabled card (visible overlay), or when is loading (invisible overlay to prevent multiple actions) -->
    <div
        *ngIf="disabled || isLoading"
        class="absolute z-10 top-0 left-0 right-0 bottom-0"
    >
        <div class="relative w-full h-full">
            <div
                [ngClass]="{
                    'bg-white-fade hover:opacity-10': disabled
                }"
                class="w-full h-full rounded-xl"
            ></div>
            <div
                *ngIf="disabled && disabledText"
                class="absolute top-0 left-0 right-0 h-full text-center flex flex-col justify-end"
            >
                <div class="font-semibold text-xl bg-primary py-2 text-white">
                    {{ disabledText }}
                </div>
            </div>
        </div>
    </div>
</div>
