<div class="flex flex-col w-full pb-2 px-4 md:px-6 xl:pb-6 xl:px-10">
    <div class="flex justify-between items-center">
        <div class="flex gap-4 items-center">
            <img
                class="w-20"
                [src]="'assets/basile-postures/' + basilePosture + '.png'"
            />
            <div class="flex flex-col gap-1">
                <div class="flex items-end">
                    <span class="text-lg">{{
                        "associationdashboard.welcome" | transloco
                    }}</span>
                    <span class="font-bold text-2xl"
                        >&nbsp;{{ displayedName$ | async }},</span
                    >
                </div>
                <span class="text-lg">{{
                    "associationdashboard.recap" | transloco
                }}</span>
            </div>
        </div>

        <!-- New Mission -->
        <a class="hidden md:flex gap-2" (click)="findVolunteers()">
            <button
                class="flex gap-2 font-semibold p-6 border border-solid border-primary"
                mat-flat-button
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:users'"
                    class="hidden lg:flex text-primary"
                ></mat-icon>
                {{ "shared.navigation.find-volunteers" | transloco }}
            </button>
        </a>
    </div>

    <div class="border-b-2 border-slate-300 w-full mb-6"></div>

    <div class="grid grid-cols-12 gap-8">
        <project-donations
            class="h-64 xxl:h-80 col-span-12"
            [ngClass]="
                (isProjectDonations$ | async) ? 'order-first' : 'md:col-span-6'
            "
        ></project-donations>
        <missions-kpis
            class="h-64 xxl:h-80 col-span-12 md:col-span-6"
        ></missions-kpis>
    </div>
    <pending-association-approvals class="mt-8"></pending-association-approvals>
</div>
