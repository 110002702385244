import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkCommentComponent } from '@bslk/components/comment/comment.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MicroActionCommentFormService } from 'app/shared/services/form/micro-action-comment-form.service';
import { MicroActionCommentListActions } from 'app/store/micro-action/micro-action-comment-list/micro-action-comment-list.actions';
import { MicroActionCommentListSelectors } from 'app/store/micro-action/micro-action-comment-list/micro-action-comment-list.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'micro-action-comments',
    templateUrl: './comments.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        BslkCommentComponent,
        BslkLengthInfoComponent,
        BslkTagButtonComponent,
        FormsModule,
        LetDirective,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        NgxSkeletonLoaderModule,
    ],
})
export class MicroActionCommentsComponent {
    @Input() microActionId: number;

    comments$ = this.store.select(MicroActionCommentListSelectors.selectData);
    isLoading$ = this.store.select(
        MicroActionCommentListSelectors.selectIsLoading
    );
    pageIndex$ = this.store.select(
        MicroActionCommentListSelectors.selectPageIndex
    );
    totalPages$ = this.store.select(
        MicroActionCommentListSelectors.selectTotalPages
    );
    totalCount$ = this.store.select(
        MicroActionCommentListSelectors.selectTotalCount
    );
    pageSize$ = this.store.select(
        MicroActionCommentListSelectors.selectPageSize
    );
    isCreating$ = this.store.select(
        MicroActionCommentListSelectors.selectIsCreating
    );
    userId$ = this.store.select(UserSelectors.selectUserId);
    isEditing$ = this.store.select(
        MicroActionCommentListSelectors.selectIsItemSelected
    );

    constructor(
        private store: Store,
        private microActionCommentFormService: MicroActionCommentFormService
    ) {}

    get formGroup() {
        return this.microActionCommentFormService.getFormGroup;
    }

    post(): void {
        if (this.formGroup.valid) {
            this.store.dispatch(MicroActionCommentListActions.CreateItem());
        }
    }

    update() {
        if (this.formGroup.valid) {
            this.store.dispatch(
                MicroActionCommentListActions.UpdateSelectedItem()
            );
        }
    }

    delete(id: number) {
        this.store.dispatch(MicroActionCommentListActions.DeleteItem({ id }));
    }

    onEdit(id: number) {
        this.store.dispatch(MicroActionCommentListActions.SelectItem({ id }));
    }

    onDelete(id: number) {
        this.store.dispatch(MicroActionCommentListActions.DeleteItem({ id }));
    }

    cancelEdit() {
        this.store.dispatch(MicroActionCommentListActions.UnselectItems());
        this.microActionCommentFormService.initForm();
    }
}
