import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MicroActionViewModel } from 'app/api';
import { MicroActionGridCardComponent } from 'app/shared/components/micro-action/micro-action-grid-card/micro-action-grid-card.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'micro-action-grid-view',
    templateUrl: './micro-action-grid-view.component.html',
    standalone: true,
    imports: [
        NgFor,
        MicroActionGridCardComponent,
        NgIf,
        NgxSkeletonLoaderModule,
    ],
})
export class MicroActionGridViewComponent implements OnInit {
    @Input() microActions: MicroActionViewModel[];
    @Input() isLoading = false;

    constructor() {}

    ngOnInit() {}
}
