import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store';
import { isNil } from 'lodash-es';

const selectState = (state: AppState) => state.missionUserMatch;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsLoadingContact = createSelector(
    selectState,
    (state) => state.isLoadingContact
);

const selectChat = createSelector(selectState, (state) => state.chat);

const selectPotentialMatches = createSelector(
    selectState,
    (state) => state.potentialMatches
);
const selectHasContactedMatch = createSelector(
    selectState,
    (state) => state.hasContactedMatch
);

const selectViewedMatch = createSelector(selectState, (state) =>
    state.potentialMatches
        ? state.potentialMatches[state.viewedMatchIndex]
        : null
);

const selectHasMorePotentialMatches = createSelector(
    selectViewedMatch,
    (viewedMatch) => !isNil(viewedMatch)
);

export const MissionUserMatchSelectors = {
    selectIsLoading,
    selectIsLoadingContact,
    selectPotentialMatches,
    selectViewedMatch,
    selectHasMorePotentialMatches,
    selectChat,
    selectHasContactedMatch,
};
