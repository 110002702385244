<bslk-classic-card
    *ngrxLet="isLoading$ as isLoading"
    [isLoading]="isLoading"
    [title]="'microactionview.comments-title' | transloco"
>
    <div *ngIf="!isLoading; else loading" class="flex flex-col gap-2">
        <div
            *ngrxLet="comments$ as comments"
            class="flex border-t border-x p-3 rounded-t-xl flex-col gap-5"
        >
            <bslk-comment
                *ngFor="let comment of comments"
                [comment]="comment"
                [canEdit]="comment.userId === (userId$ | async)"
                [canDelete]="comment.userId === (userId$ | async)"
                (onEdit)="onEdit($event)"
                (onDelete)="onDelete($event)"
            ></bslk-comment>

            <div
                *ngIf="comments?.length === 0"
                class="grow flex flex-col justify-center gap-5 text-lg items-center"
            >
                {{ "microactionview.be-first" | transloco }}
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left'"
                    class="text-current icon-size-12"
                ></mat-icon>
            </div>
        </div>

        <mat-paginator
            class="border rounded-b-xl"
            [ngClass]="{
                'pointer-events-none': isLoading$ | async
            }"
            [length]="totalCount$ | async"
            [pageIndex]="pageIndex$ | async"
            [pageSize]="pageSize$ | async"
            [hidePageSize]="true"
            (page)="pageChangeEvent($event)"
            [showFirstLastButtons]="true"
        ></mat-paginator>
    </div>

    <form [formGroup]="formGroup" class="flex flex-col gap-3 mt-5">
        <div class="flex flex-col">
            <mat-form-field
                floatLabel="always"
                subscriptSizing="dynamic"
                class="w-full mb-3"
            >
                <mat-label>{{
                    "microactionview.your-comment" | transloco
                }}</mat-label>
                <textarea
                    class="h-28 bg-white"
                    [formControlName]="'comment'"
                    matInput
                    [placeholder]="
                        'microactionview.what-do-you-have-to-say' | transloco
                    "
                >
                </textarea>
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.comment.value"
                [maxLength]="500"
            ></bslk-length-info>
        </div>

        <div *ngrxLet="isEditing$ as isEditing" class="flex gap-2 justify-end">
            <bslk-tag-button
                *ngIf="isEditing"
                (onClick)="cancelEdit()"
                [isLoading]="isLoading"
                [icon]="'heroicons_outline:x-mark'"
                >{{
                    "microactionview.cancel-edit" | transloco
                }}</bslk-tag-button
            >
            <bslk-tag-button
                *ngIf="isEditing"
                (onClick)="update()"
                [isLoading]="isLoading"
                [isDisabled]="formGroup.invalid"
                [icon]="'heroicons_outline:check'"
                >{{
                    "microactionview.edit-comment" | transloco
                }}</bslk-tag-button
            >
            <bslk-tag-button
                *ngIf="!isEditing"
                (onClick)="post()"
                [isLoading]="isLoading"
                [isDisabled]="formGroup.invalid"
                [icon]="'heroicons_outline:chat-bubble-bottom-center-text'"
                >{{
                    "microactionview.add-comment" | transloco
                }}</bslk-tag-button
            >
        </div>
    </form>
</bslk-classic-card>

<ng-template #loading>
    <div class="flex flex-col flex-1 overflow-auto px-6">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
