import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EFileExtension, EFileType, EFolderType, FileViewModel } from 'app/api';
import { FilesActions } from 'app/store/files/files.action';
import { FilesSelectors } from 'app/store/files/files.selectors';
import { Subject, takeUntil } from 'rxjs';
import { FileManagerDetailsComponent } from './details/my-files-details.component';

@Component({
    selector: 'my-files',
    templateUrl: './my-files.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        BslkTagComponent,
        FileManagerDetailsComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatTooltipModule,
        MatPaginatorModule,
        NgFor,
        NgIf,
        RouterLink,
        RouterOutlet,
        TranslocoModule,
    ],
})
export class FileManagerListComponent implements OnInit, OnDestroy {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;

    EFileExtension = EFileExtension;
    EFileType = EFileType;

    drawerMode: 'side' | 'over';

    unsubscribeAll: Subject<any> = new Subject<any>();

    hasData$ = this.store.select(FilesSelectors.selectHasData);
    hasSelectedItem$ = this.store.select(FilesSelectors.selectHasSelectedFile);
    isLoading$ = this.store.select(FilesSelectors.selectIsLoading);
    items$ = this.store.select(FilesSelectors.selectItems);
    pageIndex$ = this.store.select(FilesSelectors.selectPageIndex);
    pageSize$ = this.store.select(FilesSelectors.selectPageSize);
    selectedItem$ = this.store.select(FilesSelectors.selectSelectedFile);
    totalCount$ = this.store.select(FilesSelectors.selectTotalCount);
    totalPages$ = this.store.select(FilesSelectors.selectTotalPages);

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store,
        private fuseMediaWatcherService: FuseMediaWatcherService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.fuseMediaWatcherService
            .onMediaQueryChange$('(min-width: 1440px)')
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((state) => {
                // Calculate the drawer mode
                this.drawerMode = state.matches ? 'side' : 'over';

                this.changeDetectorRef.markForCheck();
            });

        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['folderType']) {
                    this.store.dispatch(
                        FilesActions.selectFolder({
                            folder: {
                                folderType: params['folderType'] as EFolderType,
                            },
                        })
                    );
                } else {
                    this.store.dispatch(FilesActions.reset());
                }
            });
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            FilesActions.changeFolderPage({ pageIndex: event.pageIndex })
        );
    }

    selectFile(file: FileViewModel) {
        this.store.dispatch(FilesActions.selectFile({ file }));
    }

    onDrawerClosed() {
        this.store.dispatch(FilesActions.unselectFile());
    }

    selectFolder(folder: FileViewModel) {
        this.store.dispatch(FilesActions.selectFolder({ folder }));
    }

    unselectFolder() {
        this.store.dispatch(FilesActions.unselectFolder());
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
