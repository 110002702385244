import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionSlotApprovalInfoViewModel } from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import {
    EAssociationUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { AssociationDashboardActions } from 'app/store/association/association-dashboard/association-dashboard.actions';
import { AssociationDashboardSelectors } from 'app/store/association/association-dashboard/association-dashboard.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'pending-association-approvals',
    templateUrl: './pending-association-approvals.component.html',
    standalone: true,
    imports: [
        LetDirective,
        AsyncPipe,
        NgIf,
        TranslocoModule,
        MatIconModule,
        NgFor,
        NgxSkeletonLoaderModule,
    ],
})
export class PendingAssociationApprovalsComponent implements OnInit {
    approvals$ = this.store.select(
        AssociationDashboardSelectors.selectKpiResult(
            'pending-association-approvals'
        )
    );

    isLoading$ = this.store.select(
        AssociationDashboardSelectors.selectIsLoading(
            'pending-association-approvals'
        )
    );

    constructor(
        private store: Store,
        private datePipe: DatePipe,
        private router: Router
    ) {}

    ngOnInit() {
        this.store.dispatch(
            AssociationDashboardActions.checkIfKpiIsLoaded({
                kpi: 'pending-association-approvals',
            })
        );
    }

    formatDateRange(startDate: string, endDate: string) {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    openSlot(approvalInfo: MissionSlotApprovalInfoViewModel) {
        this.router.navigateByUrl(
            UrlHelpers.getAssociationUrl(
                EAssociationUrl.AssociationMissionManagePage,
                {
                    id: approvalInfo.missionId,
                    missionSlotId: approvalInfo.missionSlotId,
                }
            )
        );
    }
}
