import { MicroActionViewModel } from 'app/api';
import { AppState } from '../..';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.microActionList;

type MicroActionListSelectors = RessourceListSelectors<MicroActionViewModel>;

export const MicroActionListSelectors: MicroActionListSelectors = {
    ...createRessourceListSelectors('microActionList'),
};
