import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ImageUploadDialogInput } from 'app/shared/components/file-upload/file-upload-dialog.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { SetupActions } from 'app/store/setup/setup.action';
import { SetupSelectors } from 'app/store/setup/setup.selectors';
import { isEmpty } from 'lodash-es';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'company-customization',
    templateUrl: './company-customization.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        LetDirective,
        BslkClassicButtonComponent,
        NgClass,
        FormsModule,
        BslkTagButtonComponent,
        ReactiveFormsModule,
        AsyncPipe,
        BslkClassicCardComponent,
        BslkInfoComponent,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        NgxSkeletonLoaderModule,
        NgIf,
    ],
})
export class CompanyCustomizationComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    formGroup: FormGroup;

    isLoadingUpdate$ = this.store.select(
        SetupSelectors.selectIsLoadingCustomizationUpdate
    );

    isLoadingCustomization$ = this.store.select(
        SetupSelectors.selectIsLoadingCustomization
    );

    customization$ = this.store
        .select(SetupSelectors.selectCustomization)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((customization) => {
            if (customization) {
                this.formGroup.patchValue(customization);
            }
        });

    get isFormGroupValid() {
        return this.formGroup.dirty;
    }

    get imageUrl() {
        if (!isEmpty(this.formGroup.value.imageAsBase64)) {
            return this.formGroup.value.imageAsBase64;
        }
        return this.formGroup.value.imageUrl;
    }

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    ngOnInit() {
        this.store.dispatch(SetupActions.loadCompanyCustomizationRequest());
        this.formGroup = this.fb.group({
            id: [0],
            primaryColor: ['#f4af4b', { nonNullable: true }],
            secondaryColor: ['#ee846f', { nonNullable: true }],
            imageAsBase64: [null],
            imageUrl: [null],
        });
    }

    save() {
        this.store.dispatch(
            SetupActions.updateCustomizationRequest({
                customization: this.formGroup.value,
            })
        );
    }

    resetImage() {
        this.formGroup.patchValue({
            imageAsBase64: null,
            imageUrl: null,
        });
        this.formGroup.markAsDirty();
    }

    openFileUploadDialog() {
        this.fileUploadDialogService.open({
            formControl: this.formGroup.controls.imageAsBase64,
            maxFileSize: 500 * 1024,
            infoTextKey: 'setup.info-logo',
        } as ImageUploadDialogInput);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
