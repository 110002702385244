import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ELoginType, LocationViewModel, UserViewModel } from 'app/api';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';
import { LocationFormService } from './location-form.service';

@Injectable({
    providedIn: 'root',
})
export class UserFormService
    extends LocationFormService
    implements IFormService<UserViewModel>
{
    private formGroup: FormGroup;

    constructor(protected formBuilder: FormBuilder) {
        super(formBuilder);
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.formBuilder.group(
            {
                id: [''],
                association: [null],
                company: [null],
                companyAgencyId: [null],
                dateArchived: [''],
                dateCreated: [''],
                dateUpdated: [''],
                department: [''],
                email: ['', Validators.required],
                familyName: [''],
                firstName: [''],
                gender: [''],
                isDirectContact: [false],
                signatureBase64: [null],
                jobTitle: [''],
                lastConnection: [''],
                location: this.initLocationForm(),
                loginType: [ELoginType.Google],
                managerEmail: ['', Validators.email],
                password: [''],
                phoneNumber: [''],
                skills: [[]],
                userRole: [''],
                experiencePoints: [0],
                isAssociationContactEnabled: [true],
            },
            { validators: CustomValidators.validateManagerEmail }
        );
        return this.formGroup;
    }

    updateForm(entity: UserViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        this.patchLocation(entity.location);
    }

    getEntity(): { [key: string]: UserViewModel } {
        const userViewModel = returnOnlyNonNullFields<UserViewModel>(
            this.formGroup.value
        ) as UserViewModel;

        userViewModel.location = returnOnlyNonNullFields<LocationViewModel>(
            userViewModel.location
        );

        return {
            userViewModel: returnOnlyNonNullFields<UserViewModel>(
                this.formGroup.value
            ),
        };
    }
}
