import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkSliderComponent } from '@bslk/components/form/slider/slider.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { EActivityField, EMicroActionType } from 'app/api';
import { MicroActionFormService } from 'app/shared/services/form/micro-action-form.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import {
    areDatesOnSameDay,
    fromTodayDatesFilter,
    weekendsDatesFilter,
} from 'app/shared/utils/date-helpers.utils';
import { MissionSlotUtils } from 'app/shared/utils/extensions/mission-slots/mission-slots.utils';
import { DateTime } from 'luxon';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'create-micro-action-step-0',
    standalone: true,
    templateUrl: './create-micro-action-step-0.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkInfoLabelComponent,
        BslkSliderComponent,
        LetDirective,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        BslkLengthInfoComponent,
        QuillEditorComponent,
        BslkClassicToggleComponent,
        BslkCardToggleComponent,
        MatDatepickerModule,
    ],
})
export class CreateMicroActionStep0Component implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    EActivityField = EActivityField;
    EMicroActionType = EMicroActionType;

    activityFieldsTranslationsLoaded: boolean = false;
    activityFieldsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    constructor(
        private translationService: TranslationService,
        private microActionFormService: MicroActionFormService
    ) {}

    get formGroup() {
        return (
            this.microActionFormService.getFormGroup ??
            this.microActionFormService.initForm()
        );
    }

    get displayTimePicker(): boolean {
        const startDate = this.formGroup.controls.startDate.value;
        const endDate = this.formGroup.controls.endDate.value;
        return areDatesOnSameDay(startDate, endDate);
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsAdvancedSelectItems = translatedItems;
                this.activityFieldsTranslationsLoaded = true;
            });

        if (this.formGroup) {
            MissionSlotUtils.subscribeToFormGroupValueChanges(
                this.formGroup,
                this.unsubscribeAll,
                false
            );
        }
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    getDatesFilter(date: DateTime) {
        return weekendsDatesFilter(date) && fromTodayDatesFilter(date);
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
