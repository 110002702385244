import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MicroActionCommentService, MicroActionService } from 'app/api';
import { CreateMicroActionDialogService } from 'app/modules/regular/micro-action/create-micro-action/create-micro-action.service';
import { RewardDialogService } from 'app/shared/components/dialog/reward/reward-dialog.service';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { MicroActionFormService } from 'app/shared/services/form/micro-action-form.service';
import {
    EAccountUrl,
    ERegularUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { HighlightActions } from 'app/store/highlight/highlight.action';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isEmpty, isNil } from 'lodash-es';
import { catchError, exhaustMap, map, mergeMap, of, tap } from 'rxjs';
import { AlertActions } from '../../alert/alert.actions';
import { MicroActionActions } from './micro-action.action';
import { MicroActionSelectors } from './micro-action.selectors';
@Injectable()
export class MicroActionEffects {
    openDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MicroActionActions.openDialog),
                tap(({ input }) => {
                    this.createMicroActionDialogService.open(input);
                })
            ),
        { dispatch: false }
    );

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MicroActionActions.closeDialog),
                tap(() => {
                    this.createMicroActionDialogService.close();
                })
            ),
        { dispatch: false }
    );

    createMicroAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.createMicroActionRequest),
            exhaustMap(() => {
                const microAction = this.microActionFormService.getEntity();
                return this.microActionService
                    .microActionPost(microAction)
                    .pipe(
                        map((id) =>
                            MicroActionActions.createMicroActionRequestSuccess({
                                id,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.createMicroActionRequestFail(
                                    {
                                        error: httpResponse.error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    openUpdateMicroActionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.editMicroAction),
            concatLatestFrom(() => [
                this.store.select(MicroActionSelectors.selectMicroAction),
            ]),
            tap(([, microAction]) => {
                this.microActionFormService.updateForm(microAction);
            }),
            map(() =>
                MicroActionActions.openDialog({
                    input: { isEdit: true, isFromEntityPage: true },
                })
            )
        )
    );

    updateMicroAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.updateMicroActionRequest),
            exhaustMap(() => {
                const microActionViewModel =
                    this.microActionFormService.getEntity();
                return this.microActionService
                    .microActionPut(microActionViewModel)
                    .pipe(
                        map(() =>
                            MicroActionActions.updateMicroActionRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.updateMicroActionRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    reloadOnUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MicroActionActions.updateMicroActionRequestSuccess,
                MicroActionActions.registerMicroActionRequestSuccess,
                MicroActionActions.unregisterMicroActionRequestSuccess
            ),
            concatLatestFrom(() => [
                this.store.select(MicroActionSelectors.selectMicroAction),
            ]),
            tap(() => {
                this.createMicroActionDialogService.close();
            }),
            map(([, microAction]) =>
                MicroActionActions.loadMicroActionRequest({
                    id: microAction.id,
                })
            )
        )
    );

    createMicroActionSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.createMicroActionRequestSuccess),
            tap(({ id }) => {
                this.createMicroActionDialogService.close();
                this.router.navigateByUrl(
                    UrlHelpers.getRegularUrl(
                        ERegularUrl.RegularMicroActionPage,
                        { id }
                    )
                );
            }),
            map(() => {
                return AlertActions.displaySuccess({
                    key: 'micro-action-create',
                });
            })
        )
    );

    createMicroActionFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MicroActionActions.createMicroActionRequestFail,
                MicroActionActions.updateMicroActionRequestFail
            ),
            map(() => {
                return AlertActions.displayGenericError();
            })
        )
    );

    loadMicroAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.loadMicroActionRequest),
            exhaustMap(({ id }) => {
                return this.microActionService.microActionIdGet({ id }).pipe(
                    map((response) =>
                        MicroActionActions.loadMicroActionRequestSuccess({
                            microAction: response,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MicroActionActions.loadMicroActionRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    showProfileNeedsCompletionBeforeRegistrationAlert$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.tryRegisterMicroActionRequest),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectCompanyHasAgencies),
                this.store.select(UserSelectors.selectAgencyId),
            ]),
            map(([{ id }, companyHasAgencies, userAgencyId]) => {
                if (companyHasAgencies && isNil(userAgencyId)) {
                    return AlertActions.displayInfo({
                        key: 'agency-needed',
                        action: HighlightActions.createHighlightEvent({
                            event: {
                                highlightedElement: 'account-agency',
                                targetUrl: UrlHelpers.getAccountUrl(
                                    EAccountUrl.AccountSettings
                                ),
                            },
                        }),
                    });
                }
                return MicroActionActions.registerMicroActionRequest({ id });
            })
        )
    );

    registerMicroAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.registerMicroActionRequest),
            exhaustMap(({ id }) => {
                return this.microActionService
                    .microActionIdRegisterPatch({ id })
                    .pipe(
                        map(() =>
                            MicroActionActions.registerMicroActionRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.registerMicroActionRequestFail(
                                    { error: httpResponse.error }
                                )
                            )
                        )
                    );
            })
        )
    );

    unregisterMicroAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.unregisterMicroActionRequest),
            exhaustMap(({ id }) => {
                return this.microActionService
                    .microActionIdUnregisterPatch({ id })
                    .pipe(
                        map(() =>
                            MicroActionActions.unregisterMicroActionRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.unregisterMicroActionRequestFail(
                                    { error: httpResponse.error }
                                )
                            )
                        )
                    );
            })
        )
    );

    registerSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.registerMicroActionRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({
                    key: 'micro-action-register',
                });
            })
        )
    );

    unregisterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.unregisterMicroActionRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({
                    key: 'micro-action-unregister',
                });
            })
        )
    );

    updateCover$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.updateCoverRequest),
            concatLatestFrom(() =>
                this.store.select(MicroActionSelectors.selectMicroAction)
            ),
            exhaustMap(([{ file }, microAction]) => {
                return this.microActionService
                    .microActionIdCoverPatch({ id: microAction.id, file })
                    .pipe(
                        map((url) =>
                            MicroActionActions.updateCoverRequestSuccess({
                                url,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.updateCoverRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateCoverSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.updateCoverRequestSuccess),
            map(() => {
                this.fileUploadDialogService.close();
                return AlertActions.displaySuccess({
                    key: 'micro-action-cover',
                });
            })
        )
    );

    archive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.archiveMicroActionRequest),
            exhaustMap(({ id }) => {
                return this.microActionService.microActionDelete({ id }).pipe(
                    map(() =>
                        MicroActionActions.archiveMicroActionRequestSuccess()
                    ),
                    catchError((httpResponse) =>
                        of(
                            MicroActionActions.archiveMicroActionRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    archiveSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.archiveMicroActionRequestSuccess),
            map(() => {
                this.router.navigateByUrl(
                    UrlHelpers.getRegularUrl(
                        ERegularUrl.RegularMicroActionsPage
                    )
                );
                return AlertActions.displaySuccess({
                    key: 'micro-action-archive',
                });
            })
        )
    );

    markActivityAsCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.markActivityAsCompletedRequest),
            exhaustMap(({ id }) => {
                return this.microActionService
                    .microActionActivityIdCompletedPatch({ id })
                    .pipe(
                        mergeMap((rewards) => {
                            const actions: any[] = [
                                MicroActionActions.markActivityAsCompletedRequestSuccess(),
                            ];

                            const hasGainedRewards = !isEmpty(rewards);
                            if (hasGainedRewards) {
                                this.rewardDialogService.open({
                                    rewards,
                                });
                                actions.push(UserActions.setHasGainedRewards());
                            }
                            return actions;
                        }),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.markActivityAsCompletedRequestFail(
                                    {
                                        error: httpResponse.error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    markActivityAsNotCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.unmarkActivityAsCompletedRequest),
            exhaustMap(({ id }) => {
                return this.microActionService
                    .microActionActivityIdNotCompletedPatch({ id })
                    .pipe(
                        map(() =>
                            MicroActionActions.unmarkActivityAsCompletedRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MicroActionActions.unmarkActivityAsCompletedRequestFail(
                                    {
                                        error: httpResponse.error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private microActionFormService: MicroActionFormService,
        private microActionService: MicroActionService,
        private microActionCommentService: MicroActionCommentService,
        private createMicroActionDialogService: CreateMicroActionDialogService,
        private router: Router,
        private fileUploadDialogService: FileUploadDialogService,
        private rewardDialogService: RewardDialogService
    ) {}
}
