<base-dialog
    (onClose)="close()"
    [title]="'csrapproach.matrix.edit-legends' | transloco"
    [showActions]="true"
>
    <div class="w-full h-full flex flex-col gap-5 justify-center" body>
        <mat-form-field
            *ngIf="legendsFormArray.length > 0"
            [subscriptSizing]="'dynamic'"
        >
            <mat-label>{{
                "csrapproach.matrix.edit-legend" | transloco
            }}</mat-label>
            <mat-select
                [(ngModel)]="editedLegendFormGroup"
                (selectionChange)="editedLegendChange($event)"
            >
                <mat-option
                    *ngFor="let legendFormGroup of legendsFormArray.controls"
                    [value]="legendFormGroup"
                >
                    {{ legendFormGroup.value.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="flex gap-2 justify-end">
            <bslk-tag-button
                *ngIf="editedLegendFormGroup"
                [icon]="'heroicons_outline:trash'"
                (onClick)="deleteLegend()"
                >{{
                    "csrapproach.matrix.delete-legend" | transloco
                }}</bslk-tag-button
            >
            <bslk-tag-button
                [icon]="'heroicons_outline:plus'"
                (onClick)="addLegend()"
                >{{
                    "csrapproach.matrix.add-legend" | transloco
                }}</bslk-tag-button
            >
        </div>

        <div class="border-t-2"></div>

        <form
            class="flex flex-col gap-2"
            *ngIf="editedLegendFormGroup"
            [formGroup]="editedLegendFormGroup"
        >
            <mat-form-field
                class="w-full"
                [subscriptSizing]="'dynamic'"
                [flotLabel]="'always'"
            >
                <mat-label>{{
                    "csrapproach.matrix.legend-name" | transloco
                }}</mat-label>
                <input
                    matInput
                    type="text"
                    [formControlName]="'name'"
                    required
                />
            </mat-form-field>

            <mat-form-field
                floatLabel="always"
                subscriptSizing="dynamic"
                class="w-full"
            >
                <mat-label>{{
                    "csrapproach.matrix.legend-color" | transloco
                }}</mat-label>
                <input matInput [formControlName]="'color'" type="color" />
            </mat-form-field>
        </form>
    </div>

    <div class="flex gap-3 justify-end" actions>
        <bslk-classic-button
            [icon]="'heroicons_outline:check'"
            [isLoading]="isLoading$ | async"
            [isDisabled]="!legendsFormArray.dirty || !legendsFormArray.valid"
            (onClick)="save()"
            >{{ "shared.common.validate" | transloco }}</bslk-classic-button
        >
        <button
            (click)="close()"
            [color]="'accent'"
            class="z-10 text-white m-auto"
            mat-flat-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            <span class="ml-1">{{ "shared.common.close" | transloco }}</span>
        </button>
    </div>
</base-dialog>
