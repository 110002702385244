import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    EActivityField,
    EMicroActionType,
    MicroActionViewModel,
} from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import {
    ConfirmationDialogComponent,
    ConfirmationDialogInput,
} from 'app/shared/components/dialog/confirmation/confirmation-dialog.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { CoverTemplateComponent } from 'app/shared/components/templates/cover-template/cover-template.component';
import { SanitizePipe } from 'app/shared/pipes/sanitize.pipe';
import { GoogleMapsApiService } from 'app/shared/services/api/google-maps-api.service';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { initializeMapWithLocation } from 'app/shared/utils/google-maps.utils';
import { MicroActionActions } from 'app/store/micro-action/micro-action/micro-action.action';
import { MicroActionSelectors } from 'app/store/micro-action/micro-action/micro-action.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { BslkTagButtonComponent } from '../../../../../@bslk/components/cards/tag-button/tag-button.component';
import { MicroActionActivitiesComponent } from './activities/activities.component';
import { MicroActionCommentsComponent } from './comments/comments.component';

@Component({
    selector: 'micro-action-view',
    templateUrl: './micro-action-view.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BasileAvatarComponent,
        BslkClassicButtonComponent,
        BslkClassicCardComponent,
        BslkInfoComponent,
        BslkTagButtonComponent,
        BslkTagButtonComponent,
        BslkTagComponent,
        CoverTemplateComponent,
        EnumTagListComponent,
        LetDirective,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        MicroActionActivitiesComponent,
        MicroActionCommentsComponent,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        SanitizePipe,
        TextFieldModule,
        TranslocoModule,
    ],
})
export class MicroActionViewComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('googleMaps') mapRef: ElementRef;

    EActivityField = EActivityField;
    EMicroActionType = EMicroActionType;
    sustainableDevelopmentGoals = sustainableDevelopmentGoals;

    unsubscribeAll: Subject<any> = new Subject<any>();
    locationSubscription: Subscription;
    showGoogleMaps = false;

    microAction$ = this.store.select(MicroActionSelectors.selectMicroAction);
    isLoading$ = this.store.select(MicroActionSelectors.selectIsLoading);
    isLoadingRegistration$ = this.store.select(
        MicroActionSelectors.selectIsLoadingRegistration
    );
    isLoadingArchive$ = this.store.select(
        MicroActionSelectors.selectIsLoadingArchive
    );
    isRegistered$ = this.store.select(MicroActionSelectors.selectIsRegistered);
    canEdit$ = this.store.select(MicroActionSelectors.selectCanEdit);
    locationWithoutAddress$ = this.store.select(
        MicroActionSelectors.selectLocationWithoutAddress
    );
    canRegister$ = this.store.select(MicroActionSelectors.selectCanRegister);

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private fileUploadDialogService: FileUploadDialogService,
        private googleMapsApiService: GoogleMapsApiService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    this.store.dispatch(
                        MicroActionActions.loadMicroActionRequest({
                            id: params['id'],
                        })
                    );
                    this.ngAfterViewInit();
                }
            });
    }

    ngAfterViewInit(): void {
        this.locationSubscription = this.store
            .select(MicroActionSelectors.selectLocation)
            .subscribe((location) => {
                if (location?.latitude && location?.longitude) {
                    this.showGoogleMaps = true;
                    this.googleMapsApiService
                        .loadLibrary({
                            core: true,
                            maps: true,
                            marker: true,
                        })
                        .then((libraries) => {
                            initializeMapWithLocation(
                                libraries,
                                this.mapRef,
                                location
                            );
                            this.locationSubscription.unsubscribe();
                        });
                }
            });
    }

    register(id: number) {
        this.store.dispatch(
            MicroActionActions.tryRegisterMicroActionRequest({ id })
        );
    }

    unregister(id: number) {
        this.store.dispatch(
            MicroActionActions.unregisterMicroActionRequest({ id })
        );
    }

    edit() {
        this.store.dispatch(MicroActionActions.editMicroAction());
    }

    archive(id: number) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                textKey: 'microactionview.delete-confirmation',
            } as ConfirmationDialogInput,
        });

        dialogRef.componentInstance.onConfirm
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.store.dispatch(
                    MicroActionActions.archiveMicroActionRequest({ id })
                );
            });
    }

    updateCover(): void {
        this.fileUploadDialogService.open({
            action: MicroActionActions.updateCoverRequest,
            isLoading$: this.store.select(
                MicroActionSelectors.selectIsUploadFileLoading
            ),
            maxFileSize: 2000 * 1024,
            infoTextKey: 'microactionview.info-cover',
        });
    }

    formatDateRange(microAction: MicroActionViewModel) {
        return formatDateRange(
            this.datePipe,
            microAction.startDate,
            microAction.endDate
        );
    }

    ngOnDestroy(): void {
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
