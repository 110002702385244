import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { BasileConfigurationViewModel } from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';

export type GenericCommentViewModel = {
    id: number;
    comment: string;
    dateCreated: string;
    dateUpdated?: string;
    basileConfiguration: BasileConfigurationViewModel;
    firstName: string;
    familyName?: string;
};

@Component({
    selector: 'bslk-comment',
    templateUrl: './comment.component.html',
    standalone: true,
    imports: [
        DatePipe,
        BasileAvatarComponent,
        MatIconModule,
        NgIf,
        TranslocoModule,
        MatTooltipModule,
    ],
})
export class BslkCommentComponent {
    @Input() comment: GenericCommentViewModel;
    @Input() isPreview = false;
    @Input() canEdit = false;
    @Input() canDelete = false;

    @Output() onEdit = new EventEmitter<number>();
    @Output() onDelete = new EventEmitter<number>();

    edit() {
        this.onEdit.emit(this.comment.id);
    }

    remove() {
        this.onDelete.emit(this.comment.id);
    }
}
