<base-dialog
    (onClose)="close()"
    [title]="'donationgift.use-my-gift-donation' | transloco"
    [showActions]="true"
>
    <div class="w-full h-full flex flex-col gap-4 justify-center" body>
        <div class="border">
            <img
                *ngIf="project.images.length > 0"
                class="h-20 lg:h-30 xxl:h-40 object-cover"
                [src]="project.images[0].url"
                alt="Cover image"
            />

            <div
                *ngIf="project.images.length === 0"
                class="h-20 lg:h-30 xxl:h-40 object-cover flex items-center border-b-2"
            >
                <span class="text-4xl text-gray-400 mx-auto">{{
                    "missionview.no-cover" | transloco
                }}</span>
            </div>
            <div
                class="flex flex-col flex-0 lg:flex-row items-center max-w-7xl w-full mx-auto px-2 md:px-8 py-2 mb-1 bg-card"
            >
                <!-- Association Logo -->
                <div class="-mt-22 lg:-mt-18 relative">
                    <classic-avatar
                        [size]="24"
                        [borderSize]="4"
                        [avatarUrl]="project.association.logoUrl"
                        [fallbackLetter]="project.association.name.at(0)"
                    >
                    </classic-avatar>
                </div>

                <!-- Details -->
                <div
                    class="flex flex-col items-center lg:items-start text-center lg:text-left lg:ml-8"
                >
                    <div class="text-2xl font-bold max-w-120 p-4 md:p-0">
                        {{ project.name }}
                    </div>
                    <div class="font-bold text-primary">
                        {{ project.association.name }}
                    </div>
                </div>
            </div>
        </div>

        <fuse-alert type="info">
            <span *ngIf="data.isOneEnvelope">
                {{ "donationgift.envelope-info" | transloco }}</span
            >
            <span *ngIf="!data.isOneEnvelope">
                {{ "donationgift.gift-info" | transloco }}</span
            >
        </fuse-alert>

        <form [formGroup]="formGroup" class="flex flex-col gap-2">
            <mat-form-field floatLabel="always" class="w-full">
                <mat-label>{{ "donationgift.my-email" | transloco }}</mat-label>
                <input
                    [readonly]="isLoggedIn"
                    [placeholder]="'donationgift.stay-informed' | transloco"
                    matInput
                    [formControlName]="'email'"
                />
                <mat-error>
                    {{ "donationgift.email-required" | transloco }}
                </mat-error>
            </mat-form-field>

            <div class="flex w-full gap-2">
                <mat-form-field class="grow" floatLabel="always">
                    <mat-label>{{
                        "donationgift.first-name" | transloco
                    }}</mat-label>
                    <input
                        [readonly]="isLoggedIn"
                        [placeholder]="'Basile'"
                        matInput
                        [formControlName]="'firstName'"
                    />
                    <mat-error>
                        {{ "donationgift.first-name-required" | transloco }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="grow" floatLabel="always">
                    <mat-label>{{
                        "donationgift.family-name" | transloco
                    }}</mat-label>
                    <input
                        [readonly]="isLoggedIn"
                        [placeholder]="'Bertrand'"
                        matInput
                        [formControlName]="'familyName'"
                    />
                    <mat-error>
                        {{ "donationgift.family-name-required" | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="flex flex-col gap-1">
                <mat-form-field
                    floatLabel="always"
                    subscriptSizing="dynamic"
                    class="w-full"
                >
                    <mat-label>{{
                        "donationgift.why-this-project" | transloco
                    }}</mat-label>
                    <textarea matInput [formControlName]="'comment'"></textarea>
                </mat-form-field>
                <bslk-length-info
                    [maxLength]="300"
                    [text]="formGroup.controls.comment.value"
                ></bslk-length-info>
            </div>

            <mat-form-field
                *ngIf="
                    campaign.whitelistType ===
                    EDonationCampaignWhitelistType.Code
                "
                floatLabel="always"
                subscriptSizing="dynamic"
                class="w-full"
            >
                <mat-label>{{ "donationgift.code" | transloco }}</mat-label>
                <input
                    [placeholder]="'donationgift.example-code' | transloco"
                    matInput
                    [formControlName]="'code'"
                />
            </mat-form-field>
            <span
                *ngIf="submitted && formGroup.hasError('emptyCode')"
                class="text-primary text-sm"
                >{{ "donationgift.errors.CodeRequired" | transloco }}</span
            >
            <ng-container *ngrxLet="error$ as error">
                <div *ngIf="error" class="flex justify-center mt-4">
                    <fuse-alert class="max-w-120" type="error">{{
                        "donationgift.errors." + error | transloco
                    }}</fuse-alert>
                </div>
            </ng-container>
        </form>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (click)="submit()"
            [isLoading]="isLoading$ | async"
            [icon]="'heroicons_outline:heart'"
            >{{
                "donationgift.choose-this-project" | transloco
            }}</bslk-classic-button
        >
    </div>
</base-dialog>
