<div class="w-full" *ngrxLet="microAction$ as microAction">
    <cover-template
        *ngrxLet="isLoading$ as isLoading"
        [coverUrl]="microAction?.coverUrl"
        [title]="microAction?.name"
        [titleIcon]="
            microAction
                ? microAction.type === EMicroActionType.Awareness
                    ? 'heroicons_solid:academic-cap'
                    : 'feather:target'
                : null
        "
        [isLoading]="isLoading"
        [hideLogo]="true"
    >
        <!-- Cover actions -->
        <div *ngIf="!isLoading" class="flex gap-5" coverActions>
            <!-- Activity fields -->
            <enum-tag-list
                class="hidden md:flex"
                *ngIf="!isLoading"
                [tags]="microAction.activityFields"
                [enum]="EActivityField"
                [maxTagsVisible]="3"
            ></enum-tag-list>

            <bslk-tag-button
                *ngIf="canEdit$ | async"
                [class.animate-wizz]="!microAction.coverUrl"
                [icon]="'heroicons_outline:photo'"
                (onClick)="updateCover()"
                >{{
                    "microactionview." +
                        (microAction.coverUrl ? "update" : "add") +
                        "-cover" | transloco
                }}</bslk-tag-button
            >
        </div>

        <!-- Regular Header actions -->
        <div
            *ngrxLet="isRegistered$ as isRegistered"
            class="ml-auto flex gap-x-4"
            headerActions
        >
            <!-- Register -->
            <bslk-classic-button
                *ngIf="!isRegistered && !isLoading"
                (onClick)="register(microAction.id)"
                [icon]="'heroicons_outline:bolt'"
                [isLoading]="isLoadingRegistration$ | async"
                [isDisabled]="!(canRegister$ | async)"
                [disabledTooltip]="'microactionview.full-tooltip' | transloco"
            >
                {{ "microactionview.register" | transloco }}
            </bslk-classic-button>
            <bslk-tag
                *ngIf="!(canRegister$ | async)"
                [icon]="'heroicons_outline:exclamation-triangle'"
                [background]="'warn'"
                >{{ "microactionview.full" | transloco }}</bslk-tag
            >

            <!-- Unregister -->
            <bslk-classic-button
                *ngIf="isRegistered && !isLoading && !(canEdit$ | async)"
                (onClick)="unregister(microAction.id)"
                [icon]="'heroicons_outline:x-mark'"
                [isLoading]="isLoadingRegistration$ | async"
            >
                {{ "microactionview.unregister" | transloco }}
            </bslk-classic-button>

            <!-- Edit -->
            <bslk-classic-button
                *ngIf="!isLoading && (canEdit$ | async)"
                [background]="'warn'"
                (onClick)="edit()"
                [icon]="'heroicons_outline:pencil'"
            >
                {{ "microactionview.edit" | transloco }}
            </bslk-classic-button>

            <!-- Archive -->
            <bslk-classic-button
                *ngIf="!isLoading && (canEdit$ | async)"
                [background]="'primary'"
                (onClick)="archive(microAction.id)"
                [icon]="'heroicons_outline:trash'"
                [isLoading]="isLoadingArchive$ | async"
                >{{ "microactionview.delete" | transloco }}</bslk-classic-button
            >
        </div>

        <!-- Left Column -->
        <div class="w-full flex flex-col gap-5" leftColumn>
            <bslk-classic-card
                *ngIf="!isLoading; else skeleton50"
                [title]="'microactionview.location' | transloco"
            >
                <div class="flex flex-col gap-y-3">
                    <div *ngIf="microAction.isRemote">
                        <div class="flex items-center">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:computer-desktop'"
                            ></mat-icon>
                            <span class="leading-none">{{
                                "microactionview.remote" | transloco
                            }}</span>
                        </div>
                    </div>
                    <div *ngIf="microAction.location" class="flex flex-col">
                        <div class="flex items-center mb-3">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:map-pin'"
                            ></mat-icon>
                            <span class="leading-none">{{
                                locationWithoutAddress$ | async
                            }}</span>
                        </div>
                        <div class="flex items-center">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:home'"
                            ></mat-icon>
                            <span class="leading-none">{{
                                microAction.location.address
                            }}</span>
                        </div>
                        <div
                            *ngIf="showGoogleMaps"
                            #googleMaps
                            class="mt-4 w-full h-60"
                        ></div>
                    </div>
                </div>
            </bslk-classic-card>

            <!-- Slots -->
            <bslk-classic-card
                *ngIf="!isLoading; else skeleton80"
                [title]="'microactionview.dates' | transloco"
                class="w-full"
            >
                <div class="flex gap-2 flex-col">
                    <div class="flex items-center">
                        <mat-icon
                            class="icon-size-4 mr-1"
                            [svgIcon]="'heroicons_solid:calendar'"
                        ></mat-icon>
                        <span class="font-bold">{{
                            formatDateRange(microAction)
                        }}</span>
                    </div>
                    <div
                        *ngIf="microAction.maximumSlots"
                        class="flex items-center"
                    >
                        <mat-icon
                            class="icon-size-4 mr-1"
                            [svgIcon]="'heroicons_solid:users'"
                        ></mat-icon>
                        <span class="font-bold"
                            >{{
                                microAction.maximumSlots -
                                    microAction.users.length
                            }}
                            {{ "microactionview.slots-left" | transloco }}
                        </span>
                    </div>
                </div>
            </bslk-classic-card>

            <!-- Participants -->
            <ng-container *ngIf="!isLoading; else skeleton30">
                <bslk-classic-card
                    *ngIf="microAction?.createdBy"
                    [title]="'microactionview.participants' | transloco"
                    class="flex w-full"
                >
                    <div class="flex flex-col gap-4">
                        <div
                            *ngFor="let user of microAction.users"
                            class="flex items-center gap-3"
                        >
                            <basile-avatar
                                class="w-20 h-20"
                                [configuration]="user.basileConfiguration"
                            ></basile-avatar>

                            <div class="flex flex-col">
                                <div class="flex gap-2">
                                    <span class="text-lg font-semibold">{{
                                        user.name
                                    }}</span>
                                    <bslk-tag
                                        *ngIf="
                                            user.id === microAction.createdById
                                        "
                                        [icon]="'heroicons_outline:key'"
                                        [size]="'very-small'"
                                    >
                                        {{
                                            "microactionview.admin" | transloco
                                        }}
                                    </bslk-tag>
                                </div>

                                <span>{{ user.jobTitle }}</span>
                            </div>
                        </div>
                    </div>
                </bslk-classic-card>
            </ng-container>
        </div>

        <!-- Main -->

        <div class="flex w-full flex-col gap-y-5">
            <!-- Mission Description -->
            <bslk-classic-card
                class="w-full"
                *ngIf="!isLoading; else skeleton50"
                [title]="'microactionview.description' | transloco"
            >
                <div
                    class="flex flex-col gap-y-3 items-start px-6 overflow-x-scroll"
                >
                    <div [innerHTML]="microAction.description | sanitize"></div>
                    <div class="w-full border-t">
                        <div
                            class="w-full flex items-center my-3 text-lg font-semibold"
                        >
                            <mat-icon
                                class="text-current mr-2"
                                [svgIcon]="'heroicons_outline:bolt'"
                            ></mat-icon>
                            {{ "microactionview.your-impact" | transloco }}
                        </div>
                        <div class="flex flex-col"></div>
                        <span class="text-xl">
                            {{ microAction.impact }}
                        </span>
                        <div class="flex flex-wrap gap-2 mt-2">
                            <span class="text-sm"
                                >{{ "microactionview.sdg-impact" | transloco }}
                                <span class="font-semibold">{{
                                    "microactionview.sdgs" | transloco
                                }}</span
                                ><bslk-info
                                    [tooltip]="
                                        'microactionview.sdg-info' | transloco
                                    "
                                ></bslk-info>
                            </span>
                            <bslk-tag
                                *ngFor="
                                    let sdg of microAction.sustainableDevelopmentGoals
                                "
                                [size]="'small'"
                                [icon]="sustainableDevelopmentGoals[sdg].icon"
                                [background]="null"
                                [backgroundHexaColor]="
                                    sustainableDevelopmentGoals[sdg].color
                                "
                            >
                                {{
                                    "enums.ESustainableDevelopmentGoal." + sdg
                                        | transloco
                                }}
                            </bslk-tag>
                        </div>
                    </div>
                </div>
            </bslk-classic-card>

            <micro-action-activities
                [activities]="microAction?.activities"
            ></micro-action-activities>

            <micro-action-comments
                [microActionId]="microAction?.id"
                [comments]="microAction?.comments"
            >
            </micro-action-comments>
        </div>
    </cover-template>
</div>
