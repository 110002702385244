import { FuseNavigationItem } from '@fuse/components/navigation';
import { createSelector } from '@ngrx/store';
import { EFeature, EUserRole } from 'app/api';
import {
    EAssociationUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { environment } from 'environments/environment';
import { AppState } from '..';
import { AssociationSelectors } from '../association/association/association.selectors';
import { DialogActions } from '../dialog/dialog.action';
import { HighlightSelectors } from '../highlight/highlight.selectors';

const selectState = (state: AppState) => state.navigation;

const selectUserRole = createSelector(selectState, (state) => state.userRole);
const selectAllowedFeatures = createSelector(
    selectState,
    (state) => state.allowedFeatures
);

const selectLayout = createSelector(selectUserRole, (userRole) => {
    if (userRole === EUserRole.Regular) {
        return 'regular';
    } else if (userRole === EUserRole.External) {
        return 'external';
    } else if (userRole === EUserRole.CompanyAdmin) {
        return 'company-admin';
    } else if (userRole === EUserRole.SuperAdmin) {
        return 'admin';
    } else if (userRole === EUserRole.AssoAdmin) {
        return 'association-admin';
    }
    return 'empty';
});

const selectRegularNavigation = createSelector(
    selectAllowedFeatures,
    (allowedFeatures) => {
        const navigationItems: FuseNavigationItem[] = [];
        navigationItems.push({
            id: 'dashboard',
            title: 'dashboard',
            type: 'basic',
            icon: 'heroicons_outline:tv',
            link: '/dashboard',
        });
        if (allowedFeatures.includes(EFeature.SkillsSponsorship)) {
            navigationItems.push({
                id: 'missions',
                title: 'missions',
                type: 'basic',
                icon: 'heroicons_outline:hand-raised',
                link: '/missions',
            });
        }
        if (allowedFeatures.includes(EFeature.Awareness)) {
            navigationItems.push({
                id: 'awareness',
                title: 'awareness',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/awareness/courses',
            });
            navigationItems.push({
                id: 'dual-quiz',
                title: 'dual-quiz',
                type: 'basic',
                icon: 'heroicons_outline:question-mark-circle',
                link: '/s/dual-quiz',
            });
        }
        if (allowedFeatures.includes(EFeature.SkillsSponsorship)) {
            navigationItems.push({
                id: 'my-files',
                title: 'my-files',
                type: 'basic',
                icon: 'heroicons_outline:folder',
                link: '/s/my-files',
            });
        }
        navigationItems.push({
            id: 'csr-approach',
            title: 'csr-approach',
            type: 'basic',
            icon: 'heroicons_outline:sun',
            link: '/s/csr-approach',
        });
        navigationItems.push({
            id: 'my-basile',
            title: 'avatar',
            type: 'basic',
            icon: 'mat_outline:face',
            link: '/s/my-basile',
        });

        return navigationItems;
    }
);

const selectExternalNavigaton = createSelector(
    selectAllowedFeatures,
    (allowedFeatures) => {
        const navigationItems: FuseNavigationItem[] = [];
        navigationItems.push({
            id: 'dual-quiz',
            title: 'dual-quiz',
            type: 'basic',
            icon: 'heroicons_outline:question-mark-circle',
            link: '/s/dual-quiz',
        });
        navigationItems.push({
            id: 'my-basile',
            title: 'avatar',
            type: 'basic',
            icon: 'mat_outline:face',
            link: '/s/my-basile',
        });

        return navigationItems;
    }
);

const selectCompanyAdminNavigation = createSelector(
    selectAllowedFeatures,
    (allowedFeatures) => {
        const navigationItems: FuseNavigationItem[] = [];
        navigationItems.push({
            id: 'dashboard',
            title: 'dashboard',
            type: 'basic',
            icon: 'heroicons_outline:tv',
            link: '/ca/dashboard',
        });
        if (allowedFeatures.includes(EFeature.SkillsSponsorship)) {
            navigationItems.push({
                id: 'filters',
                title: 'filters',
                type: 'basic',
                icon: 'heroicons_outline:funnel',
                link: '/ca/filters',
            });
        }
        if (allowedFeatures.includes(EFeature.Awareness)) {
            navigationItems.push({
                id: 'awareness',
                title: 'awareness',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/ca/awareness/campaign',
            });
        }
        if (allowedFeatures.includes(EFeature.DonationGifts)) {
            navigationItems.push({
                id: 'donation-campaigns',
                title: 'donation-campaigns',
                type: 'basic',
                icon: 'heroicons_outline:banknotes',
                link: '/ca/donation-campaigns',
            });
        }
        navigationItems.push({
            id: 'csr-approach',
            title: 'csr-approach',
            type: 'basic',
            icon: 'heroicons_outline:sun',
            link: '/s/csr-approach',
        });
        navigationItems.push({
            id: 'my-files',
            title: 'my-files',
            type: 'basic',
            icon: 'heroicons_outline:folder',
            link: '/s/my-files',
        });
        navigationItems.push({
            id: 'setup',
            title: 'setup',
            type: 'basic',
            icon: 'feather:save',
            link: '/ca/setup',
        });
        return navigationItems;
    }
);

const selectAdminNavigation = createSelector(() => {
    const navigationItems: FuseNavigationItem[] = [
        {
            id: 'dashboard',
            title: 'dashboard',
            type: 'basic',
            icon: 'heroicons_outline:tv',
            link: '/sa/dashboard',
        },
        {
            id: 'companies',
            title: 'companies',
            type: 'basic',
            icon: 'heroicons_outline:building-storefront',
            link: '/sa/companies',
        },
        {
            id: 'associations',
            title: 'associations',
            type: 'basic',
            icon: 'heroicons_outline:home',
            link: '/sa/associations',
        },
        {
            id: 'users',
            title: 'users',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/sa/users',
        },
        {
            id: 'missions',
            title: 'missions',
            type: 'basic',
            icon: 'heroicons_outline:hand-raised',
            link: '/missions',
        },
        {
            id: 'projects',
            title: 'projects',
            type: 'basic',
            icon: 'heroicons_outline:banknotes',
            link: '/sa/projects',
        },
        {
            id: 'questions',
            title: 'questions',
            type: 'basic',
            icon: 'heroicons_outline:question-mark-circle',
            link: '/sa/questions',
        },
    ];

    if (!environment.production) {
        navigationItems.push({
            id: 'demonstration',
            title: 'demonstration',
            type: 'basic',
            icon: 'heroicons_outline:play',
            link: '/sa/demonstration',
        });
    }

    return navigationItems;
});

const selectAssociationAdminNavigation = createSelector(
    AssociationSelectors.selectCanCreateMission,
    (canCreateMission) => {
        const navigationItems: FuseNavigationItem[] = [];
        navigationItems.push(
            {
                id: 'create-mission',
                title: 'create-mission',
                type: 'basic',
                icon: 'heroicons_outline:plus-circle',
                disabled: !canCreateMission,
                disabledTooltip:
                    'shared.onboarding-progress.tasks.mission-disabled',
                action: DialogActions.openMissionOrProjectDialog(),
                highlightElementSelector$:
                    HighlightSelectors.selectIsElementHighlighted(
                        'association-create-mission'
                    ),
                highlight: true,
            },
            {
                id: 'dashboard',
                title: 'dashboard',
                type: 'basic',
                icon: 'heroicons_outline:tv',
                link: '/a/dashboard',
            },
            {
                id: 'missions',
                title: 'missions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-list',
                link: '/a/missions',
            },
            {
                id: 'find-volunteers',
                title: 'find-volunteers',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/a/find-volunteers',
            },
            {
                id: 'projects',
                title: 'projects',
                type: 'basic',
                icon: 'heroicons_outline:banknotes',
                link: '/a/projects',
            },
            {
                id: 'my-organization',
                title: 'my-organization',
                type: 'collapsable',
                icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'organization-view',
                        title: 'organization-view',
                        icon: 'heroicons_outline:eye',
                        type: 'basic',
                        link: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationViewPage
                        ),
                    },
                    {
                        id: 'organization-edit',
                        title: 'organization-edit',
                        icon: 'heroicons_outline:pencil',
                        type: 'basic',
                        link: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationEditPage
                        ),
                    },
                ],
            },
            {
                id: 'my-files',
                title: 'my-files',
                type: 'basic',
                icon: 'heroicons_outline:folder',
                link: '/s/my-files',
            },
            {
                id: 'association-users',
                title: 'association-users',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/a/users',
            }
        );
        return navigationItems;
    }
);

const selectNavigation = createSelector(
    selectRegularNavigation,
    selectCompanyAdminNavigation,
    selectAdminNavigation,
    selectAssociationAdminNavigation,
    selectExternalNavigaton,
    (
        regularNav,
        companyAdminNav,
        adminNav,
        associationAdminNav,
        extarnalNav
    ) => {
        return {
            regular: regularNav,
            companyAdmin: companyAdminNav,
            admin: adminNav,
            associationAdmin: associationAdminNav,
            external: extarnalNav,
        };
    }
);

export const NavigationSelectors = {
    selectState,
    selectLayout,
    selectNavigation,
};
