import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { MicroActionListActions } from 'app/store/micro-action/micro-action-list/micro-action-list.actions';
import { MicroActionListSelectors } from 'app/store/micro-action/micro-action-list/micro-action-list.selectors';
import { CreateMicroActionDialogService } from '../create-micro-action/create-micro-action.service';
import { MicroActionGridViewComponent } from './micro-action-grid-view/micro-action-grid-view.component';

@Component({
    selector: 'app-micro-action-list',
    templateUrl: './micro-action-list.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        TextFieldModule,
        BslkTagButtonComponent,
        MicroActionGridViewComponent,
        AsyncPipe,
        NgIf,
        NgClass,
        TranslocoModule,
        FormsModule,
        MatPaginatorModule,
    ],
})
export class MicroActionListComponent implements OnInit {
    microActions$ = this.store.select(MicroActionListSelectors.selectData);
    isLoading$ = this.store.select(MicroActionListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(MicroActionListSelectors.selectPageIndex);
    totalPages$ = this.store.select(MicroActionListSelectors.selectTotalPages);
    totalCount$ = this.store.select(MicroActionListSelectors.selectTotalCount);
    pageSize$ = this.store.select(MicroActionListSelectors.selectPageSize);

    constructor(
        private store: Store,
        private createMicroActionDialogService: CreateMicroActionDialogService
    ) {}

    ngOnInit() {
        this.store.dispatch(
            MicroActionListActions.InitializeWithFilters({
                filters: [
                    {
                        key: 'minimalIncludes',
                        value: true,
                    },
                ],
            })
        );
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            MicroActionListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    onlyMineChanged(event: MatCheckboxChange) {
        if (event.checked) {
            this.store.dispatch(
                MicroActionListActions.FilterListRequest({
                    filters: [{ key: 'onlyMine', value: true }],
                })
            );
        } else {
            this.store.dispatch(
                MicroActionListActions.FilterListRequest({
                    filters: [{ key: 'onlyMine', value: false }],
                })
            );
        }
    }

    openCreateMicroActionDialog() {
        this.createMicroActionDialogService.open({
            isEdit: false,
            isFromEntityPage: false,
        });
    }
}
