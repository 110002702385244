import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MicroActionService } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { MicroActionFormService } from 'app/shared/services/form/micro-action-form.service';
import {
    createDeleteItemEffect,
    createLoadItemRequestEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { MicroActionListActions } from './micro-action-list.actions';
import { MicroActionListSelectors } from './micro-action-list.selectors';
@Injectable()
export class MicroActionListEffects {
    loadMicroActions$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.microActionService,
        MicroActionListActions,
        MicroActionListSelectors,
        this.microActionService.microActionGet
    );

    loadMission$ = createLoadItemRequestEffect(
        this.store,
        this.actions$,
        this.microActionService,
        MicroActionListActions,
        MicroActionListSelectors,
        this.microActionService.microActionIdGet
    );

    reload$ = createReloadEffect(this.actions$, MicroActionListActions);

    deleteMission$ = createDeleteItemEffect(
        this.microActionService,
        this.actions$,
        MicroActionListActions,
        'micro-action-delete',
        this.microActionService.microActionDelete
    );

    update$ = createUpdateSelectedItemEffect(
        this.microActionService,
        this.actions$,
        MicroActionListActions,
        this.microActionFormService,
        'micro-action-update',
        this.microActionService.microActionPut
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private microActionService: MicroActionService,
        private fileUploadDialogService: FileUploadDialogService,
        private microActionFormService: MicroActionFormService
    ) {}
}
