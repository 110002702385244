import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ESkill, MissionUserMatchViewModel } from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { MissionUserMatchActions } from 'app/store/mission/mission-user-match/mission-user-match.action';
import { MissionUserMatchSelectors } from 'app/store/mission/mission-user-match/mission-user-match.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ContactMatchDialogService } from './contact-match-dialog/contact-match-dialog.service';

@Component({
    selector: 'potential-match',
    standalone: true,
    templateUrl: './potential-match.component.html',
    imports: [
        TranslocoModule,
        LetDirective,
        AsyncPipe,
        NgIf,
        JsonPipe,
        NgClass,
        MatIconModule,
        SingleSelectAsyncComponent,
        BasileAvatarComponent,
        NgxSkeletonLoaderModule,
        EnumTagListComponent,
    ],
})
export class PotentialMatchComponent {
    @Input() potentialMatch: MissionUserMatchViewModel;

    ESkill = ESkill;

    viewedMatch$ = this.store.select(
        MissionUserMatchSelectors.selectViewedMatch
    );

    hasMorePotentialMatch$ = this.store.select(
        MissionUserMatchSelectors.selectHasMorePotentialMatches
    );

    isLoading$ = this.store.select(MissionUserMatchSelectors.selectIsLoading);

    constructor(
        private store: Store,
        private contactMatchDialogService: ContactMatchDialogService
    ) {}

    nextMatch(match: MissionUserMatchViewModel) {
        this.store.dispatch(
            MissionUserMatchActions.denyMatchRequest({ potentialMatch: match })
        );
    }

    contactMatch(match: MissionUserMatchViewModel) {
        this.store.dispatch(MissionUserMatchActions.resetHasContactedMatch());
        this.contactMatchDialogService.open({ match });
    }
}
