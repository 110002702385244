import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';

export const AlertActions = createActionGroup({
    source: 'Alert',
    events: {
        'Display Success': props<{
            key: string;
            variables?: { [key: string]: string };
            duration?: number;
        }>(),
        'Display Error': props<{
            key: string;
            variables?: { [key: string]: string };
            duration?: number;
        }>(),
        'Display Info': props<{
            key: string;
            link?: string;
            action?: Action;
            duration?: number;
        }>(),
        'Do Not Display Error': emptyProps(),
        'Display Generic Error': emptyProps(),
    },
});
