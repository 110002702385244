import { createReducer, on } from '@ngrx/store';
import { MissionUserMatchActions } from './mission-user-match.action';
import { MissionUserMatchState } from './mission-user-match.state';

export const missionUserMatchInitialState: MissionUserMatchState = {
    isLoading: false,
    isLoadingContact: false,
    hasContactedMatch: false,
};

export const missionUserMatchReducer = createReducer(
    missionUserMatchInitialState,

    on(MissionUserMatchActions.loadPotentialMatchesRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),
    on(
        MissionUserMatchActions.loadPotentialMatchesRequestSuccess,
        (state, { potentialMatches }) => {
            return {
                ...state,
                potentialMatches,
                viewedMatchIndex: 0,
                isLoading: false,
            };
        }
    ),
    on(MissionUserMatchActions.loadPotentialMatchesRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MissionUserMatchActions.contactPotentialMatcheRequest, (state) => {
        return {
            ...state,
            isLoadingContact: true,
        };
    }),
    on(
        MissionUserMatchActions.contactPotentialMatcheRequestSuccess,
        (state, { chat }) => {
            return {
                ...state,
                isLoadingContact: false,
                hasContactedMatch: true,
                viewedMatchIndex: state.viewedMatchIndex + 1,
                chat,
            };
        }
    ),
    on(MissionUserMatchActions.contactPotentialMatcheRequestFail, (state) => {
        return {
            ...state,
            isLoadingContact: false,
        };
    }),

    on(MissionUserMatchActions.denyMatchRequest, (state) => {
        return {
            ...state,
            viewedMatchIndex: state.viewedMatchIndex + 1,
        };
    }),

    on(MissionUserMatchActions.resetHasContactedMatch, (state) => {
        return {
            ...state,
            hasContactedMatch: false,
        };
    })
);
