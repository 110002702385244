<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-280 z-999"
    fixed
    [mode]="'over'"
    (openedChanged)="openedChanged($event)"
    [name]="'editMatrixPointDrawer'"
    [position]="'right'"
    #editMatrixPointDrawer
>
    <div class="flex flex-col w-full max-h-screen bg-card">
        <div class="flex gap-2 flex-row items-center p-6 text-white bg-warn">
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "csrapproach.matrix.edit-point" | transloco }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="editMatrixPointDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <form
            *ngIf="editMatrixPointDrawer.opened"
            [formGroup]="formGroup"
            class="p-4 flex flex-col gap-5 h-full overflow-auto"
        >
            <div class="flex flex-col gap-1">
                <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                    <mat-label>{{
                        "csrapproach.matrix.name" | transloco
                    }}</mat-label>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:pencil'"
                        matPrefix
                    ></mat-icon>
                    <input
                        [placeholder]="
                            'csrapproach.matrix.point-name-placeholder'
                                | transloco
                        "
                        [formControlName]="'name'"
                        matInput
                    />
                </mat-form-field>
                <bslk-length-info
                    [text]="formGroup.controls.name.value"
                    [maxLength]="100"
                ></bslk-length-info>
            </div>
            <div class="flex flex-col gap-1">
                <bslk-info-label
                    class="font-bold"
                    [tooltip]="
                        'csrapproach.matrix.description-tooltip' | transloco
                    "
                    >{{
                        "csrapproach.matrix.description" | transloco
                    }}</bslk-info-label
                >
                <quill-editor
                    class="mt-2 w-full"
                    [formControlName]="'description'"
                    [bounds]="'self'"
                    [placeholder]="
                        'csrapproach.matrix.description-placeholder' | transloco
                    "
                    [modules]="quillModules"
                ></quill-editor>
                <bslk-length-info
                    [text]="formGroup.controls.description.value"
                    [maxLength]="5000"
                    [isHtml]="true"
                ></bslk-length-info>
            </div>

            <single-select-async
                [label]="'csrapproach.matrix.related-micro-actions' | transloco"
                [selectPlaceholder]="
                    'csrapproach.matrix.related-micro-actions-placeholder'
                        | transloco
                "
                [searchPlaceholder]="
                    'csrapproach.matrix.related-micro-actions-search-placeholder'
                        | transloco
                "
                [control]="formGroup.controls.microActionIds"
                [actions]="MicroActionListActions"
                [selectors]="MicroActionListSelectors"
                [filters]="filters"
                [valueIsId]="true"
                [multiple]="true"
                [noDataText]="'csrapproach.matrix.no-micro-action' | transloco"
            >
            </single-select-async>

            <bslk-advanced-select-sync
                [label]="'csrapproach.matrix.legend' | transloco"
                [selectPlaceholder]="
                    'csrapproach.matrix.legend-placeholder' | transloco
                "
                [searchPlaceholder]="
                    'csrapproach.matrix.legend-search-placeholder' | transloco
                "
                [group]="formGroup"
                [controlName]="'legendId'"
                [items]="legends$ | async"
            ></bslk-advanced-select-sync>

            <!-- Actions -->
            <div class="grow flex gap-3 items-end justify-end">
                <bslk-classic-button
                    [background]="'primary'"
                    (onClick)="remove()"
                    [label]="'shared.common.remove' | transloco"
                ></bslk-classic-button>
                <bslk-classic-button
                    [isDisabled]="!formGroup.valid"
                    (onClick)="update()"
                    [label]="'shared.common.validate' | transloco"
                ></bslk-classic-button>
            </div>
        </form>
    </div>
</fuse-drawer>
