import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MicroActionCommentService } from 'app/api';
import { MicroActionCommentFormService } from 'app/shared/services/form/micro-action-comment-form.service';
import { map, tap } from 'rxjs';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createSelectedItemEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { MicroActionActions } from '../micro-action/micro-action.action';
import { MicroActionCommentListActions } from './micro-action-comment-list.actions';
import { MicroActionCommentListSelectors } from './micro-action-comment-list.selectors';
@Injectable()
export class MicroActionCommentListEffects {
    loadOnMicroActionLoad = createEffect(() =>
        this.actions$.pipe(
            ofType(MicroActionActions.loadMicroActionRequest),
            map(({ id }) => {
                this.microActionCommentFormService.initForm();
                this.microActionCommentFormService.getFormGroup.patchValue({
                    microActionId: id,
                });
                return MicroActionCommentListActions.HardResetWithFilters({
                    filters: [
                        {
                            key: 'microActionId',
                            value: id,
                        },
                    ],
                });
            })
        )
    );

    resetFormOnCreate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    MicroActionCommentListActions.UpdateSelectedItemSuccess,
                    MicroActionCommentListActions.CreateItemSuccess
                ),
                tap(() => {
                    this.microActionCommentFormService.getFormGroup.patchValue({
                        comment: '',
                    });
                })
            ),
        { dispatch: false }
    );

    loadComments$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.microActionCommentService,
        MicroActionCommentListActions,
        MicroActionCommentListSelectors,
        this.microActionCommentService.microActionCommentGet
    );

    reload$ = createReloadEffect(this.actions$, MicroActionCommentListActions);

    create$ = createCreateItemEffect(
        this.microActionCommentService,
        this.actions$,
        MicroActionCommentListActions,
        this.microActionCommentFormService,
        'micro-action-comment-create',
        this.microActionCommentService.microActionCommentPost
    );

    delete$ = createDeleteItemEffect(
        this.microActionCommentService,
        this.actions$,
        MicroActionCommentListActions,
        'micro-action-comment-delete',
        this.microActionCommentService.microActionCommentIdDelete
    );

    update$ = createUpdateSelectedItemEffect(
        this.microActionCommentService,
        this.actions$,
        MicroActionCommentListActions,
        this.microActionCommentFormService,
        'micro-action-comment-update',
        this.microActionCommentService.microActionCommentPut
    );

    itemSelected$ = createSelectedItemEffect(
        this.store,
        this.actions$,
        MicroActionCommentListActions,
        MicroActionCommentListSelectors,
        this.microActionCommentFormService
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private microActionCommentService: MicroActionCommentService,
        private microActionCommentFormService: MicroActionCommentFormService
    ) {}
}
