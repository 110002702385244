import { createSelector } from '@ngrx/store';
import { DateRange } from 'app/shared/types/common.types';
import { isNil } from 'lodash-es';
import { AppState } from '../..';
import { AssociationKpi } from './association-dashboard.state';

const selectState = (state: AppState) => state.associationDashboard;

const selectIsLoading = (kpi: AssociationKpi) =>
    createSelector(selectState, (state) => state.isLoading[kpi]);

const selectKpiFilters = (kpi: AssociationKpi) =>
    createSelector(selectState, (state) => state.kpiFilters[kpi]);

const selectKpiResult = (kpi: AssociationKpi) =>
    createSelector(selectState, (state) => state.kpiResults[kpi]);

const selectKpiResultDates = (kpi: AssociationKpi) =>
    createSelector(
        selectKpiResult(kpi),
        (kpiResult: any) =>
            ({
                startDate: kpiResult?.startDate,
                endDate: kpiResult?.endDate,
            } as DateRange)
    );

const selectIsKpiResultReady = (kpi: AssociationKpi) =>
    createSelector(selectKpiResult(kpi), (kpiResult) => !isNil(kpiResult));

const selectIsKpiExpanded = (kpi: AssociationKpi) =>
    createSelector(selectState, (state) => state.expandedKpi === kpi);

const selectIsKpiExpandedAnyOf = (kpis: [AssociationKpi]) =>
    createSelector(selectState, (state) => kpis.includes(state.expandedKpi));

export const AssociationDashboardSelectors = {
    selectIsKpiExpanded,
    selectIsKpiExpandedAnyOf,
    selectIsKpiResultReady,
    selectIsLoading,
    selectKpiResult,
    selectState,
    selectKpiFilters,
    selectKpiResultDates,
};
