import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import {
    FuseNavigationItem,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { MyBasileSelectors } from 'app/store/my-basile/my-basilie.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';

@Component({
    selector: 'vertical-navigation',
    templateUrl: './vertical-navigation.component.html',
    styleUrls: ['./vertical-navigation.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        BasileAvatarComponent,
        BslkTagComponent,
        ClassicAvatarComponent,
        FuseVerticalNavigationComponent,
        LetDirective,
        LetDirective,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        NgIf,
        NgStyle,
        RouterLink,
        TranslocoModule,
    ],
})
export class VerticalNavigationComponent {
    @Input() navigation: FuseNavigationItem[];
    @Input() isScreenSmall: boolean = true;

    displayedName$ = this.store.select(UserSelectors.selectDisplayedName);

    hasAvatar$ = this.store.select(UserSelectors.selectHasAvatar);

    avatarUrl$ = this.store.select(UserSelectors.selectAvatarUrl);

    email$ = this.store.select(UserSelectors.selectEmail);

    isAssoAdmin$ = this.store.select(UserSelectors.selectIsAssoAdmin);

    userNameFirstLetter$ = this.store.select(
        UserSelectors.selectNameFirstLetter
    );

    basileConfig$ = this.store.select(
        MyBasileSelectors.selectBasileConfiguration
    );

    customization$ = this.store.select(UserSelectors.selectCustomization);

    constructor(private store: Store) {}
}
