<div
    #requestsRepartition
    *ngrxLet="requestsRepartition$ as requestsRepartition"
    class="w-full p-6 bg-white shadow-lg rounded-2xl flex flex-col gap-4"
>
    <div class="flex w-full justify-between">
        <div class="flex gap-2 items-center">
            <mat-icon [svgIcon]="'feather:bar-chart-2'"></mat-icon>
            <span class="text-2xl font-bold">{{
                "donationcampaign.choices-repartition" | transloco
            }}</span>
        </div>
        <bslk-tag
            *ngIf="isWaitingForTieSettlement$ | async"
            [background]="'warn'"
            [icon]="'heroicons_outline:exclamation-circle'"
            >{{ "donationcampaign.tie-to-settle" | transloco }}</bslk-tag
        >
    </div>

    <ng-container *ngrxLet="tiedWinningProjectIds$ as tiedWinningProjectIds">
        <div
            *ngrxLet="winningProjectId$ as winningProjectId"
            class="flex flex-col gap-4 divide-y-2"
        >
            <div
                class="grid grid-cols-12 gap-8 pt-4 items-center"
                *ngFor="let requestRepartition of requestsRepartition"
            >
                <div class="col-span-5 flex gap-6">
                    <classic-avatar
                        [size]="16"
                        [borderSize]="4"
                        [avatarUrl]="
                            requestRepartition.project.association.logoUrl
                        "
                        [fallbackLetter]="
                            requestRepartition.project.association.name.at(0)
                        "
                    >
                    </classic-avatar>
                    <div class="flex flex-col justify-center gap-2">
                        <span class="font-bold text-xl">{{
                            requestRepartition.project.name
                        }}</span>
                        <span>{{
                            requestRepartition.project.association.name
                        }}</span>
                        <bslk-tag
                            [icon]="'heroicons_outline:star'"
                            [background]="'warn'"
                            *ngIf="
                                winningProjectId ===
                                requestRepartition.project.id
                            "
                            >{{
                                "donationcampaign.winning-project" | transloco
                            }}</bslk-tag
                        >
                        <bslk-tag-button
                            class="flex"
                            *ngIf="
                                tiedWinningProjectIds &&
                                tiedWinningProjectIds.includes(
                                    requestRepartition.project.id
                                )
                            "
                            (click)="
                                setWinningProject(requestRepartition.project.id)
                            "
                            [icon]="'heroicons_outline:paper-airplane'"
                            [isLoading]="isLoading$ | async"
                            >{{
                                "donationcampaign.set-winning-project"
                                    | transloco
                            }}</bslk-tag-button
                        >
                    </div>
                </div>

                <div
                    class="col-span-2 flex flex-col gap-2 items-center justify-center text-center"
                >
                    <span class="text-3xl font-extrabold">{{
                        requestRepartition.requestsCount
                    }}</span>
                    <span>{{
                        (requestRepartition.requestsCount > 1
                            ? "donationcampaign.selections"
                            : "donationcampaign.selection"
                        ) | transloco
                    }}</span>
                </div>

                <div class="col-span-5">
                    <bslk-classic-progress-bar
                        [progress]="requestRepartition.percentage"
                    ></bslk-classic-progress-bar>
                </div>
            </div>
        </div>
    </ng-container>
</div>
