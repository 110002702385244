<div class="flex flex-col">
    <div
        class="flex items-end truncate justify-between pb-1 mb-6 border-b-2 border-slate-300"
    >
        <span class="text-3xl md:text-4xl font-bold truncate">{{
            "regulardashboard.my-donation-gifts" | transloco
        }}</span>
    </div>

    <div class="grid gap-4 grid-cols-1">
        <gift-card
            class="cursor-pointer hover:scale-105 transition-transform duration-300"
            (click)="openGift(gift)"
            [campaign]="gift"
            *ngFor="let gift of pendingGifts$ | async"
        ></gift-card>
    </div>
</div>
