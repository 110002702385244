<company-csr-approach-card
    (editing)="onEdit($event)"
    (hiding)="onHiding($event)"
    [isLoading]="isLoading"
    [isLoadingUpdate]="isLoadingUpdate"
    [isCompanyAdmin]="isCompanyAdmin"
    [isHidden]="csrApproach?.hidePillars"
    [isEditing]="isEditing"
    [title]="'csrapproach.our-pillars' | transloco"
>
    <div class="flex items-center gap-2">
        <mat-icon
            *ngIf="hasPreviousPage"
            (click)="previousPage()"
            class="icon-size-7 cursor-pointer transition-all hover:scale-110"
            [svgIcon]="'heroicons_solid:chevron-left'"
        ></mat-icon>
        <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 w-full"
        >
            <div
                *ngIf="isEditing"
                (click)="addPillar()"
                class="rounded-3xl cursor-pointer bg-gray-100 text-gray-500 flex flex-col items-center justify-center gap-5 min-h-64"
            >
                <span class="text-sm">{{
                    "csrapproach.new-pillar" | transloco
                }}</span>
                <div class="flex items-center gap-2 text-2xl">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                    <span class="font-extrabold">
                        {{ "csrapproach.add" | transloco }}</span
                    >
                </div>
            </div>

            <div
                *ngFor="let pillar of displayedPillars; let index = index"
                (click)="viewPillar(pillar, index)"
                class="cursor-pointer bg-white shadow-md rounded-3xl relative overflow-hidden group"
            >
                <!-- Color mask effect on hover -->
                <div
                    [ngClass]="{
                        'bg-primary': index % 4 === 0 || index % 4 === 3,
                        'bg-warn': index % 4 === 1,
                        'bg-tertiary': index % 4 === 2
                    }"
                    class="absolute inset-0 opacity-0 group-hover:opacity-10 transition-opacity duration-300"
                ></div>
                <div
                    class="absolute z-10 top-2 left-2 flex items-center gap-2"
                    *ngIf="isEditing"
                >
                    <bslk-icon-button
                        (click)="editPillarImage($event, pillar.id)"
                        [matTooltip]="'csrapproach.change-image' | transloco"
                        [icon]="'heroicons_solid:camera'"
                    ></bslk-icon-button>
                    <bslk-icon-button
                        (click)="editPillar($event, index)"
                        [matTooltip]="'csrapproach.edit' | transloco"
                        [icon]="'heroicons_solid:pencil'"
                    ></bslk-icon-button>
                    <bslk-icon-button
                        (click)="removePillar($event, index)"
                        [matTooltip]="'csrapproach.remove' | transloco"
                        [icon]="'heroicons_solid:trash'"
                    ></bslk-icon-button>
                </div>
                <div
                    [class.border-b]="!pillar.imageUrl"
                    class="h-48 overflow-hidden"
                >
                    <img
                        *ngIf="pillar.imageUrl"
                        [src]="pillar.imageUrl"
                        class="object-cover h-full w-full group-hover:scale-105 transition-all duration-300 ease-in-out"
                    />
                    <!-- Color mask effect on hover -->
                    <div
                        [ngClass]="{
                            'bg-primary': index % 4 === 0 || index % 4 === 3,
                            'bg-warn': index % 4 === 1,
                            'bg-tertiary': index % 4 === 2
                        }"
                        class="absolute inset-0 opacity-0 group-hover:opacity-10 transition-opacity duration-300"
                    ></div>
                </div>

                <div class="flex flex-col gap-2 py-4 px-6 justify-center">
                    <span
                        [ngClass]="{
                            'text-primary': index % 4 === 0 || index % 4 === 3,
                            'text-warn': index % 4 === 1,
                            'text-tertiary': index % 4 === 2
                        }"
                        class="text-sm group-hover:text-black transition-all duration-300 ease-in-out"
                        >{{ "csrapproach.pillar" | transloco }} #{{
                            index + 1 + this.pillarsPage * this.pillarsPerPage
                        }}</span
                    >
                    <span
                        [ngClass]="{
                            'group-hover:text-primary':
                                index % 4 === 0 || index % 4 === 3,
                            'group-hover:text-warn': index % 4 === 1,
                            'group-hover:text-tertiary': index % 4 === 2
                        }"
                        class="text-3xl font-bold truncate transition-all duration-300 ease-in-out"
                        >{{ pillar.name }}</span
                    >
                </div>
            </div>
        </div>
        <mat-icon
            *ngIf="hasNextPage"
            (click)="nextPage()"
            class="icon-size-7 cursor-pointer transition-all hover:scale-110"
            [svgIcon]="'heroicons_solid:chevron-right'"
        ></mat-icon>
    </div>
</company-csr-approach-card>

<company-csr-approach-edit-pillar
    (close)="editDrawerClosed()"
    [isCreating]="isCreating"
    [editedPillarIndex]="editedPillarIndex"
></company-csr-approach-edit-pillar>

<company-csr-approach-view-pillar
    (close)="viewDrawerClosed()"
    [pillar]="viewedPillar"
    [color]="viewedPillarColor"
>
</company-csr-approach-view-pillar>
